import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgbModal, NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import * as firebase from 'firebase/app';
import { ActivatedRoute, Params } from '@angular/router';
import { BitshareService } from '../service/bitshare.service';
import { UserService } from '../service/user.service';
import { ReplaceOpenPipe } from '../pipe/replace-open.pipe';
import { Global } from '../global/global';

@Component({
  selector: 'app-moonpay',
  templateUrl: './moonpay.component.html',
  styleUrls: ['./moonpay.component.scss']
})
export class MoonpayComponent implements OnInit {
  public url: string;
  public urlSafe: SafeResourceUrl;
  public currentOrientation = 'horizontal';
  public user: any;
  public iconsOfAsset: any = [];
  public buy = true;
  public sell = false;
  public defaultCoins: any = [];
  public firstThreeCoin: any;
  public remainingCoin: any;
  public firstThreeAssetBalance: any;
  public remainingAssetBalance: any;
  public walletBalance: any;
  public convertedSellCurrency: any;
  public convertedAmount = 0;
  public sellFeeDetails: any;
  public networkFee = {amount: 0, symbol: 'OPEN.BTC'};
  public marketPrices: any = [];
  public userDocId: string;
  public selectedAssetBalance: string;
  public selectedAssetAmount: string;
  public selectedAsset: string;
  public choosedCurrencyAsset: string;
  public walletAddress: string;
  public choosedSellCurrency: any;
  public amount: any;
  public isGetAllAssetIcons = false;
  public buyWalletAddress: string;
  public modalReference: any;
  public buyAmount = 0;
  public sellAmount: any;
  public moonPayQuote: any;
  public amountError: string;
  public chooseCurrency: any;
  public currentMarketPrice: any;
  public convertedSell: number;
  public serviceFee: any;
  public errorMsg: string;
  public feeValidation = false;
  public staticFiatCurrency = true;
  public param: any;
  public testnetURL: any;

  constructor(
    public sanitizer: DomSanitizer,
    private modalService: NgbModal,
    private bitshareService: BitshareService,
    private userService: UserService,
    private ngxService: NgxUiLoaderService,
    private global: Global,
    private route: ActivatedRoute,

  ) { }
  ngOnInit() {
    let id = this.route.snapshot.params.id;
    if(id) {
      this.param = id.split("_");
      console.log('id', this.param[0]);
        }
    
    this.ngxService.start();
    this.userService.getCurrentUser().then(([user, udid]) => {
      this.user = user;
      this.userDocId = udid;
      if(id) {
      this.selectedAssetBalance = this.param[1];
      } else {
        this.selectedAssetBalance = 'USD';
      }
      this.choosedCurrencyAsset = user.currency;
      this.getAllAssetIcons();
      this.getWalletBalance(this.global.authToken, this.user.currency);
      this.getMarketPrice();
    });
    this.getMoonpayMarketCoinAmount();
    this.bitshareService.getClientConfig().subscribe(configData => {
      this.testnetURL = 'https://' + configData['PRO_HOSTNAME'];
      console.log('this.testnetURL', this.testnetURL)
    }, err => {
      console.log('err', err);
    });
  }
  closePopup() {
    this.modalReference.close();
  }
  getAllAssetIcons() {
    const storageRef = firebase.storage().ref().child('assets');
    storageRef.listAll().then(assetStorages => {
      const iconLoadedLen = assetStorages.items.length;
      assetStorages.items.forEach((assetIcon, ind) => {
        const iconName = assetIcon.name.replace('.jpg', '').replace('.png', '');
        assetIcon.getDownloadURL().then(response => {
          this.iconsOfAsset.push({name: iconName, path: response});
          if (ind === (iconLoadedLen - 1)) {
            this.isGetAllAssetIcons = true;
            this.setDefaultCoins();
          }
        }, err => {
          console.log('not able to get asset icon path');
        });
      });
    }, error => {
      console.log('not able to get list of assets from storage');
    });
  }

  enableSelectedAsset(asset) {
    this.ngxService.start();
    this.selectedAssetBalance = 'false';
    this.choosedCurrencyAsset = asset;
    console.log('choosedCurrencyAsset', this.choosedCurrencyAsset);
    this.ngxService.stop();
  }

  sellMaxCurrency() {
    this.sellAmount = this.selectedAssetAmount;
    this.amount = this.selectedAssetAmount;
    this.convertSellCurrency();
  }
  onCurrencySellChange(newObj) {
    if (newObj) {
      this.choosedSellCurrency = newObj;
      this.choosedCurrencyAsset = newObj;
    }
  }
  convertSellCurrency() {
    if (this.choosedCurrencyAsset) {
      const toCurrency = this.selectedAsset;
      const fromCurrency = this.selectedAssetBalance;
      this.amount = this.sellAmount;
      if (this.sellAmount === undefined) {
        this.amount = 0;
      }
      if (this.amount > 0) {
        this.feecalcSell(this.amount, toCurrency + ':' + fromCurrency);
      }
      const buycoinval = this.getPriceforPair(fromCurrency, toCurrency);
      this.convertedSell = buycoinval;
      this.convertedSellCurrency = (buycoinval * this.amount).toFixed(8);
      this.convertedAmount = parseFloat((buycoinval * this.amount).toFixed(6));
    }
  }
  feecalcSell(amount, pair) {
    this.bitshareService.feecalcSell(this.global.authToken, pair, amount).subscribe((sellFee: any) => {
      this.sellFeeDetails = sellFee;
      this.networkFee = sellFee.network_fee;
      this.serviceFee = sellFee.service_fee;
      if (sellFee.transaction_ok) {
        // All good
        this.feeValidation = true;
      }
    }, err => {
      console.log('err', err.error);
      this.networkFee = err.error.network_fee;
      this.serviceFee = err.error.service_fee;
      if (!err.error.transaction_ok) {
        if (err.error.msg === 'Balance is < total purchase price') {
          this.errorMsg = 'You don\t have enough on your account to exchange this amount';
        }
        this.feeValidation = false;
      }
    });
  }
  getMarketPrice() {
    this.bitshareService.getPairMarketPrice().subscribe((MarketPrices) => {
      this.marketPrices = MarketPrices;
    });
  }
  getPriceforPair(curr_one, curr_two) {
    let pp;
    this.marketPrices.forEach((marketpair, ind) => {
      if (marketpair.pair == curr_one + ':' + curr_two) {
        pp = marketpair.price;
      }
    });
    return pp;
  }
  tabChange($event: NgbTabChangeEvent) {
    if ($event.activeId === 'ngb-tab-1') {
      this.buy = true;
      this.sell = false;
    } else {
      this.sell = true;
      this.buy = false;
      window.open('https://testnet.blockbasis.com/', '_blank');
      $event.preventDefault();
    }
  }
  getWalletBalance(authToken, asset) {
    this.ngxService.start();
    this.bitshareService.getBalance(this.userDocId).subscribe((balance: any) => {
      this.walletBalance = balance;
      this.walletBalance.unshift({symbol: 'EUR', static: 'true'});
      this.walletBalance.unshift({symbol: 'USD', static: 'true'});
      this.firstThreeAssetBalance = this.walletBalance.slice(0, 3);
      this.remainingAssetBalance = this.walletBalance.slice(3);
      this.ngxService.stop();
    }, error => {
      this.ngxService.stop();
    });
  }
  getMoonpayMarketCoinAmount() {
    this.bitshareService.getMoonpayExchangeRates().subscribe((price: any) => {
      this.currentMarketPrice = price;
    }, error => {
      console.log('error moonpay price', error);
    });
  }
  getMoonpayQuote() {
    const selectedAssetsss = this.selectedAsset.replace(/open./g, '').replace(/OPEN./g, '').toLowerCase();
    if (this.buyAmount && this.buyAmount >= 20 && this.choosedCurrencyAsset) {
      this.amountError = '';
      const chooseCurrency = this.choosedCurrencyAsset.toLowerCase();
      this.bitshareService.getMoonpayQuote(selectedAssetsss, this.buyAmount, chooseCurrency).subscribe(quote => {
        this.moonPayQuote = quote;
        this.loadIframe();
      }, error => {
        console.log('error moonpay', error);
      });
    } else {
      this.amountError = 'Please enter minimum value of 20.';
    }
    if (this.buyAmount >= 20) {
      this.amountError = '';
    }
  }

  getWalletKey(asset) {
    this.ngxService.start();
    this.selectedAsset = asset.symbol;
    this.buyWalletAddress = asset.walletKey;
    if (this.buyAmount && this.buyAmount >= 20) {
      this.getMoonpayQuote();
    }
    this.ngxService.stop();
  }
  activateSelectedCoins(assets) {
    this.ngxService.start();
    this.selectedAssetBalance = assets.symbol;
    if (assets.hasOwnProperty('static')) {
      this.choosedCurrencyAsset = assets.symbol;
      this.staticFiatCurrency = true;
    } else {
      this.selectedAssetAmount = assets.amount;
      this.staticFiatCurrency = false;
    }
    this.ngxService.stop();
  }
  setDefaultCoins() {
    this.defaultCoins = [
      {symbol: 'OPEN.BTC', walletKey: '3HdJo1GyYt9AAjLykAmHERovV9es5rxedF', memo: '', marketPrice: this.currentMarketPrice.BTC},
      {symbol: 'OPEN.EOS', walletKey: 'devcspo5z5mi', memo: '1093', marketPrice: this.currentMarketPrice.EOS},
      {symbol: 'OPEN.ETH', walletKey: '0x33E5718252137292de12CB6e175ae36192598c24', memo: '', marketPrice: this.currentMarketPrice.ETH},
      {symbol: 'OPEN.XRP', walletKey: 'rNJ4rRvx8AL7KBRCAxJjhqfUGZMtL5eDpV', memo: '', marketPrice: this.currentMarketPrice.XRP},
      {symbol: 'OPEN.LTC', walletKey: 'MSabkkABm4kPHRvnwGeeoUWrCEsXai32nz', memo: '', marketPrice: this.currentMarketPrice.LTC},
    ];
    this.buyWalletAddress = this.defaultCoins[0].walletKey;
    if(this.param) {
      this.selectedAsset = this.param[0];
    } else {
      this.selectedAsset = this.defaultCoins[0].symbol;
    }
    this.firstThreeCoin = this.defaultCoins.slice(0, 3);
    this.remainingCoin = this.defaultCoins.slice(3);
    this.ngxService.stop();
  }

  buyMoreCurrency(buyCurrencyMore) {
    this.modalReference = this.modalService.open(buyCurrencyMore, { size: 'lg' });
  }

  chooseBuyCurrency(currency) {
    this.getWalletKey(currency);
    // this.activateSelectedCoins(currency);
    // this.selectedAssetBalance = currency.symbol;
    this.selectedAsset = currency.symbol;
    this.firstThreeCoin[2] = currency;
    this.modalReference.close();
  }
   sellMoreCurrency(sellCurrencyMore) {
    this.modalReference = this.modalService.open(sellCurrencyMore, { size: 'lg' });
  }

  chooseSellCurrency(currency) {
    console.log(currency);
    this.selectedAsset = currency.symbol;
    this.firstThreeCoin[2] = currency;
    this.modalReference.close();
  }
  userAvailableCurrency(showUserAvailableCurrency) {
    this.modalReference = this.modalService.open(showUserAvailableCurrency, { size: 'lg' });
  }
  chooseAvailableCurrency(currency) {
    this.selectedAssetBalance = currency.symbol;
    this.firstThreeAssetBalance[2] = currency;
    this.modalReference.close();
  }
  getAssetIcon(coinName) {
    coinName = new ReplaceOpenPipe().transform(coinName);
    coinName = coinName.toLowerCase();
    let iconPath = '../../assets/images/currency_logo/'+coinName+'coin.png';

    if (this.isGetAllAssetIcons === true) {
        let resFound = 'false';
        for (let assets of this.iconsOfAsset) {
          if (assets.name == coinName) {
              iconPath = assets.path;
          }
        }
        return iconPath;
    } else {
        return iconPath;
    }
  }


  loadIframe() {
    const selectedAsset = this.selectedAsset.replace(/open./g, '').replace(/OPEN./g, '');
    const redirectURL = location.protocol + '//' + location.host + '/buy-success';
    const transactionData = {
      buy_coin: selectedAsset,
      buy_coin_value: this.moonPayQuote.quoteCurrencyAmount,
      wallet_address: this.buyWalletAddress,
      paying_currency_value: this.moonPayQuote.totalAmount,
      paying_currency: this.user.currency,
      type: 'BUY',
      uid: this.user[1],
      transaction_id: null,
      payment_status: null
    };
    localStorage.setItem('transactionData', JSON.stringify(transactionData));
    this.url = 'https://buy.moonpay.io?apiKey=pk_test_hvCod142wP9zgevCjoELcJgb7DHlz6&currencyCode='+selectedAsset+'&walletAddress='+this.buyWalletAddress+'&baseCurrencyAmount='+this.buyAmount+'&email='+this.user.email+'&baseCurrencyCode='+this.choosedCurrencyAsset+'&redirectURL=' + redirectURL;
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }
};
