import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ReplaceBlockPipe } from '../pipe/replace-block.pipe'
import { BitshareuserService } from '../service/bitshareuser.service';
import { BitshareService } from '../service/bitshare.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UnlockPopup } from '../unlock-popup/unlock-popup';
import { ngCopy } from 'angular-6-clipboard';
import { Apis } from 'bitsharesjs-ws';
var { Login, ChainStore, FetchChain, TransactionBuilder, TransactionHelper, Aes, key, PrivateKey, ops } = require("bitsharesjs");
import { Global } from '../global/global';

export interface ConfigData {
  page: {
    heading: string;
  },
  formData: {
    allAssets: any;
    currentWallet: any;
    user: any;
    assetInfo: any;
    walletAddress: string;
    celsiusWalletAddress: string;
    coinName: string;
    confirmation: boolean;
  },
  type: string
}


@Component({
  selector: 'action-popup',
  templateUrl: './action-popup.html',
  styleUrls: ['./action-popup.css']
})
export class ActionPopup {
  public actionForm: FormGroup;
  public destinationAddress: string;
  public newTag: any;
  public memoID: any;
  public Xrptag: any;
  public memo: any;
  public privKeyBS: string;
  public privKeyMemo: string;
  public networkFee: number;
  public networkFeeParam: any;
  public withdrawAmount: number;
  public tpCode: string;
  public transaction_id: string;
  public currentWallet: any;
  public user: any;
  public successMsg: string;
  public errorMsg: string;
  public withdrawStatus = true;
  public show = true;
  public loading = false;
  public withdrawFeeStatus = true;
  public bitshareAssetInfo: Array<any> = [];
  public walletAddress: string;
  public celsiusWalletAddress: string;
  public loader = false;
  public tryAgain:boolean = false;
  public depositError = false;
  public depositErrorMsg: string;
  public HOSTURL: string;
  public BASE_ASSET:string;
  public memoDisplay:boolean = false;
  public tagDisplay:boolean = false;
  public newDestinationAddress: any;
  
  constructor(
    public dialogRef: MatDialogRef<ActionPopup>,
    @Inject(MAT_DIALOG_DATA) public data: ConfigData,
    private router: Router,
    public bitshareuserService: BitshareuserService,
    private bitshareService: BitshareService,
    public dialog: MatDialog,
    private global: Global,
  ) {
        if(data.type == 'Withdraw') {
          this.BASE_ASSET = localStorage.getItem('BASE_ASSET');
          if (data.formData.currentWallet.symbol === (this.BASE_ASSET+'.EOS')) { //EOS
            this.memoDisplay = true;
            this.eosActionForm();
          } else if (data.formData.currentWallet.symbol === (this.BASE_ASSET+'.XRP')) { //XRP
            this.tagDisplay = true;
            this.xrpActionForm();
          } else {
            this.simpleActionForm();
          }
          this.currentWallet = data.formData.currentWallet;
          this.user = data.formData.user;
          this.bitshareAssetInfo = data.formData.assetInfo;
        }
        if (data.type == 'Deposit') {
          this.loader = true;
          this.walletAddress = data.formData.walletAddress;
          //this.celsiusWalletAddress = data.formData.celsiusWalletAddress;
          const coinName = data.formData.coinName;
          this.bitshareService.getDepositAddress(this.global.authToken, coinName).subscribe((bsDepositAddress: any) => {
            this.loader = false;
            console.log('bsDepositAddress', bsDepositAddress);
            if (bsDepositAddress.address) {
              this.celsiusWalletAddress = bsDepositAddress.address;
              this.splitAddressTag(this.celsiusWalletAddress);
            } else {
              this.tryAgain = true;
            }
          }, error => {
            this.loader = false;
            console.log('deposit ADDRESS error', error.error);
            if (error.error.message) {
              this.depositError = true;
              this.depositErrorMsg = error.error.message;
            } else {
              this.tryAgain = true;
            }
          });
        }
        if (data.type == 'Transfer') {
          console.log('transfer');
        }
    }
  
  get f() { return this.actionForm.controls; }
  
  onNoClick(): void {
    this.dialogRef.close();
  }
  
  confirmTransfer(): void {
    this.data.formData.confirmation = true;
    this.dialogRef.close('Success');
  }
  
  validationMessages = {
    withdrawAmount: [
      { type: 'required', message: 'Amount is required.' },
      { type: 'balanceValidator', message: 'Low balance in your account.' },
      { type: 'addressValidator', message: 'Please enter the address above.' },
    ],
    networkFee: [
      { type: 'required', message: 'Fee is required.' },
      { type: 'validating', message: 'Calculating fee.' },
    ],
    address: [
      { type: 'required', message: 'Address is required.' },
      { type: 'toAddressValidator', message: 'Address is invalid.' },
    ],
    tag: [
      { type: 'required', message: 'Destination Tag address is required.' },
    ],
    memo: [
      { type: 'required', message: 'Memo is required for EOS withdraw.' },
    ]
  };
  
  xrpActionForm() {
    this.actionForm = new FormGroup({
      withdrawAmount: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      networkFee: new FormControl('', Validators.compose([])),
      address: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      tag: new FormControl('', Validators.compose([Validators.required,])),
      memo: new FormControl({value: '', disabled: true})
    });
  }


  eosActionForm() {
    this.actionForm = new FormGroup({
      withdrawAmount: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      networkFee: new FormControl('', Validators.compose([])),
      address: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      tag: new FormControl({value: '', disabled: true}),
      memo: new FormControl('', Validators.compose([Validators.required,]))
    });
  }
  
  simpleActionForm() {
    this.actionForm = new FormGroup({
      withdrawAmount: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      networkFee: new FormControl('', Validators.compose([])),
      address: new FormControl('', Validators.compose([
        Validators.required,
      ]))
    });
  }
  
  getAssetIcon(coinName) {
    coinName = new ReplaceBlockPipe().transform(coinName);
    coinName = coinName.toLowerCase();
    let iconPath = '../../assets/images/currency_logo/'+coinName+'coin.png';

        let resFound = 'false';
        for (let assets of this.data.formData.allAssets) {
          if (assets.name == coinName) {
              iconPath = assets.path; 
          }
        }
        return iconPath;
    
  }
 
  withdrawCoin(form) {
      console.log(this.actionForm.valid)
      if (this.actionForm.valid) {
        this.loading = true;
        console.log('withdraw clicked', form.value);
        console.log('this.currentWallet.symbol', this.data.formData.currentWallet.symbol);
        let curr = this.data.formData.currentWallet.symbol;
        this.privKeyBS = this.bitshareuserService.token;
        this.privKeyMemo = this.bitshareuserService.memo;
        const memo_symbol = this.data.formData.currentWallet.symbol.toLowerCase().split(".");  // Split block.xxx into "xxx"
        if (memo_symbol[1] === 'eos') {
           this.memo = "withdraw:" + memo_symbol[1] + ":" + this.destinationAddress + ":" + this.memoID;
        } else if (memo_symbol[1] === 'xrp') {
           this.memo = "withdraw:" + memo_symbol[1] + ":" + this.destinationAddress + ":" + this.Xrptag;
        } else if (memo_symbol[1] !== 'xrp' && 'eos') {
           this.memo = "withdraw:" + memo_symbol[1] + ":" + this.destinationAddress;
        }
        console.log('memo', this.memo);
        const fee = this.networkFeeParam;
        //const fee = this.bitshareFees.transfer.fee; // 100000
        // const fee = this.networkFee;
        if ((this.withdrawAmount + this.networkFee) > this.currentWallet.balance) {
            this.actionForm.controls['withdrawAmount'].setErrors({required: true});
            this.actionForm.controls['withdrawAmount'].setErrors({balanceValidator: true});
            this.loading = false;
        } else {
            if (this.privKeyBS && this.memo && this.withdrawAmount && fee) {
                this.actionForm.controls['withdrawAmount'].setErrors(null);
                this.bitshareService.doBitshareWithdraw(this.privKeyBS, this.privKeyMemo, this.user.accountName, this.memo, this.withdrawAmount, curr, fee).then(withdrawRes => {
                  console.log('withdrawRes', withdrawRes);
                  const tp = withdrawRes[0][0];

                  if (tp.id) {
                    this.tpCode = tp.block_num;
                    this.transaction_id = tp.trx_num;
                    this.withdrawStatus = false;
                    this.successMsg = 'Withdrawal successfully processed, reference block number is'; //tp.block_num;
                    this.loading = false;
                    this.HOSTURL = this.bitshareService.hostUrl;
                    //this.show = false; 
                  }
            
                }).catch(error => { 
                  console.log('error', error);
                  this.loading = false;
                  this.withdrawFeeStatus = false;
                  this.errorMsg = 'Execution error: something wrong with your inputs, please try again later.';
                });
            }
        }
        
        
      }
  }
  
  valBalance() {
    
    if(this.withdrawAmount == 0) {
      this.actionForm.controls['withdrawAmount'].setErrors({ required: true });
      //this.actionForm.controls['withdrawAmount'].setErrors({ balanceValidator: false });
    }
    if(this.withdrawAmount > 0 && this.withdrawAmount > (this.currentWallet.balance - this.networkFee)) {
       this.actionForm.controls['withdrawAmount'].setErrors({ required: true });
       this.actionForm.controls['withdrawAmount'].setErrors({ balanceValidator: true });
    } 
    if(this.withdrawAmount > 0 && this.withdrawAmount <= (this.currentWallet.balance - this.networkFee)) {
       this.actionForm.controls['withdrawAmount'].setErrors(null);
    }
    
  }
  
  calcFee() {
    if(this.destinationAddress && this.user.accountName && this.bitshareuserService.token) {
        console.log('this.destinationAddress', this.destinationAddress);
        console.log('this.user.accountName', this.user.accountName);
        console.log('this.bitshareuserService.token', this.bitshareuserService.token);
        this.loading = true;
        this.actionForm.controls['networkFee'].setErrors({ validating: true });
        let tr = new TransactionBuilder();
        this.privKeyBS = this.bitshareuserService.token;
        var privKey = this.privKeyBS;
        let pKey = PrivateKey.fromWif(privKey);
        
        let fromAccount = this.user.accountName;
        let memoSender = fromAccount;
        const memo_symbol = this.currentWallet.symbol.toLowerCase().split(".");  // Split block.xxx into "xxx"
        const memo = "withdraw:" + memo_symbol[1] + ":" + this.destinationAddress;
        //const addrValRes = validateAddress(this.destinationAddress, memo_symbol[1]);
        //client side address validation
        var CAValidator = (window as any).CAValidator;
        var valid = CAValidator.validate(this.destinationAddress, memo_symbol[1], 'both');
        console.log('address validaion', valid);
        if(valid) {
          //this.actionForm.controls['address'].setErrors({ toAddressValidator: false });
          this.actionForm.controls['address'].setErrors(null);
        } else {
          this.actionForm.controls['address'].setErrors({ toAddressValidator: true });
        }
        let toAccount = 'block-basis'
        let sendAmount = {
          amount: this.withdrawAmount,
          asset: this.currentWallet.symbol
        }

        Promise.all([
          FetchChain("getAccount", fromAccount),
          FetchChain("getAccount", toAccount),
          FetchChain("getAccount", memoSender),
          FetchChain("getAsset", sendAmount.asset),
          FetchChain("getAsset", sendAmount.asset)
        ]).then((res)=> {
            let [fromAccount, toAccount, memoSender, sendAsset, feeAsset] = res;
            console.log('______', sendAsset.get("precision"));
            let memoFromKey = memoSender.getIn(["options","memo_key"]);
            let memoToKey = toAccount.getIn(["options","memo_key"]);
            let nonce = TransactionHelper.unique_nonce_uint64();
            let memo_object = {
                from: memoFromKey,
                to: memoToKey,
                nonce,
                message: Aes.encrypt_with_checksum(
                    pKey,
                    memoToKey,
                    nonce,
                    memo
                )
            }
            
            let serialized = ops.memo_data.fromObject(memo_object);
            const stringified = JSON.stringify(
                ops.memo_data.toHex(serialized)
            );
            console.log('stringified', stringified)
            const byteLength = Buffer.byteLength(stringified, "hex");
            console.log('=======byteLength===========', byteLength)
            
            const user_transfer_info_dynamic = {
                fee: {
                  amount: 0,//this.bitshareFees.transfer.fee 2136718
                  asset_id: feeAsset.get("id") // '1.3.0'
                },
                from: fromAccount.get("id"),
                to: toAccount.get("id"),
                amount: { amount: Math.pow(10, sendAsset.get('precision')) * sendAmount.amount, asset_id: sendAsset.get("id") },
                memo: memo_object
            };
            tr.add_type_operation( "transfer", user_transfer_info_dynamic);

            tr.set_required_fees().then(() => {
              tr.add_signer(pKey, pKey.toPublicKey().toPublicKeyString());
              console.log("serialized transaction:", tr.serialize());
              const calculatedFee = tr.serialize();
              //console.log(calculatedFee['operations'][0][1]['fee'])
              this.networkFee = this.formatAmountwithPrec(calculatedFee['operations'][0][1]['fee']['amount'], calculatedFee['operations'][0][1]['fee']['asset_id']);
              this.networkFeeParam = calculatedFee['operations'][0][1]['fee']['amount'];
              //tr.broadcast();
              console.log(this.networkFee +":"+ this.networkFeeParam);
              this.loading = false;
              this.actionForm.controls['networkFee'].setErrors(null);
              this.valBalance();
            });
        });
    }
  }
  
  formatAmountwithPrec(amount, assetId){
    if (assetId) {
      //console.log('assetId', assetId)
      
        var thisAssProp = this.bitshareAssetInfo[assetId];
        const currBal:string = amount.toString();
        let curBalnFor:any;
        
        if (assetId != '1.3.0') {
           if(thisAssProp && thisAssProp['precision']) {
       
            if (currBal.length > thisAssProp.precision) {
                const f:string = currBal.substring(0, (currBal.length - thisAssProp.precision));
                const s:string = currBal.substring((currBal.length - thisAssProp.precision), currBal.length);
                curBalnFor = f+'.'+s;
            } else {
              const diff = thisAssProp.precision - currBal.length;
              let f = '0.';
              for (var i=1; i<=diff; i++) {
                f = f+'0';
              }
              curBalnFor = f+currBal;
            }
          } 
        } else { //5
          const f:string = currBal.substring(0, (currBal.length - 5));
          const s:string = currBal.substring((currBal.length - 5), currBal.length);
          curBalnFor = f+'.'+s;
        }
        
        return curBalnFor;
     
    } else {
      return amount;
    }
  }
  
  openDialog() {
    const dialogRef = this.dialog.open(UnlockPopup, {
      height: 'auto',
      width: '700px',
      data: { password: '' }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      this.calcFee();
      this.valBalance();
    });
  };
  
  copytoCliboard(address) {
    console.log('address', address);
    ngCopy(address);
    //this.textCopyStatus = true;
  }
  
  buyMaximumCurrency() {
    setTimeout(() => {
    if (this.destinationAddress) {
        console.log(this.currentWallet.balance +'-'+ this.networkFee+'::'+this.destinationAddress);
        if (this.currentWallet.balance > 0) {
            this.withdrawAmount = this.currentWallet.balance
            if (this.networkFee) {
                this.withdrawAmount -= this.networkFee;
            }
            console.log('this.withdrawAmount', this.withdrawAmount);
        } else {
          this.withdrawAmount = 0;
        }
    } else {
        console.log('addressValidator failed');
        this.actionForm.controls['withdrawAmount'].setErrors({ required: true });
        this.actionForm.controls['withdrawAmount'].setErrors({ addressValidator: true });
    }
  }, 1000);
  }
  
  splitAddressTag(celsiusWalletAddress) {
    let addressArray;
    if (celsiusWalletAddress.indexOf("?dt=") !== -1) {
      addressArray = celsiusWalletAddress.split("?dt=");
      this.newDestinationAddress = addressArray[0];
      this.newTag = addressArray[1];
    } else if (celsiusWalletAddress.indexOf("?memoId=") !== -1) {
      addressArray = celsiusWalletAddress.split("?memoId=");
      this.newDestinationAddress = addressArray[0];
      this.newTag = addressArray[1];
    } else {
      this.newDestinationAddress = celsiusWalletAddress;
      this.newTag = '';
    }
  }
}
