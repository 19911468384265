import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../service/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Global } from '../global/global';
import { BitshareuserService } from '../service/bitshareuser.service';
import { BitshareService } from '../service/bitshare.service';
import { Apis } from 'bitsharesjs-ws';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UnlockPopup } from '../unlock-popup/unlock-popup';
var { Login, ChainStore, FetchChain, PrivateKey, TransactionHelper, Aes, TransactionBuilder, key, ops } = require("bitsharesjs");
// var { Gem } = require('@gem.co/api').SDK;
export interface DialogData {
  password: string;
}
// const {OpenSSL} = require('openssl');
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  public loading = false;
  public GATEWAY_WALLETS: string;
  public currency;
  public userName;
  public loggedInUserURL;
  public userDocId;
  public user;
  public photoURL;
  public selectedWallet: any;
  public currBal;
  public usersBSBalance: any;
  public CurrencyChange;
  public errorMsg: string;
  public amount;
  public profileHeading;
  public transferStatus: boolean;
  public paymentEmail;
  public paymentPhotoURL;
  public lastName;
  public accessToken: string;
  public showTransferConf: boolean = false;
  public bbUsername: string;
  selectedIndex: number = 0;
  public usernameBS: string;
  public transferBy: string;
  public transferTo: string;
  // isEditable = false;
  public addPlus: boolean;
  public BASE_ASSET: string;
  public HOSTURL: string;
  public resetConn: any;
  public password: string;
  public email: any;
  constructor(
    private bitshareService: BitshareService,
    private _formBuilder: FormBuilder,
    private userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private global: Global,
    private http: HttpClient,
    public dialog: MatDialog,
    public bitshareuserService: BitshareuserService,

  ) {
    this.GATEWAY_WALLETS = JSON.parse(localStorage.getItem('clientConfig')).GATEWAY_WALLETS;
    this.bbUsername = JSON.parse(localStorage.getItem('clientConfig')).GATEWAY_ACCOUNT;
    this.activatedRoute.params.subscribe(allParams => {
      console.log('allParams', allParams);
      if (allParams) {
        if (allParams.userName) {
          this.userName = allParams.userName;
          this.profileHeading = this.userName[0].toUpperCase() + this.userName.slice(1);
        }
      }
    });
    this.getParamUserDetails();
  }
  get f() { return this.secondFormGroup.controls; }
  ngOnInit(): void {
    this.firstFormGroup = this._formBuilder.group({
      userCurrency: ['', Validators.required],
      userAmount: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      userEmail: [''],
      userCurrency: ['', Validators.required],
      userAmount: ['', Validators.required],
    });
    if (Apis.instance().chain_id) {
      this.initPage();
    } else {
      this.bitshareService.bsConnect().then((connRes: any) => {
        console.log('connRes', connRes);
        setTimeout(() => {
          this.initPage()
        }, 1500);
      });
    }
  }
  initPage() {
    if (localStorage.getItem('BASE_ASSET')) {
      this.BASE_ASSET = localStorage.getItem('BASE_ASSET');
    } else {
      this.bitshareService.getBaseAsset().then(BASE_ASSET => {
        localStorage.setItem('BASE_ASSET', BASE_ASSET);
        this.BASE_ASSET = BASE_ASSET;
      })
    }
    console.log('this.BASE_ASSET', this.BASE_ASSET);
    this.userService.getCurrentUser().then(([user, udid]) => {
      this.userDocId = udid;
      this.user = user;
      this.email = this.user.email;
      this.usernameBS = this.user.accountName;
      this.getBSUserBalance(this.usernameBS);
      console.log('this.usernameBS', this.usernameBS)
      this.accessToken = this.global.authToken;
      this.CurrencyChange = 'BTC';
      this.amount = '0.00';
      console.log('this.user', this.user);
    });
  }
  ngOnDestroy() {
    this.removeIntervalWSSConn();
  }
  removeIntervalWSSConn() {
    if (this.resetConn) {
      clearInterval(this.resetConn);
    }
  }
  gemPayment() {
    const onrampConfig = {
      partnerName: 'Block Basis',
      partnerIconUrl: 'http://app.blockbasis.com/assets/images/bb_logo_4.png',
      apiKey: 'aa6d19580ee62427c2f41252471d0a02ffc8bc0015177ff0ceef8684843eab3b',
      wallets: [
        {
          asset: 'btc',
          address: '3CEaLJ7xfbpneNNyVCu1aQjuC3nBFs5PhM'
        },
        {
          asset: 'eth',
          address: '0x4e34e6bda92bdd590f503630959ee20a1c2df5f2'
        }
      ],
      onExit: function (data) {
        console.log('onExit', data);
      },
      onSuccess: function (data) {
        console.log('onSuccess', data);
      },
      onError: function (data) {
        console.log('onError', data);
      }
    };
    // const GO = new Gem.Onramp(onrampConfig);
    // console.log('GO', GO);
    // Launch Gem on click
    // const GemButton = document.getElementById('gem-button');
    // GemButton.onclick = function () {
      // GO.open({ userEmail:this.email});
    // };
  }
  getBSUserBalance(usernameBS) {
    return new Promise<any>((resolve, reject) => {
      this.bitshareService.getBitshareUserbalance(usernameBS).then(userBalance => {
        console.log('userBalance', userBalance);
        this.usersBSBalance = userBalance;
        resolve(userBalance);
      }, err => {
        reject(err);
      });
    });
  }

  transferCoin(form) {
    console.log('form', form);
    if (this.bitshareuserService.token) {
      const receiverEmail = form.value.email;
      // 1, check the user exist through email
      // 2, yes, do transer, send email
      // 3, no, send an invite email
      let receiverDetails = null;
      if(this.usersBSBalance.length > 0) {
        console.log('form.value.userCurrency', form.value.userCurrency);
        this.confirmTransfer(form.value);
        this.usersBSBalance.forEach((curInfo, ind) => {
          if(curInfo.symbol === form.value.userCurrency || (curInfo.symbol === this.BASE_ASSET+"."+form.value.userCurrency)) {
            // precision balance
            this.currBal = curInfo.balance;
            this.selectedWallet = curInfo;
            console.log('this.selectedWallet', this.selectedWallet);
          }
        })
      } else {
        console.log('else')
        this.gemPayment();
      }
    }
  }
  confirmTransfer(formValue) {
    this.removeIntervalWSSConn();
    console.log('confirmTransfer', formValue);
    if (this.bitshareuserService.lockedFlag == true) {
      let tr = new TransactionBuilder();
      let pKey = PrivateKey.fromWif(this.bitshareuserService.token);
      let pKeyMemo = PrivateKey.fromWif(this.bitshareuserService.memo);
      let fromAccount = this.usernameBS;
      let memoSender = fromAccount;
      let memo;
      if (this.transferBy == "Blockbasis") {
        if (this.addPlus === true) {
          memo = "transfer:+" + formValue.toUsername;
        } else {
          memo = "transfer:" + formValue.toUsername;
        }
      } else {
        memo = "";
      }
      console.log('memo', memo);
      let toAccount; //senthil-r wd-dev-test-13 r-senthil mark-dencker
      this.transferTo = this.bbUsername;
      if (this.transferBy === 'Username') {
        toAccount = formValue.userEmail; //senthil-r wd-dev-test-13 r-senthil mark-dencker
      } else {
        toAccount = this.transferTo;
      }
      let sendAmount = {
        amount: formValue.userAmount,
        asset: (formValue.userCurrency == 'TEST') ? formValue.userCurrency : this.BASE_ASSET + '.' + formValue.userCurrency
      }
      Promise.all([
        FetchChain("getAccount", fromAccount),
        FetchChain("getAccount", toAccount),
        FetchChain("getAccount", memoSender),
        FetchChain("getAsset", sendAmount.asset),
        FetchChain("getAsset", sendAmount.asset)
      ]).then((res) => {
        let [fromAccount, toAccount, memoSender, sendAsset, feeAsset] = res;
        console.log('fromAccount', fromAccount);
        console.log('toAccount', toAccount);
        console.log('memoSender', memoSender);
        console.log('sendAsset', sendAsset);
        console.log('feeAsset', feeAsset);
        if (fromAccount) {
          if (toAccount) {
            var feeOptions = feeAsset.get("options");
            console.log('feeOptions', feeOptions)
            const marketFee = feeOptions._root.nodes;
            let memoFromKey = memoSender.getIn(["options", "memo_key"]);
            let memoToKey = toAccount.getIn(["options", "memo_key"]);
            let nonce = TransactionHelper.unique_nonce_uint64();
            let memo_object = {
              from: memoFromKey,
              to: memoToKey,
              nonce,
              message: Aes.encrypt_with_checksum(
                pKeyMemo,
                memoToKey,
                nonce,
                memo
              )
            }
            const user_transfer_info_dynamic = {
              fee: {
                amount: 0, //2121093 2136718 (Math.pow(10, sendAsset.get('precision')) * sendAmount.amount) * (marketFee[1]['nodes'][0]['entry'][1]/100)
                asset_id: feeAsset.get("id") //feeAsset.get("id") '1.3.0'
              },
              from: fromAccount.get("id"),
              to: toAccount.get("id"),
              amount: { amount: (Math.pow(10, sendAsset.get('precision')) * sendAmount.amount).toFixed(), asset_id: sendAsset.get("id") },
              memo: memo_object
            };
            tr.add_type_operation("transfer", user_transfer_info_dynamic);

            tr.set_required_fees().then(() => {
              tr.add_signer(pKey, pKey.toPublicKey().toPublicKeyString());
              console.log("serialized transaction:", tr.serialize());
              //tr.broadcast();
              //console.log("broadcast transaction:", tr.broadcast());
              Promise.all([
                tr.broadcast()
              ]).then((trbrRes: any) => {
                console.log('trbrRes', trbrRes)
                const tp = trbrRes[0][0];
                if (tp.id) {
                  //this.transferFormReset();
                  this.transferStatus = true;
                  this.HOSTURL = this.bitshareService.hostUrl;
                  this.router.navigate(['/dashboard']);
                }
                this.loading = false;
                this.setIntervalWSSConn();
              }).catch(error => {
                console.log('error', error);
                this.errorMsg = 'Something went wrong, please try again.';
                this.transferStatus = false;
                this.loading = false;
                this.setIntervalWSSConn();
              });
            });
          } else {
            this.errorMsg = 'Send Currency To username does not exist in BitsharesJS';
            this.transferStatus = false;
            this.loading = false;
            this.setIntervalWSSConn();
          }
        } else {
          this.errorMsg = 'Username does not exist in BitsharesJS';
          this.transferStatus = false;
          this.loading = false;
          this.setIntervalWSSConn();
        }
      });

    }
  }
  setIntervalWSSConn() {
    this.resetConn = setInterval(() => {
      this.bitshareService.doCheckWSSConnection();
    }, 10000);
  }
  getParamUserDetails() {
    this.userService.getUserByName(this.profileHeading).subscribe(res => {
      this.paymentEmail = res[0].email;
      this.paymentPhotoURL = res[0].photoURL;
      this.lastName = res[0].lastName;
    });
  }
  checkUser() {
    console.log('sdfsfsdfsfsd')
    this.userService.getCurrentUser().then(([user, udid]) => {
      if (!user) {
        this.router.navigate(['/login']);
      }
    });
  }
  // async transactionThroughGem() {
  //   console.log('GEM')
  //   const gemApi = new Gem({
  //     apiKey: "aa6d19580ee62427c2f41252471d0a02ffc8bc0015177ff0ceef8684843eab3b",
  //     secretKey: "4fbb8194534a9cb4cde782374d32087bf997de5eb690c056fa4dcb47159719b8",
  //     baseUrl: 'https://api.sandbox.gem.co',

  //   });

  //   // var date = new Date();
  //   // var timestamp = date.getTime();
  //   // var signature = OpenSSL::HMAC.hexdigest("SHA256", secretKey, "#{api_key}:#{timestamp}")

  //   const user = await gemApi.findOrCreateUser({ email: this.email });
  //   console.log('usereee', user);
  //   const txn = await gemApi.createTransaction({
  //     source_id: this.usernameBS,
  //     source_amount: 1,
  //     blockchain_address: {
  //       address: '3CEaLJ7xfbpneNNyVCu1aQjuC3nBFs5PhM',
  //       asset_id: 'bitcoin',
  //     },
  //     type: 'buy',
  //     preview: false,
  //   });
  //   console.log('txn', txn);
  // }
  openDialog() {
    const dialogRef = this.dialog.open(UnlockPopup, {
      height: 'auto',
      width: '700px',
      data: { password: this.password }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
    });
  };
  setIndex(event) {
    this.selectedIndex = event.selectedIndex;
  }

  triggerClick(event) {
    console.log(`Selected tab index: ${this.selectedIndex}`);
  }
}
