import { Component, OnInit, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { Apis } from 'bitsharesjs-ws';
var { Login, ChainStore, FetchChain } = require("bitsharesjs");
import { BitshareService } from '../service/bitshare.service';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../service/user.service';

@Component({
  selector: 'app-chart-sample',
  templateUrl: './chart-sample.component.html',
  styleUrls: ['./chart-sample.component.scss']
})
export class ChartSampleComponent implements OnInit {
  
  public assetChartData: Array<any> = [];
  public chartCoinpair: Array<any> = [];
  public ngx_charts_exchange: boolean = false;

  view: any[] = [1000, 600];

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = 'Date';
  showYAxisLabel = true;
  yAxisLabel = 'Market Value';
  yAxis: boolean = true;
  xAxis: boolean = true;
  legend: boolean = false;
  showLabels: boolean = true;
  animations: boolean = true;
  timeline = true;
  public fromDate: string;
  public toDate: string;
  //graphData:Array<any> = [];
  lineChartData:Array<any> = [];
  lineChartTwoData:Array<any> = [];
  colorScheme = {
    domain: ['#7f2090', '#E44D25', '#e799f1', '#36033c', '#a8385d', '#e3aaf5']
  };
  
  public user: any;
  
  //lineChartData: any[] = [];
  
            
  constructor(
    private bitshareService: BitshareService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService
  ) { 
    
  }

  ngOnInit() {
    let today = new Date();
    let toDateOnly = ("0" + today.getDate()).slice(-2);
    let toMonthOnly = ("0" + today.getMonth()).slice(-2);
    let toYearOnly = today.getFullYear();
    let toDateTime = toYearOnly +'-'+ toMonthOnly + '-' + toDateOnly + 'T' + '00:00:00';
    this.fromDate = toDateTime;
    
    let fromDate = new Date();
    let fromDateOnly = ("0" + fromDate.getDate()).slice(-2);
    let fromMonthOnly = ("0" + (fromDate.getMonth() - 1 + 2)).slice(-2);
    let fromYearOnly = fromDate.getFullYear();
    let fromDateTime = fromYearOnly +'-'+ fromMonthOnly + '-' + fromDateOnly + 'T' + '00:00:00';
    this.toDate = fromDateTime;
      
    this.activatedRoute.params.subscribe(allParams => {
        if(allParams) {
          if(allParams.fromDate) {
            this.fromDate = allParams.fromDate;
          }
          if(allParams.toDate) {
            this.toDate = allParams.toDate;
          }
          if(allParams.currOne) {
            this.chartCoinpair.push(allParams.currOne)
          }
          if(allParams.currTwo) {
            this.chartCoinpair.push(allParams.currTwo)
          }
        }
    });
    
    let server = "wss://api.dex.trading/"; //wss://testnet.dex.trading/ wss://api.btsgo.net/ws wss://api.dex.trading/ 
    Apis.instance(server, true).init_promise.then((res) => { //comment
        //console.log("connected to:", res[0].network);
        console.log('server conn res', res);
        ChainStore.init(false).then(() => {
        });
    });
    
    var val = JSON.parse(localStorage.getItem('clientConfig')).GATEWAY_WALLETS;
    const GATEWAY_WALLETS = val.toLocaleString().toLowerCase().split(',');
    
    
    setTimeout(() => {
      this.bitshareService.getGatewayCoins().subscribe((bsAssetsRes: any) => {
        
        var bsAssets:Array<any> = [];
        bsAssets.push("CNY");
        bsAssets.push("BTC");
        bsAssets.push("GDEX.EOS");
        bsAssets.push("BTS");
        
        this.userService.getCurrentUser().then(([user, udid]) => {
          if (!user) {
            //this.router.navigate(['/login']);
          } else {
            this.user = user; //this.user.currency
            
            bsAssets.forEach((bsitm, bsind) => {
              
              this.chartCoinpair = [];
              this.chartCoinpair.push(bsitm);
              this.chartCoinpair.push("USD"); //this.user.currency
              let graphData:Array<any> = [];
              let yAxisMin:Array<number> = [];
              Apis.instance().db_api().exec( "lookup_asset_symbols", [this.chartCoinpair]).then((assetsInfoBS:any) => {
                console.log('assetsInfoBS', assetsInfoBS);
                const base_id = assetsInfoBS[0];
                const quote_id = assetsInfoBS[1];
                let toDo: string;
                let divByOne: boolean = false;
                let diff: number; //Math.abs(base_id['precision'] - quote_id['precision'])
                let preDiv: boolean;
                let postDiv: boolean;
                if(base_id['precision'] >= quote_id['precision']) {
                  diff = base_id['precision'] - quote_id['precision'];
                  preDiv = true;
                  postDiv = false;
                } else {
                  diff = quote_id['precision'] - base_id['precision'];
                  preDiv = false;
                  postDiv = true;
                }
                
                if(base_id['id'].split(".")[2] > quote_id['id'].split(".")[2]) {
                  if(quote_id['options']['core_exchange_rate']['quote']['asset_id'] == quote_id['id']) {
                    toDo = 'Divide';
                  } else {
                    toDo = 'Multiply';
                  }
                } else {
                  toDo = 'Divide';
                }
                
                let bi:number = 1;
                for(let i=1; i <= diff; i++) {
                  bi = bi * 10;
                }
                console.log('bi', bi, toDo)
                //if(base_id['id'].split(".")[2] > quote_id['id'].split(".")[2]) {
                if(base_id['options']['core_exchange_rate']['base']['asset_id'].split(".")[2] >= quote_id['options']['core_exchange_rate']['quote']['asset_id'].split(".")[2]) {
                    divByOne = true;    
                }
                console.log(base_id['symbol'] +"::"+ quote_id['symbol'] +quote_id['precision'] + '>=' + base_id['precision'] + 'div ' + divByOne + '-'+preDiv+' - '+postDiv);
                
                Apis.history.get_market_history_buckets().then((marketHistoryBuckets:any) => {
                    const mid = (marketHistoryBuckets.length / 2).toFixed();
                    //const mid = marketHistoryBuckets.length - 1; //
                    
                    let lineChartDataTmp;
                    Apis.history.get_market_history(base_id['symbol'], quote_id['symbol'], marketHistoryBuckets[mid], this.fromDate, this.toDate).then((debugMarketBuckets:any) => {
                      console.log('debugMarketBuckets', debugMarketBuckets);
                    });
                    Apis.history.get_market_history(base_id['symbol'], quote_id['symbol'], marketHistoryBuckets[mid], this.fromDate, this.toDate).then((marketBuckets:any) => {
                      const original = marketBuckets;
                      marketBuckets.forEach((gData, gInd) => {
                        let openVal, highVal, lowVal, closeVal;
                        const base_precision = base_id.precision;
                        const quote_precision = quote_id.precision;
                            
                        openVal = (gData.open_base / gData.open_quote);
                        highVal = (gData.high_base / gData.high_quote);
                        lowVal = (gData.low_base / gData.low_quote);
                        closeVal = (gData.close_base / gData.close_quote);
                        
                        if(toDo == 'Divide') {
                          if(preDiv == true) {
                            openVal = (bi / openVal);
                            highVal = (bi / highVal);
                            lowVal = (bi / lowVal);
                            closeVal = (bi / closeVal);
                          }
                          if(postDiv == true) {
                            openVal = (openVal / bi);
                            highVal = (highVal / bi);
                            lowVal = (lowVal / bi);
                            closeVal = (closeVal / bi);
                          }
                          
                        } else {
                          openVal = (openVal * bi);
                          highVal = (highVal * bi);
                          lowVal = (lowVal * bi);
                          closeVal = (closeVal * bi);
                        }
                        yAxisMin.push(openVal);
                        graphData.push({'name': this.formatDate(gData.key.open), 'value': openVal, 'base': gData.open_base, 'quote': gData.open_quote}); 
                        if(graphData.length == marketBuckets.length) {
                            //console.log(graphData);
                            setTimeout(()=>{
                                
                                //console.log('condition check ' +  bsind + '==' + bsAssets.length)
                                //let minYVal = yAxisMin.reduce((a, b)=>Math.min(a, b));
                                yAxisMin.sort((a,b) => (a > b) ? 1 : ((b > a) ? -1 : 0));
                                console.log('yAxisMin', yAxisMin)
                                let minYVal = yAxisMin[0] - (yAxisMin[1] - yAxisMin[0]);
                                if(minYVal < 0) { minYVal = 0; }
                                this.lineChartData = [{ "name": base_id['symbol'] + " :: " + quote_id['symbol'], "series": graphData, "min": minYVal }];
                                
                                this.assetChartData.push({"base": base_id, "quote": quote_id, "graphData": this.lineChartData, "min": minYVal});
                                if(bsind == (bsAssets.length - 1)) {
                                    this.ngx_charts_exchange = true;
                                }
                            }, 1000);
                        }
                      });
                      
                    }); 
                });
              });
              
            });
          }
        });
        
      });
    }, 3000);
    /*
    Hint
    firs to check multiply or divide
    condition for multiply is third base id is greater than third id of quote, else div
    if multiply then diff of precion x10 thats all for multiply
    if divide then to check pre div or post div
    do pre div only if base precison is equal or greater than quote precision then it is pre div
    
    if base precision equal or greater than quote precision then pre divide
    if base precision less than quote precision then post divide
    if third id of base is greater than quote third id then it will be multiply
    by default multiply
    */
    //console.log('done');
      
    
    
  }
  
  onSelect(data): void {
    //console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    //console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    //console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }
  /*
  onSelect(event) {
    console.log(event);
  }
  */
  getGraphValue(base, quote) {
    
  }
  
  formatDate(thisDateTime) {
    var dateandtime = thisDateTime.split("T");
    var datewithyear = dateandtime[0].split("-");
    var timewithsec = dateandtime[1].split(":");
    return datewithyear[1] + '-' + datewithyear[2] + ' ' + timewithsec[0] + ':' + timewithsec[1];
  }
  
}
