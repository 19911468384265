import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';

import { Transfers } from './../models/Transfer';
@Injectable({
  providedIn: 'root'
})
export class TransferService {
  private transferCollection: AngularFirestoreCollection<Transfers>;
  private buySellCollection: AngularFirestoreCollection;
  constructor(
    public db: AngularFirestore,
    public afAuth: AngularFireAuth,
  ) {
    this.transferCollection = this.db.collection('Transfers');
    this.buySellCollection = this.db.collection('Buy_Sell');
  }

  addTransfer(transfer: Transfers) {
    return new Promise<any>((resolve, reject) => {
      // const transferRef: AngularFirestoreDocument<any> = this.db.doc(`Transfers/${transfer.uid}`);
      // resolve(transferRef.set(transfer, { merge: true }));
      this.transferCollection.add(transfer).then(res => {
        resolve(res);
      }, err => reject(err));
    });
  }

  addBuySell(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.buySellCollection.add(data).then(res => {
        resolve(res);
      }, err => reject(err));
    });
  }
}
