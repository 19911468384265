import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';

import { AuthService } from '../service/auth.service';
import { UserService } from '../service/user.service';
import { BitshareService } from '../service/bitshare.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Global } from '../global/global';
@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {
  public user: any;
  public userDocId: string;
  public checkUserEvent: any;
  public UserAction: any;
  public errorMessage: string;
  public emailVerified: any;
  public mobileNoVerified: any;
  constructor(
    private global: Global,
    private fb: FormBuilder,
    private authService: AuthService,
    private userService: UserService,
    public afAuth: AngularFireAuth,
    public router: Router,
    private bitshareService: BitshareService,
  ) {
    this.userService.detectMobileDevices();
  }

  ngOnInit() {
    this.authService.doInvisibleReChaptcha();
    this.userService.getCurrentUser().then(([user, udid]) => {
      this.global.loggedInUser = user;
      this.user = user;
      this.userDocId = udid;
      if (user.emailVerified === false) {
        this.authService.doSentVerificationEmail().then(emailSend => {
        }, err => {
          console.log('err', err);
          this.errorMessage = 'Something went wrong while sending email verification, please try again';
          // this.ngxService.stop();
        });
      } else {
        this.router.navigate(['/verify-mobile']);
      }
      this.getUserAction(this.userDocId);
    });
  }

  getUserAction(uid) {
    const checkdata = setInterval(() => {
    this.userService.doCheckUSer(uid).then(userAction => {
      if (userAction.emailVerified === true) {
        this.router.navigate(['/verify-mobile']);
      }
      if (userAction.mobileNoVerified === true) {
        this.router.navigate(['/b/create']);
      }
      if (userAction.accountName !== '') {
        this.router.navigate(['/kyc']);
        clearInterval(checkdata );
      }
    });
  }, 3000);

  // firebase.firestore().collection('Users').doc(uid).onSnapshot().subscribe((snapshot) => {
  //   console.log('this.router',this.router);
  //   this.checkUserEvent = snapshot.data();
  //   if (this.checkUserEvent) {
  //     console.log('snapshot.data', this.checkUserEvent);
  //     if (snapshot.data().emailVerified === true) {
  //       console.log('emailVerified()');
  //       this.router.navigate(['/verify-mobile']);
  //     }
  //     if (snapshot.data().mobileNoVerified === true) {
  //       console.log('mobileNoVerified()');
  //       this.router.navigate(['/b/create']);
  //     }
  //   }
  // }, function (error) {
  //   console.error(error);
  // });
  }

}
