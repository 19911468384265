import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../global/global';

import { TransferService } from '../service/transfer.service';
@Component({
  selector: 'app-buy-success',
  templateUrl: './buy-success.component.html',
  styleUrls: ['./buy-success.component.scss']
})
export class BuySuccessComponent implements OnInit {
  private transactionId: string;
  private transactionStatus: string;
  constructor(
    public global: Global,
    private router: Router,
    private route: ActivatedRoute,
    private transferService: TransferService
  ) { }

  ngOnInit() {
    this.global.currentRoute = '/buy-success';
    this.transactionId = this.route.snapshot.queryParamMap.get('transactionId');
    this.transactionStatus = this.route.snapshot.queryParamMap.get('transactionStatus');
    const transactionData = JSON.parse(localStorage.getItem('transactionData'));
    transactionData.transaction_id = this.transactionId;
    transactionData.payment_status = this.transactionStatus;
    console.log('transactionData', transactionData);
    this.saveTransactionDetails(transactionData);
  }

  saveTransactionDetails(data) {
    this.transferService.addBuySell(data).then(buy => {
      console.log('buy', buy);
    }, error => {
      console.log('error', error);
    });
  }

}
