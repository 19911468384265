import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FormGroup, Validators, FormControl, FormGroupDirective, NgForm, FormBuilder, AbstractControl } from '@angular/forms';
import * as firebase from 'firebase/app';

import { NgxUiLoaderService } from 'ngx-ui-loader';

import { AuthService } from '../service/auth.service';
import { UserService } from '../service/user.service';
import { BitshareService } from '../service/bitshare.service';
import { Global } from '../global/global';
import { BitshareuserService } from '../service/bitshareuser.service';
import { PreviousrouteService } from '../service/previousroute.service';

@Component({
  selector: 'app-bitshares-login',
  templateUrl: './bitshares-login.component.html',
  styleUrls: ['./bitshares-login.component.scss']
})
export class BitsharesLoginComponent implements OnInit {
  public loading = false;
  public signUpForm: FormGroup;
  public successMessage: string;
  public errorMessage: string;
  public credentials: any;
  public showLoginRequestMessage: boolean;
  public user: any;
  public previousUrl: string;

  constructor(
    public global: Global,
    private router: Router,
    private route: ActivatedRoute,
    private ngxService: NgxUiLoaderService,
    private authService: AuthService,
    private userService: UserService,
    public bitshareService: BitshareService,
    public bitshareuserService: BitshareuserService,
    public previousRouteService: PreviousrouteService,
  ) {
    
    this.userService.getCurrentUser().then(([user, udid]) => {
      
      if (user) {
        this.user = user;
        this.showLoginRequestMessage = false;
      } else {
        this.showLoginRequestMessage = true
        //this.router.navigate(['/dashboard']);
      }
    });
    
  }

  ngOnInit() {
    this.ngxService.stopLoader('UnlockBS');
    //this.ngxService.stopLoader('UnlockTrade');
    this.createSignUpForm();
  }
  validationMessages = {
  
    password: [
      { type: 'required', message: 'Passphrase is required.' },
    ]
  };

  get f() { return this.signUpForm.controls; }

  createSignUpForm() {
    this.signUpForm = new FormGroup({
      password: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(12),
      ])),
    });
  }
  
  // getting the form control elements
  get password(): AbstractControl {
      return this.signUpForm.controls['password'];
  }


  

  registerWithUsername(form) {
    if (this.signUpForm.invalid) {
      return;
    }
    this.ngxService.start();
    this.loading = true;
    this.errorMessage = '';
    this.successMessage = '';
    /*
    var CryptoJS = require("crypto-js");
    // Encrypt
    var encPass = CryptoJS.AES.encrypt(form.value.password, 'BS Account').toString();
    // Decrypt
    //var bytes  = CryptoJS.AES.decrypt(ciphertext, 'BS Account');
    //var originalText = bytes.toString(CryptoJS.enc.Utf8);
    */
    form.value.username = this.user.accountName
    console.log('form.value', form.value)
    this.bitshareService.loginAccountBS(form.value).then(logInBS => {
      console.log('logInBS', logInBS);

      if(logInBS.error) {
        this.loading = false;
        this.errorMessage = logInBS.error;
        this.ngxService.stop();
      } else {
        this.signUpForm.reset();
        this.successMessage = logInBS.success;
        this.bitshareuserService.token = logInBS.key;
        this.bitshareuserService.lockedFlag = true;
        this.bitshareuserService.memo = logInBS.memo;
        this.ngxService.stop();
        //localStorage.setItem('bitshareLoginMessage', 'You have logged in using Bitshares account, now you can do Transfer(s).');
        //this.router.navigate(['/transfer']);
        const prevURL = this.previousRouteService.getPreviousUrl();
        if (prevURL) {
          if (prevURL !== '/b/login') {
            this.router.navigate([prevURL]);
          } else {
            this.router.navigate(['/dashboard']);
          }
        } else {
          this.router.navigate(['/transfer']);
        }
      }
    });
    
    // do register in bitsharesJS
    //let regInBS = this.bitshareService.createAccountBS(form.value.username, form.value.password);

  }

}
