import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, Validators, FormControl } from '@angular/forms';

import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { TranslateService } from '@ngx-translate/core';

import { AuthService } from '../service/auth.service';
import { UserService } from '../service/user.service';
import { BitshareService } from '../service/bitshare.service';

import { Global } from '../global/global';
import { BitshareuserService } from '../service/bitshareuser.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @ViewChild('linkMultipleAccount', { static: true }) linkMultipleAccount: ElementRef<HTMLElement>;
  private supportedPopupSignInMethods = [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    firebase.auth.GithubAuthProvider.PROVIDER_ID,
    firebase.auth.TwitterAuthProvider.PROVIDER_ID
  ];
  public loginForm: FormGroup;
  private EMAILPATTERN = /^(?:\d{8}|[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3})$/;
  public linkedProvider: any;
  public credentials: any;
  public errorMessage: string;
  public bbConfig: any;
  public loading = false;
  public popupErrorMessage;
  constructor(
    private router: Router,
    private afAuth: AngularFireAuth,
    private ngxService: NgxUiLoaderService,
    private authService: AuthService,
    private userService: UserService,
    public global: Global,
    public bitshareService: BitshareService,
    private translate: TranslateService,
    public bitshareuserService: BitshareuserService,
  ) {
    this.createLoginForm();
    this.userService.detectMobileDevices();
  }

  validationMessages = {
    email: [
      { type: 'required', message: 'Email is required.' },
      { type: 'pattern', message: 'Enter valid email.' }
    ],
    password: [
      { type: 'required', message: 'Password is required.' },
      { type: 'minlength', message: ' Minimum 6 character.' },
      { type: 'maxlength', message: 'Maximum 12 character.' },
    ],
  };

  get f() { return this.loginForm.controls; }

  createLoginForm() {
    this.loginForm = new FormGroup({
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern(this.EMAILPATTERN)
      ])),
      password: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(6),
      ]))
    });
  }

  ngOnInit() {
    this.userService.getCurrentUser().then(user => {
      if (this.global.loggedIn) {
        //get base asset and save it in localstorage
        this.bitshareService.getBaseAsset().then(BASE_ASSET => {
            localStorage.setItem('BASE_ASSET', BASE_ASSET);
        });
        //setTimeout(()=>{
         this.checkUserStatus();
        //}, 2000);
      }
    });
  }

  signUp() {
    this.router.navigate(['/signup']);
  }

  forgotPassword() {
    this.router.navigate(['/forgot-password']);
  }

  loginWithFacebook() {
    this.authService.doFacebookLogin().then(res => {
      const uid = res.user.uid;
      const additionalUserInfo = res.additionalUserInfo.profile;
      this.checkUserAfterLogin('facebook', uid, additionalUserInfo);
      this.bitshareService.wakeUpAPI().then(value => {
      });
    }, err => {
      if (err.code === 'auth/account-exists-with-different-credential') {
        console.log('Facebook auth err account with different credential');
        this.linkAccount(err.credential, err.email);
      }
    });
    // var provider = new firebase.auth.FacebookAuthProvider();
    // this.afAuth.auth.signInWithRedirect(provider)
    // console.log('face')
    // this.afAuth.auth.getRedirectResult().then(function(result) {
    //   // The firebase.User instance:
    // console.log('getRedirectResult')

    //   var user = result.user;
    //   // The Facebook firebase.auth.AuthCredential containing the Facebook
    //   // access token:
    //   var credential = result.credential;
    //   // As this API can be used for sign-in, linking and reauthentication,
    //   // check the operationType to determine what triggered this redirect
    //   // operation.
    //   var operationType = result.operationType;
    //   const uid = result.user.uid;
    //   const additionalUserInfo = result.additionalUserInfo.profile;
    //   // additionalUserInfo.emailVerified = true;
    //   console.log('additionalUserInfo', additionalUserInfo);
    //   this.checkUserAfterLogin('facebook', uid, additionalUserInfo);
    // }, function(error) {
    //   // The provider's account email, can be used in case of
    //   // auth/account-exists-with-different-credential to fetch the providers
    //   // linked to the email:
    //   var email = error.email;
    //   // The provider's credential:
    //   var credential = error.credential;
    //   // In case of auth/account-exists-with-different-credential error,
    //   // you can fetch the providers using this:
    //   if (error.code === 'auth/account-exists-with-different-credential') {
    //     this.afAuth.auth.fetchSignInMethodsForEmail(email).then(function(providers) {
    //       // The returned 'providers' is a list of the available providers
    //       // linked to the email address. Please refer to the guide for a more
    //       // complete explanation on how to recover from this error.
    //     });
    //   }
    // });
  }
  loginWithApple() {
    this.loading = true;
    this.authService.doAppleLogin().then(res => {
      const uid = res.user.uid;
      const additionalUserInfo = res.additionalUserInfo.profile;
      this.checkUserAfterLogin('apple', uid, additionalUserInfo);
    }, err => {
      this.loading = false;
      console.log('err', err);
      this.errorMessage = 'Something went wrong, please try again';
    });
  }
  loginWithGoogle() {
    this.loading = true;
    this.authService.doGoogleLogin().then(res => {
      const uid = res.user.uid;
      const additionalUserInfo = res.additionalUserInfo.profile;
      this.checkUserAfterLogin('google', uid, additionalUserInfo);
      this.bitshareService.wakeUpAPI().then(value => {
      });
    }, err => {
      this.loading = false;
      console.log('err', err);
      this.errorMessage = 'Something went wrong, please try again or sign up below';
    });
  }
  loginWithTwitter()  {
    this.loading = true;
    this.authService.doTwitterLogin().then(res => {
      const uid = res.user.uid;
      const additionalUserInfo = res.additionalUserInfo.profile;
      this.checkUserAfterLogin('twitter', uid, additionalUserInfo);
      this.bitshareService.wakeUpAPI().then(value => {
      });
    }, err => {
      this.loading = false;
      if (err.code === 'auth/account-exists-with-different-credential') {
        console.log('Twitter auth err account with different credential')
        this.linkAccount(err.credential, err.email);
      } else  {
        this.errorMessage = 'Something went wrong, please try again or sign up below';
      }
    });
  }
  loginWithEmail(form) {
    console.log('login');
    this.loading = true;
    this.global.mobileVerifiedMsg = '';
    this.global.passwordResetMsg = '';
    this.authService.doLogin(form.value).then(res => {
      const uid = res.user.uid;
      this.checkUserAfterLogin('email', uid, null);
      this.bitshareService.wakeUpAPI().then(value => {
      });
    }, error => {
      console.log(error);
      this.loading = false;
      if (error.code === 'auth/wrong-password') {
        this.errorMessage = 'Invalid credentials, please try again';
      } else {
        this.errorMessage = 'Something went wrong, please try again or sign up below';
      }
    });
  }

  linkAccount(credentials, email) {
    this.credentials = credentials;
    firebase.auth().fetchSignInMethodsForEmail(email).then(providers => {
      const firstPopupProviderMethod = providers.find(p => this.supportedPopupSignInMethods.includes(p));
      // Test: Could this happen with email link then trying social provider?
      if (!firstPopupProviderMethod) {
        throw new Error(`Your account is linked to a provider that isn't supported.`);
      }

      const linkedProvider = this.getProvider(firstPopupProviderMethod);
      const el: HTMLElement = this.linkMultipleAccount.nativeElement;

      linkedProvider.setCustomParameters({ login_hint: email });
      this.linkedProvider = linkedProvider;
      setTimeout(() => {
        el.click();
      }, 1000);
    });
  }
  signInWithPopup(linkedProvider, credentials) {
    console.log('Continue button click')
    firebase.auth().signInWithPopup(linkedProvider).then(result => {
      result.user.linkWithCredential(credentials).then(res => {
        // setTimeout(() => {
          this.checkUserStatus();
        //  }, 2000);
      });
    }, error => {
      if(error.code === 'auth/popup-blocked') {
        this.popupErrorMessage = 'Please click continue button to proceed further';
      } else {
        this.errorMessage = 'Something went wrong, please try again';
      }
    });
  }

  getProvider(providerId) {
    switch (providerId) {
      case firebase.auth.GoogleAuthProvider.PROVIDER_ID:
        return new firebase.auth.GoogleAuthProvider();
      case firebase.auth.FacebookAuthProvider.PROVIDER_ID:
        return new firebase.auth.FacebookAuthProvider();
      case firebase.auth.GithubAuthProvider.PROVIDER_ID:
        return new firebase.auth.GithubAuthProvider();
        case firebase.auth.TwitterAuthProvider.PROVIDER_ID:
        return new firebase.auth.TwitterAuthProvider();
      default:
        throw new Error(`No provider implemented for ${providerId}`);
    }
  }
  addUserData(uid, email, emailVerified, firstName, lastName, photoURL, mobileNo, provider) {
    const user = { uid, accountName: '', email, emailVerified: true, firstName, lastName, mobileNo, mobileNoVerified: false, createdOn: new Date(), currency: 'USD', language: 'en', photoURL, roles: { admin: false } };
    this.userService.addUserDetails(uid, user).then(newUser => {
      this.userService.getCurrentUser();
      this.router.navigate(['/verify-mobile']);
    });
  }

  checkUserAfterLogin(provider, uid, additionalUserInfo) {
    console.log('checkuser')
    this.userService.getCurrentUser().then(user => {
      if (user) {
        console.log('user', user);
        localStorage.setItem('randomUsername', JSON.stringify(user.accountName));
        this.bitshareuserService.username = user.accountName;
        this.translate.use(user.language);
        if (user[0].mobileNo === null && user[0].mobileNoVerified === false) {
          this.router.navigate(['/verify-mobile']);
        } else if (user[0].accountName === null || user[0].accountName === '') {
          this.router.navigate(['/b/create']);
        } else if (user[0].accountName && user[0].accountName !== '' && user[0].accountName !== null) {
          console.log('going to dashboard by nav url 1')
          this.router.navigateByUrl('/dashboard');
        }
        
        // else if (user[0].kyc) {
        //   if (user[0].kyc.status === 'READY_FOR_KYC' || user[0].kyc.status === 'REJECTED' || user[0].kyc.status === 'REJECTED_PROCESSED' || user[0].kyc.status === 'COLLECTING') {
        //     this.router.navigate(['/kyc']);
        //   } else if (user[0].kyc.status === 'PASSED' || user[0].kyc.status === 'PENDING') {
        //     this.router.navigate(['/dashboard']);
        //   }
        // }
      }  else {
        console.log('else')
        if (provider === 'google') {
          this.addUserData(uid, additionalUserInfo.email, additionalUserInfo.emailVerified, additionalUserInfo.given_name, additionalUserInfo.family_name, additionalUserInfo.picture, null, provider);
        } else if (provider === 'facebook') {
          this.addUserData(uid, additionalUserInfo.email, additionalUserInfo.emailVerified, additionalUserInfo.first_name, additionalUserInfo.last_name, additionalUserInfo.picture.data.url, null, provider);
        } else if (provider === 'apple') {
          this.addUserData(uid, additionalUserInfo.email, additionalUserInfo.emailVerified, null, null, null, null, provider);
        } else if (provider === 'twitter') {
          this.addUserData(uid, additionalUserInfo.email, additionalUserInfo.emailVerified, additionalUserInfo.name, null, additionalUserInfo.profile_image_url, null, provider);
        } else {
          console.log('No need since we created account with email and password');
        }
      }
    });
  }
  checkUserStatus() {
    this.popupErrorMessage = '';
    this.userService.getCurrentUser().then(user => {
      if (user) {
        localStorage.setItem('randomUsername', JSON.stringify(user.accountName));
        this.bitshareuserService.username = user.accountName;
        this.translate.use(user.language);
        if (user[0].mobileNo === null && user[0].mobileNoVerified === false) {
          this.router.navigate(['/verify-mobile']);
        } else if (user[0].accountName === null || user[0].accountName === '') {
          this.router.navigate(['/b/create']);
        } else if (user[0].accountName && user[0].accountName !== '' && user[0].accountName !== null) {
          console.log('going to dashboard by nav url 2')
          this.router.navigateByUrl('/dashboard');
        }
        
        //  else if (user[0].kyc) {
        //   if (user[0].kyc.status === 'READY_FOR_KYC' || user[0].kyc.status === 'REJECTED' || user[0].kyc.status === 'REJECTED_PROCESSED' || user[0].kyc.status === 'COLLECTING') {
        //     this.router.navigate(['/kyc']);
        //   } else if (user[0].kyc.status === 'PASSED' || user[0].kyc.status === 'PENDING') {
        //     this.router.navigate(['/dashboard']);
        //   }
        // }
      }
    });
  }

}