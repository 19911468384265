import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appInputField]'
})
export class InputFieldDirective {

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) { 
    renderer.setStyle(el.nativeElement, 'bottom', '20px');
    renderer.setStyle(el.nativeElement, 'position', 'relative');
    renderer.setStyle(el.nativeElement, 'font-family', 'JosefinSans-Regular');
    renderer.setStyle(el.nativeElement, 'font-size', '12px');
    renderer.setStyle(el.nativeElement, 'color', '#f6120f');

  }

}
