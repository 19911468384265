import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateonly'
})
export class DateonlyPipe implements PipeTransform {
  
  transform(value: any): any {
    if(value) {
	  var currDateTime = value.split("T");
	  var currDate = currDateTime[0];
	  var dateInfo = currDate.split("-");
	  var retDate = (dateInfo[2] + '/' + dateInfo[1] + '/' + dateInfo[0]).toString();
	  return retDate;
	} else {
		return value;
	}
  }

}
