import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SignupComponent } from './signup/signup.component';
import { ProfileComponent } from './profile/profile.component';
import { AccountsComponent } from './accounts/accounts.component';
import { KycComponent } from './kyc/kyc.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { GoogleActionComponent } from './google-action/google-action.component';
import { VerifyMobileComponent } from './verify-mobile/verify-mobile.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { MoonpayComponent } from './moonpay/moonpay.component';
import { BuySuccessComponent } from './buy-success/buy-success.component';
import { BitsharesCreateAccountComponent } from './bitshares-create-account/bitshares-create-account.component';
import { BitsharesTransferComponent } from './bitshares-transfer/bitshares-transfer.component';
import { PreventLoginGuard, AuthGuard } from '../providers/auth-guard';
import { BitsharesLoginComponent } from './bitshares-login/bitshares-login.component';
import { BitsharesTradeComponent } from './bitshares-trade/bitshares-trade.component';
import { MobileAppComponent } from './mobile-app/mobile-app.component';
import { TransferCompletedComponent } from './transfer-completed/transfer-completed.component';
import { ChartSampleComponent } from './chart-sample/chart-sample.component';
import { ExchangeComponent } from './exchange/exchange.component';
import { PaymentComponent } from './payment/payment.component';
import { BuySellComponent } from './buy-sell/buy-sell.component';

const routes: Routes = [
{ path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'google-action', component: GoogleActionComponent },
  { path: 'verify-mobile', component: VerifyMobileComponent },
  { path: 'verify-email', component: VerifyEmailComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'accounts', component: AccountsComponent },
  { path: 'settings', component: ProfileComponent },
  { path: 'old/trade', component: MoonpayComponent },
  { path: 'old/trade/:id', component: MoonpayComponent },
  { path: 'kyc', component: KycComponent},
  { path: 'buy-success', component: BuySuccessComponent },
  { path: 'b/create', component: BitsharesCreateAccountComponent },
  { path: 'transfer', component: BitsharesTransferComponent },
  { path: 'b/login', component: BitsharesLoginComponent },
  // { path: 'trade', component: BitsharesTradeComponent },

  { path: 'mobile-app', component: MobileAppComponent },
  { path: 'transfer-completed', component: TransferCompletedComponent },
  { path: 'chart-sample', component: ChartSampleComponent },
  { path: 'chart-sample/:fromDate/:toDate', component: ChartSampleComponent },
  { path: 'chart-sample/:fromDate/:toDate/:currOne', component: ChartSampleComponent },
  { path: 'chart-sample/:fromDate/:toDate/:currOne/:currTwo', component: ChartSampleComponent },
  { path: 'trade', component: ExchangeComponent},
  { path: 'payment/:userName', component: PaymentComponent},
  { path: 'buy-sell/:wallet/:email', component: BuySellComponent},
  { path: 'buy-sell', component: BuySellComponent},

  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
