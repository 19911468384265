export class Global {
    public loggedIn = false;
    public loggedInUser: any = {};
    public mobileVerifiedMsg = '';
    public passwordResetMsg = '';
    public authToken = '';
    public currentRoute = null;
    public supportedAssets: any;
   public walletLockedFlag;
   public detectDevice:boolean = true;
   public redirectTo:boolean = true;
}
