import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BitshareuserService {
  public name:string;
  public key:string;
  public lockedFlag: boolean = false;
  public mkey:string;
  
  constructor() { }
  
  get username():string{
    return this.name;
  }
  set username(val: string){
    this.name = val;
  }
  get token():string{
    return this.key;
  }
  set token(val:string){
    this.key = val;
  }
  
  walletLock() {
    this.name = '';
    //this.key = '';
    this.lockedFlag = false;
  }
  
  get memo():string{
    return this.mkey;
  }
  set memo(val:string){
    this.mkey = val;
  }
}
