import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../service/notification.service';
import * as firebase from 'firebase/app';
import { UserService } from '../service/user.service';
import { AuthService } from '../service/auth.service';
import { BitshareService } from '../service/bitshare.service';
import {MatDialog} from '@angular/material/dialog';
import { Global } from '../global/global';
import { ngCopy } from 'angular-6-clipboard';
import { ChangePasswordComponent } from '../change-password/change-password.component';
export interface DialogData {
  newPassword: string;
  confirmPassword: string;
  currentPassword: string;
}
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})


export class ProfileComponent implements OnInit {
  public userProfileForm: FormGroup;
  public changePasswordForm: FormGroup;
  private EMAILPATTERN = /^(?:\d{8}|[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3})$/;
  // public profileEdit: boolean;
  public user: any;
  checkedInterest;
  checkedWithdraw;
  checkedDeposit;
  checkedNotification;
  checkedNews;
  userNotification: any;
  public accessToken: string;
  public successMsg: string;
  public errorMsg: string;
  public modalReference: any;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  showCropper = false;
  public userDocId: string;
  hide = true;
  public provider: any;
  startUpdate: boolean = false;
  public newPassword: string;
  public confirmPassword: string;
  public currentPassword: string;
  public paymentLink;
  public WEB_HOSTNAME;
  public userName;
  constructor(
    public global: Global,
    private userService: UserService,
    private authService: AuthService,
    private ngxService: NgxUiLoaderService,
    private modalService: NgbModal,
    private translate: TranslateService,
    private bitshareService: BitshareService,
    private notifyService: NotificationService,
    public dialog: MatDialog
  ) {
    // this.profileEdit = false;
    this.userService.detectMobileDevices();
  }

  validationMessages = {
    firstName: [
      { type: 'required', message: 'First name is required.' },
    ],
    lastName: [
      { type: 'required', message: 'Last name is required.' },
    ],
    email: [
      { type: 'required', message: 'Email is required.' },
      { type: 'pattern', message: 'Enter valid email.' }
    ],
    mobileNo: [
      { type: 'required', message: 'Mobile no is required.' },
    ],
    language: [
      { type: 'required', message: 'Language is required.' },
    ],
    currency: [
      { type: 'required', message: 'Currency is required.' },
    ],
  };

  fileChangeEvent(event: any, imageCroper): void {
    this.modalReference = this.modalService.open(imageCroper, { size: 'lg' });
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    console.log(event);
  }
  imageLoaded() {
    this.showCropper = true;
    console.log('Image loaded');
  }
  cropperReady() {
    console.log('Cropper ready');
  }
  loadImageFailed() {
    console.log('Load failed');
  }
  get f() { return this.userProfileForm.controls; }
  createUserProfileForm(user) {
    this.userProfileForm = new FormGroup({
      firstName: new FormControl({ value: user.firstName, disabled: true }, Validators.compose([
        Validators.required,
      ])),
      lastName: new FormControl({ value: user.lastName, disabled: true }, Validators.compose([
        Validators.required,
      ])),
      email: new FormControl({ value: user.email, disabled: true }, Validators.compose([
        Validators.required,
        Validators.pattern(this.EMAILPATTERN)
      ])),
      mobileNo: new FormControl({ value: user.mobileNo, disabled: true }, Validators.compose([
        Validators.required,
      ])),
      language: new FormControl({ value: user.language, disabled: false }, Validators.compose([
        Validators.required,
      ])),
      currency: new FormControl({ value: user.currency, disabled: false }, Validators.compose([
        Validators.required,
      ])),
      interest: new FormControl(''),
      withdrawal: new FormControl(''),
      deposit: new FormControl(''),
      notification: new FormControl(''),
      news: new FormControl(''),
      payment: new FormControl('')
    });
  }

  ngOnInit() {
    this.userService.getCurrentUser().then(([user, udid]) => {
      this.user = user;
      this.userName = user.firstName.toLowerCase();
      this.provider = firebase.auth().currentUser.providerData[0].providerId;
      this.userDocId = udid;
      this.accessToken = this.global.authToken;
      this.createUserProfileForm(user);
      this.getEmailSubscription();
      this.startUpdate = false;
    });
    this.WEB_HOSTNAME = JSON.parse(localStorage.getItem('clientConfig')).WEB_HOSTNAME;
    setTimeout(() => { 
      this.paymentLink = this.WEB_HOSTNAME + '/payment/' + this.userName;
      // this.paymentLink = 'http://localhost:5000/payment/athira'
      console.log('this.paymentLink', this.paymentLink);
    }, 4000);
  }
  updateUserProfile(form) {
    this.translate.use(form.value.language);
    this.user.language = form.value.language;
    this.user.currency = form.value.currency;
    this.userService.updateUserDetails(this.user, this.userDocId).then(success => {
      this.showToasterSuccess();
    });
  }
  copytoCliboard(address) {
    console.log('address', address);
    ngCopy(address);
    //this.textCopyStatus = true;
  }
  showToasterSuccess() {
    this.notifyService.showSuccess("Profile updated successfully");
  }
  uploadProfileImage() {
    // this.ngxService.start();
    const fileName = 'profile-picture';
    const myFile: Blob = this.dataURItoBlob(this.croppedImage);
    this.userService.uploadUserProfilePic(myFile, fileName, this.userDocId).then(res => {
      res.subscribe(pre => {
        if (pre === 100) {
          setTimeout(() => {
            this.userService.getUserProfilePic(fileName, this.userDocId).then(url => {
              this.user.photoURL = url;
              this.userService.updateUserDetails(this.user, this.userDocId).then(done => {
                // this.ngxService.stop();
                this.modalReference.close();
              });
            });
          }, 3000);
        }
      });
    }, err => {
      console.log('err', err);
      // this.ngxService.stop();
    });
  }

  dataURItoBlob(dataURI) {
    const binary = atob(dataURI.split(',')[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {
      type: 'image/jpg',
    });
  }


  closePopup() {
    this.changePasswordForm.reset();
    this.modalReference.close();
  }
  openDialog() {
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      height: 'auto',
      width: '700px',
      data: { 
        newPassword:  this.newPassword,
        confirmPassword: this.confirmPassword,
        currentPassword: this.currentPassword
      }
    });
    
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
    });
  }

  changePasswordPopup(changePassword) {
    this.modalReference = this.modalService.open(changePassword, { size: 'lg' });
  }

  updatePassword(form) {
    // this.ngxService.start();
    this.userService.getCurrentUser().then(([user, udid]) => {
      const currentUserCred = {
        email: user.email,
        password: form.value.currentPassword,
        newPassword: form.value.newPassword,
      };

      this.authService.doLogin(currentUserCred).then(res => {
        if (res.user.uid !== undefined) {
          this.authService.updatePassword(currentUserCred.newPassword).then(result => {
            // this.successMsg = 'Password updated successfully';
            this.showToaster();
            this.closePopup();
            // this.ngxService.stop();
          }, err => {
            if (err.code === 'auth/requires-recent-login') {
              this.errorMsg = 'Recent login is so long please Re-Login to change your password';
              this.closePopup();
              // this.ngxService.stop();
            } else {
              this.errorMsg = 'Something went wrong, please try again';
              this.closePopup();
              // this.ngxService.stop();
            }
          });
        }
      }, error => {
        if (error.code === 'auth/wrong-password') {
          // this.ngxService.stop();
          this.changePasswordForm.controls['currentPassword'].setErrors({ invalid: true });
        } else {
          this.errorMsg = 'Something went wrong, please try again';
          this.closePopup();
          // this.ngxService.stop();
        }

      });
    });
  }
  showToaster() {
    this.notifyService.showSuccess("Password updated successfully");
  }
  getEmailSubscription() {
    this.bitshareService.emailSubsriptions(this.global.authToken).then(res => {
      console.log('subscribtions', res);
      this.userNotification = res;
      this.checkedInterest = !res[0].suppressed;
      this.checkedWithdraw = !res[1].suppressed;
      this.checkedDeposit = !res[2].suppressed;
      this.checkedNotification = !res[3].suppressed;
      this.checkedNews = !res[4].suppressed;
      setTimeout(() => {
        this.startUpdate = true;
      }, 1500);
    }, err => {
      console.log(err);
    });
  }

  updateEmailSubscription(value) {
    console.log(value);
    let groupId;
    let status;
    if (value === 'checkedInterest') {
      groupId = this.userNotification[0].id;
      status = !this.checkedInterest;
    } else if (value === 'checkedWithdraw') {
      groupId = this.userNotification[1].id;
      status = !this.checkedWithdraw;
    } else if (value === 'checkedDeposit') {
      groupId = this.userNotification[2].id;
      status = !this.checkedDeposit;
    } else if (value === 'checkedNotification') {
      groupId = this.userNotification[3].id;
      status = !this.checkedNotification;
    } else if (value === 'checkedNews') {
      groupId = this.userNotification[4].id;
      status = !this.checkedNews;
    }
    console.log(groupId, status);
    const data = {
      group_id: groupId,
      suppressed: status
    };
    this.bitshareService.updateEmailSubscription(this.global.authToken, data).then(updateRes => {
      console.log('update', updateRes);
    }, err => {
      console.log(err);
    });
  }
}
