import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/firestore';

// import { environment } from '../../environments/environment';
import { map, shareReplay } from 'rxjs/operators';
import { Observable, combineLatest, Subscription, Subject, timer } from 'rxjs';
import { Apis, Manager, ChainConfig } from 'bitsharesjs-ws';
var { Login, ChainStore, FetchChain, TransactionHelper, Aes, TransactionBuilder, PrivateKey, key } = require("bitsharesjs");
import * as firebase from 'firebase/app';

import { Crypto } from '../service/bitshares-login/account/crypto.js';
import { Settings } from '../service/bitshares-login/account/settings.js';
import { BitSharesApi } from '../service/bitshares-login/account/api.js';
import { estimateFee } from "../feeLibrary.js";
import { Router } from '@angular/router';
import { AmountFormatPipe } from '../pipe/amount-format.pipe';

export interface coinData {
  id: string;
  symbol: string;
  precision: string;
  issuer: string;
  options: string;
  dynamic_asset_data_id: string;
  pid: string;
  owner: string;
  asset_type: string;
  balance: number;
  maintenance_flag: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class BitshareService {
  public httpOptions: any;
  // public apiUrl = environment.bitshare.api;
  public apiUrl;
  public repos;
  public hostUrl;
  public moonPayUrl;
  public moonPayVersion;
  public moonPayApiKey;
  public serverInfo: Array<any> = [];
  public userBalanceData: coinData[] = [];
  public bsUrl: string;
  public bsNodeURLs: Array<any> = [];;
  public domainsAPIURL: string;
  
  constructor(
    private http: HttpClient,
    private afs: AngularFirestore,
    private router: Router
  ) {
    
    const BASEURL_PROP = this.router;
    let BASEURL = BASEURL_PROP['location']['_platformLocation']['location']['origin'];
    console.log('BASEURL', BASEURL);
    if(BASEURL_PROP['location']['_platformLocation']['location']['hostname'] == 'localhost') {
      BASEURL = 'https://api.testnet.blockbasis.com';
    } else {
      BASEURL = BASEURL.replace(/app/g, 'api');
    }
    console.log('BASEURL', BASEURL);
    this.domainsAPIURL = BASEURL;
    this.getSetURL();
    this.moonPayUrl = 'https://api.moonpay.io';
    this.moonPayVersion = 'v3';
    // this.moonPayApiKey = 'pk_test_hvCod142wP9zgevCjoELcJgb7DHlz6'; // Test
    // this.moonPayApiKey = 'pk_live_o6IoqSubJHv16Hkmxk1ULlcqoHNZ3'; // Prod
    this.moonPayApiKey = 'pk_live_R5Lf25uBfNZyKwccAZpzcxuL3ZdJ3Hc';
    this.cacheClientConfig();
    }
  
  getSetURL() {
    return new Promise<any>((resolve, reject) => {
      this.getClientConfig().subscribe(configData => {
        console.log('configData', configData);
        this.apiUrl = 'https://' + configData['API_HOSTNAME'];
        this.hostUrl = 'https://' + configData['PRO_HOSTNAME'];
        if(configData['BITSHARES_NODES'] && configData['BITSHARES_NODES'].length > 0) {    
          this.bsNodeURLs = [];
          this.bsUrl = configData['BITSHARES_NODES'][0];
          //this.bsUrl = "wss://eu.nodes.bitshares.ws";
          for(var i = 0; i<= configData['BITSHARES_NODES'].length-1; i++){
            this.bsNodeURLs.push(configData['BITSHARES_NODES'][i]);
          }
          
          //this.bsNodeURLs = "wss://api.bts.mobi/ws";
          console.log('this.bsNodeURLs-'+this.bsNodeURLs+'-');

          resolve(configData);
        }
      }, err => {
        console.log('err', err);
      });
    });
  }
  ConversionRateFromMoonPay(GATEWAY_WALLETS, CURRENCY) {
    return this.http.get(this.moonPayUrl + '/' + this.moonPayVersion +'/currencies/price?cryptoCurrencies=' + GATEWAY_WALLETS +'&fiatCurrencies='+ CURRENCY +'&apiKey=' + this.moonPayApiKey);
  }
  Conversion(GATEWAY_WALLETS, CURRENCY) {
    return this.http.get(this.moonPayUrl + '/' + this.moonPayVersion +'/currencies/price?cryptoCurrencies=btc&fiatCurrencies=usd&apiKey=' + this.moonPayApiKey);
  }
  ConversionETH(GATEWAY_WALLETS, CURRENCY) {
    return this.http.get(this.moonPayUrl + '/' + this.moonPayVersion +'/currencies/price?cryptoCurrencies=eth&fiatCurrencies=usd&apiKey=' + this.moonPayApiKey);
  }
  getClientConfig() {
    console.log('this.domainsAPIURL', this.domainsAPIURL);
    return this.http.get(this.domainsAPIURL + '/client/config');
  }
  cacheClientConfig() {
    const path = this.domainsAPIURL + '/client/config';
    this.repos = this.http.get<any>(path)
      .pipe(
        map(data => data),
        shareReplay(1),
      );
    this.repos.subscribe(next => {
      localStorage['clientConfig'] = JSON.stringify(next);
    });
  }
  getServerConfig() {
    return this.afs.collection('Config').doc('Server').valueChanges();
  }
  getWsURL() {
    return this.afs.collection('Config').doc('Faucet').valueChanges();
  }
  createAccount(accessToken) {
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + accessToken });
    return this.http.get(this.apiUrl + '/account/create', { headers });
  }

  // Private authendicated API
  getAccountBalance(accessToken, asset) {
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + accessToken });
    return this.http.get(this.apiUrl + '/account/balance?value_in=' + asset, { headers });
  }

  getBalance(uid, marketPrices?, currency?) {
    return this.afs.doc(`Users/${uid}`).collection(`balance`).snapshotChanges().pipe(map((mutation: any[]) => mutation.map(p => {
      const data = p.payload.doc.data();
      data.interest = { amount: 0, asPerUserCurrency: 0 };
      // Appending market price and market price value for user wallet if marketPrices object exist
      if (marketPrices) {
        data.market = { balance: 0, symbol: currency, marketPrice: 0 };
        const userCurrency = (currency ? currency : 'EUR').toLowerCase(); // Set user currency to EUR if not exixt
        const exchangeRates = marketPrices;
        const balanceAsset = p.payload.doc.id.replace(/open./g, '').replace(/OPEN./g, '').toLowerCase();
        const singleMarket = exchangeRates.filter(exchange => {
          const pair = exchange.pair.replace(/open./g, '').replace(/OPEN./g, '').toLowerCase();
          const balancePair = balanceAsset + ':' + userCurrency;
          if (pair === balancePair) {
            return exchange;
          }
        });

        // Append wallet balance if user currency and wallet currency same
        if (userCurrency === balanceAsset) {
          data.market = { balance: data.amount, symbol: userCurrency.toUpperCase(), marketPrice: -1 }; // Set market price to -1 for identification since user curremncy and wallet currency same so no multiplication needed in any place
        }

        if (singleMarket.length > 0) {
          const walletAmount: any = data.amount;
          data.market = { balance: (singleMarket[0].price * walletAmount), symbol: userCurrency.toUpperCase(), marketPrice: singleMarket[0].price };
        }
      }
      return { ...data, symbol: [p.payload.doc.id][0] };
    })));
  }

  getHistory(accessToken, limit, onlyOps) {
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + accessToken });
    return this.http.get(this.apiUrl + '/account/history?first=0&limit=' + limit + '&only_ops=' + onlyOps, { headers });
  }
  getGatewayCoins() {
    if (this.apiUrl) {
        return this.http.get(this.apiUrl + '/gateway/active-wallets');
    } else {
       this.getSetURL().then(configData => {
         return this.http.get(this.apiUrl + '/gateway/active-wallets');
       });
    }
  }
  getGateway() {
    if (this.apiUrl) {
        return this.http.get(this.apiUrl + '/gateway');
    } else {
       this.getSetURL().then(configData => {
         return this.http.get(this.apiUrl + '/gateway');
       });
    }
  }
  async wakeUpAPI() {
    await this.http.get('https://api.testnet.blockbasis.com').subscribe(value => {
      return value;
    });
  }
 
  sortByTime(a, b) {
    if ((a.timestamp ? a.timestamp : a.updated) < (b.timestamp ? b.timestamp : b.updated)) {
      return 1;
    }
    if ((a.timestamp ? a.timestamp : a.updated) > (b.timestamp ? b.timestamp : b.updated)) {
      return -1;
    }
    return 0;

  }

  getTransactions(uid, limit, onlyOps) {
    /*const latestTrans = this.afs.doc(`Users/${uid}`).collection(`transactions`, ref => ref.where('type', '==', 0).orderBy('timestamp', 'desc').limit(limit)).valueChanges();
    return latestTrans;*/
    const latestTrans = this.afs.doc(`Users/${uid}`).collection(`transactions`, ref => ref
      .where('type', '==', 0)
      .limit(limit)
      .orderBy('timestamp', 'desc')
    ).snapshotChanges()
      .pipe(
        map(changes => changes.map(a => {
          const data = a.payload.doc.data() as any;
          data.symbol = a.payload.doc.id;
          return a;
        }))
      );
    return latestTrans;
  }

  getNextTransactions(uid, limit, onlyOps, startAfter) {
    const nextTrans = this.afs.doc(`Users/${uid}`).collection(`transactions`, ref => ref
      .where('type', '==', 0)
      .limit(limit)
      .orderBy('timestamp', 'desc')
      .startAfter(startAfter)
    ).snapshotChanges()
      .pipe(
        map(changes => changes.map(a => {
          const data = a.payload.doc.data() as any;
          data.symbol = a.payload.doc.id;
          return a;
        }))
      );
    return nextTrans;
  }

  feecalcTransfer(accessToken, amount, symbol) {
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + accessToken });
    return this.http.get(this.apiUrl + '/feecalc/transfer?symbol=' + symbol + '&amount=' + amount, { headers });
  }

  feecalcBuy(accessToken, pair, amount) {
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + accessToken });
    return this.http.get(this.apiUrl + '/feecalc/buy?pair=' + pair + '&amount=' + amount, { headers });
  }

  feecalcSell(accessToken, pair, amount) {
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + accessToken });
    return this.http.get(this.apiUrl + '/feecalc/sell?pair=' + pair + '&amount=' + amount, { headers });
  }

  feecalcWithdraw(accessToken, amount, symbol, address) {
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + accessToken });
    return this.http.get(this.apiUrl + '/feecalc/withdraw?amount=' + amount + '&symbol=' + symbol + '&address=' + address, { headers });
  }

  transferAmount(accessToken, toAccount, amount, symbol) {
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + accessToken });
    return this.http.get(this.apiUrl + '/account/transfer?to_account=' + toAccount + '&asset=' + symbol + '&amount=' + amount, { headers });
  }

  withdraw(accessToken, symbol, address, amount, eosMemo) {
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + accessToken });
    return this.http.get(this.apiUrl + '/account/withdraw?symbol=' + symbol + '&address=' + address + '&amount=' + amount + '&memo=' + eosMemo, { headers });
  }

  saving(accessToken, symbol, address, amount, eosMemo) {
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + accessToken });
    return this.http.get(this.apiUrl + '/account/withdraw?symbol=' + symbol + '&address=' + address + '&amount=' + amount + '&memo=' + eosMemo, { headers });
  }

  placeBuyOrder(accessToken, pair, price, amount) {
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + accessToken });
    return this.http.get(this.apiUrl + '/market/buy?base=' + pair + '&price=' + price + '&amount=' + amount + '&expiration=604800&killfill=0', { headers });
  }

  placeSellOrder(accessToken, pair, price, amount) {
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + accessToken });
    return this.http.get(this.apiUrl + '/market/sell?base=' + pair + '&price=' + price + '&amount=' + amount + '&expiration=604800&killfill=0', { headers });
  }

  getDepositKey(accessToken, symbol) {
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + accessToken });
    return this.http.get(this.apiUrl + '/account/deposit?symbol=' + symbol, { headers });
  }

  // getSupportedAsset(accessToken) {
    /*
    const headers = new HttpHeaders({Authorization: 'Bearer ' + accessToken });
    return this.http.get(this.apiUrl + '/assets/supported',  { headers });
    */
  //   return this.getAssetManagement();
  // }

  // Public API
  ticker(base) {
    return this.http.get(this.apiUrl + '/market/ticker?base=' + base);
  }
  getPairMarketPrice() {
    return this.afs.collection('BitShares_Market').valueChanges();
  }
  depositFee(amount, symbol) {
    return this.http.get(this.apiUrl + '/feecalc/deposit?symbol=' + symbol + '&amount=' + amount);
  }

  buyFee(amount, pair) {
    return this.http.get(this.apiUrl + '/feecalc/buy?pair=' + pair + '&amount=' + amount);
  }

  sellFee(amount, pair) {
    return this.http.get(this.apiUrl + '/feecalc/sell?pair=' + pair + '&amount=' + amount);
  }
  getMoonpayQuote(asset, amount, chooseCurrency) {
    // userCurrency = userCurrency.toLowerCase();
    return this.http.get(this.moonPayUrl + '/' + this.moonPayVersion + '/currencies/' + asset + '/quote?apiKey=' + this.moonPayApiKey + '&baseCurrencyAmount=' + amount + '&extraFeePercentage=1&baseCurrencyCode=' + chooseCurrency);
  }
  getMoonpayExchangeRates() {
    return this.http.get(this.moonPayUrl + '/' + this.moonPayVersion + '/currencies/price?apiKey=' + this.moonPayApiKey + '&cryptoCurrencies=btc,eos,eth,xrp,ltc&fiatCurrencies=eur,usd');
  }
  
  verifyKyc(accessToken, formData) {
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + accessToken });
    return this.http.post(this.apiUrl + '/c/kyc', formData, { headers });
  }
  emailSubsriptions(accessToken) {
    return new Promise<any>((resolve, reject) => {
      const headers = new HttpHeaders({ Authorization: 'Bearer ' + accessToken });
      this.http.get(this.apiUrl + '/user/email_subscriptions', { headers }).subscribe(getsub => {
        resolve(getsub);
      }, err => {
        reject(err);
      });
    });
  }

  updateEmailSubscription(accessToken, data) {
    return new Promise<any>((resolve, reject) => {
      const headers = new HttpHeaders({ Authorization: 'Bearer ' + accessToken });
      this.http.post(this.apiUrl + '/user/email_subscriptions', data, { headers }).subscribe(createResponse => {
        resolve(createResponse);
      }, err => {
        reject(err);
      });
    });
  }

  createAccountBS(accessToken, username, ownerKey, activeKey, memoKey) {
    return new Promise<any>((resolve, reject) => {
        /*
        let server = this.bsUrl;
        console.log('server', server);
        Apis.instance(server, true).init_promise.then((res) => {
          // return await Apis.instance().db_api().exec("get_account_by_name", [name]);
         
        }, err => {
          reject(err);
        });
        */
        var bsKeys = this.faucetCreateAccount(accessToken, username, ownerKey, activeKey, memoKey);
        console.log('bskey', bsKeys);
        resolve(bsKeys);
    });
    
  }

  loginAccountBS(formValue) {
    return new Promise<any>((resolve, reject) => {
        let username = formValue.username;
        Promise.all([
          FetchChain("getAccount", username)
        ]).then((accRes) => {
          let [username] = accRes;

          if (username) { //username is valid
            Apis.instance().db_api().exec( "get_chain_id", []).then((chainID:any) => {
                Apis.instance().db_api().exec( "get_config", [chainID]).then((get_config:any) => {
                    console.log('get_config', get_config);
                    Apis.instance().db_api().exec( "get_account_by_name", [username.get("name")] ).then(function(verifyAccount) {
                      console.log('verifyAccount', verifyAccount);
                      console.log('formValue', formValue);
                      let generatedKeys = Login.generateKeys(username.get("name"), formValue.password, ['active', 'owner','memo'], get_config['GRAPHENE_ADDRESS_PREFIX']);
					  console.log('generatedKeys', generatedKeys);
                      if (generatedKeys.pubKeys.active == verifyAccount.active.key_auths[0][0]) {
                        let pKey = generatedKeys.privKeys.active.toWif(); 
                        let mKey = generatedKeys.privKeys.memo.toWif();
                        resolve({'success': 'Valid credentials of Bitshares Account.', 'username':username.get("name"), 'key' : pKey, 'memo' : mKey});
                      } else {
                        let {privKey: activePrivate, pubKey: activePub} = Crypto.KeyFromPassword(username.get("name"), "active", formValue.password);
                        console.log('activePrivate', activePrivate);
                        console.log('activePub', activePub);
                        if (activePub !== verifyAccount.active.key_auths[0][0]) {
                          //console.log("ERRRRORRRRRR ::::::: The pair of login and password do not match!")
                          resolve({'error': 'Passphrase do not match in Bitshares'});
                        } else {
                          let generatedKeys = Login.generateKeys(username.get("name"), formValue.password, ['active', 'owner','memo'], get_config['GRAPHENE_ADDRESS_PREFIX']);
                          let pKey = generatedKeys.privKeys.active.toWif(); 
                          let mKey = generatedKeys.privKeys.memo.toWif();
                          resolve({'success': 'Valid credentials of Bitshares Account.', 'username':username.get("name"), 'key' : pKey, 'memo' : mKey});
                        }   
                      }
                    });
                });
            });
          } else {
            resolve({ 'error': 'Username does not exist in Bitshares' });
          }
        });
        /*
        let server = this.bsUrl;
        Apis.instance(server, true).init_promise.then((res) => {
          ChainStore.init(false).then(() => {
            
          });
        }, err => {
          console.log(err);
          reject(err);
        });
        */
    });
  }
  doBitshareTrade(tradeParam, fromUser, privKey) {
    return new Promise<any>((resolve, reject) => {
      let username = fromUser;
        var tradeResponse:any;

            Promise.all([
              FetchChain("getAccount", username)
            ]).then((userBalInfo)=> {
              let [username] = userBalInfo;

              if (username) {
                //var privKey = "5JyQsoSCWqdESHiux4ASsJdS3qT8Fz36WzgFc3heH921HqVUtEa";

                let pKey = PrivateKey.fromWif(privKey);
                tradeParam.seller = username.get("id");
                console.log('tradeParam', tradeParam);
                let tr = new TransactionBuilder();
                tr.add_type_operation( "limit_order_create", tradeParam);
                console.log('tr properties', tr);
                tr.set_required_fees().then(() => {
                  tr.add_signer(pKey, pKey.toPublicKey().toPublicKeyString());
                  console.log("serialized transaction:", tr.serialize().operations);
                  tr
                  .broadcast()
                  .then((tradeData) => {
                        console.log("limit order success!");
                        console.log('tradeData', tradeData);
                        tradeResponse = tradeData;
                        resolve(tradeData);
                  })
                  .catch(err => {
                      console.error(err);
                      reject(err)
                  });
                });
              }
            });
          
    
    });
  }
  faucetCreateAccount(accessToken, name, okey, akey, mkey) {
    return new Promise<any>((resolve, reject) => {
      firebase.auth().currentUser.getIdToken().then( res => {
        const faucetURL = this.apiUrl + '/faucet';
        const faucetUserInfo = {
          account: {
            name: name,
            owner_key: okey,
            active_key: akey,
            memo_key: mkey,
            refcode: null,
            referrer: null
          }
        }
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + accessToken });
        console.log('headers', headers)
        this.http.post(faucetURL, faucetUserInfo, {headers}).subscribe(createResponse => {
          resolve(createResponse);
          console.log('createResponse', createResponse);
        }, err => {
          reject(err);
        });
      });
    });
  }

  async bsConnect() {

    if(!this.bsUrl) {
       const temp = await this.getSetURL();
       console.log('temp', temp)
       console.log('this.bsUrl', this.bsUrl);
    }
    
    return new Promise<any>((resolve, reject) => {
        if(this.bsUrl) {
            console.log('this.bsUrl', this.bsUrl);
            console.log('this.bsNodeURLs', this.bsNodeURLs);
            const man = new Manager({url: this.bsUrl, urls: this.bsNodeURLs});
            const that = this;
            man.checkConnections().then(function(res) {
                console.log('Latency Check Response ', res);
                const mapped = Object.keys(res).map(key => ({url: key, ms: res[key]}));
                console.log('mapped', mapped);
                console.log('mapped.length', mapped.length);
                
                if(mapped.length) {
                    mapped.sort(function (a, b) {
                      return a.ms - b.ms;
                    });
                    that.bsUrl = mapped['0']['url'];
                    
		    that.bsNodeURLs = [];
                    for(var i = 1; i<= mapped.length-1; i++){
                        that.bsNodeURLs.push(mapped[i]['url']);
                    }
                    
                    console.log('that.bsNodeURLs', that.bsNodeURLs);
                    console.log('that.bsUrl', that.bsUrl);
                    const lowlatser = new Manager({url: that.bsUrl, urls: that.bsNodeURLs});
                    lowlatser.connect().then(function(lowlatserres) {
                        const con = Apis.instance().init_promise;
                        ChainStore.init(false).then(() => {
                            //console.log('con', con);
                            if (Apis.instance().chain_id == '67afd6cb481e7ede5634f6c554489c1a34f84f44d6990e5a10e6fcdc981e67c0') {
                              Apis.instance().db_api().exec( "get_chain_id", []).then((chainID:any) => {
                                console.log('development chainID', chainID);
                                Apis.instance().db_api().exec( "get_config", [chainID]).then((get_config:any) => {
                                    console.log("get_config bsconnect ", get_config);
                                    ChainConfig.setPrefix(get_config['GRAPHENE_ADDRESS_PREFIX']);
                                    
                                });
                              });
                            }
                            resolve(lowlatserres);
                        });  
                    }).catch(function(err) {
                       resolve({'WSSConnection': true});
                    });
                } else {
                  resolve({'WSSConnection': true});
                }
                //man.setCloseCb(null);
            });
            
        }
    });
    
  }
  
  getBitshareUserbalance(username) {
    return new Promise<any>((resolve, reject) => {

            Promise.all([
              FetchChain("getAccount", username)
            ]).then((userBalInfo)=> {
              let [username] = userBalInfo;

              if (username) { //username is valid
                let balanceEntriesObj:any;
                balanceEntriesObj = username.get("balances");
                if (balanceEntriesObj && balanceEntriesObj._root && balanceEntriesObj._root.entries) {
                    const balCurId = balanceEntriesObj._root.entries;
                    let j = 0;
                    for (let i = 0; i < balCurId.length; i++) {
                      const cid = balCurId[i];
              
                      Promise.all([
                        FetchChain("getAsset", cid[0]),
                        FetchChain("getAsset", cid[1])
                      ]).then((allAssetInfo)=> {
                        let [firAssetInfo, secAssetInfo] = allAssetInfo;
                        //console.log('firAssetInfo', firAssetInfo._root.entries)
                        //console.log('secAssetInfo', secAssetInfo._root.entries)
                        if (firAssetInfo._root.entries[0][1] != '1.3.0') {
                            this.userBalanceData[j] = {
                              id: firAssetInfo._root.entries[0][1],
                              symbol: firAssetInfo._root.entries[1][1],
                              precision: firAssetInfo._root.entries[2][1],
                              issuer: firAssetInfo._root.entries[3][1],
                              options: firAssetInfo._root.entries[4][1],
                              dynamic_asset_data_id: firAssetInfo._root.entries[5][1],
                              pid: secAssetInfo._root.entries[0][1],
                              owner: secAssetInfo._root.entries[1][1],
                              asset_type: secAssetInfo._root.entries[2][1],
                              balance: new AmountFormatPipe().transform(secAssetInfo._root.entries[3][1], firAssetInfo._root.entries[2][1]),
                              maintenance_flag: secAssetInfo._root.entries[4][1]
                            }
                            j++;
                        }
                      });
                    }
                    //console.log('userBalanceData', this.userBalanceData);
                    resolve(this.userBalanceData);
                } else {
                  resolve({'message': 'NilBalance'});
                }
              }
            });
            //code end
          
    });
  }
  
  getDepositAddress(accessToken, symbol) {
    const headers = new HttpHeaders({Authorization: 'Bearer ' + accessToken });
    return this.http.get(this.apiUrl + '/c/wallet/'+symbol+'/deposit/',  { headers });
  }
  
  getBitshareFees() {
    return this.afs.collection('BitShares').doc('Fees').valueChanges();
  }

  getEstimateFee() {
    return new Promise<any>((resolve, reject) => {
        Promise.all([
            FetchChain("getObject", "2.0.0") //219 236 223
        ]).then((res)=> { 
            let [obj] = res;
            console.log('-------------obj', obj);
            let amount = estimateFee('transfer', null, obj);
            resolve(amount);
        }).catch(error => { 
            console.log('error', error);
            reject(error);
        });
    });
  }
  
  getBitshareAssetInfo(symbol) {

    return new Promise<any>((resolve, reject) => {
        
      //code begin
      Promise.all([
        FetchChain("getAsset", symbol)
      ]).then((assetInfo)=> {
        console.log('assetInfo', assetInfo);
        resolve(assetInfo)
      });
      //code end
          
    });
  }
  
  getRecentHistory(accountName) {
    return new Promise<any>((resolve, reject) => {
        let accountHistory:any = [];
        
            Promise.all([
              FetchChain("getAccount", accountName)
            ]).then((accInfo)=> {
              let [username] = accInfo;
              var account_id = username.get("id");
              console.log('account_id', account_id)
              Promise.all([
                FetchChain("fetchRecentHistory", account_id)
              ]).then((accHis)=> {
                let [userAccHis] = accHis;
                var current_history = userAccHis.get("history");
				console.log('current_history', current_history);
                if (current_history.size) {
                  if (current_history._root) {
                    var hisRoot = current_history._root;
                    if (hisRoot.array.length) {
                        for (var i=0; i < hisRoot.array.length; i++) {
                            hisRoot.array[i].array.forEach((assetIcon, ind) => {
                                const transacData = assetIcon._root.entries
                                let transType, issuerName, toName;
                                let skipThis = false;
                                if(transacData[1][1]['_tail']['array'][1]['_root']['entries']) { 
                                  transType = 'Entry';
                                  toName = issuerName = '';
                                  if (transacData[1][1]['_tail']['array'][1]['_root']['entries'][2][0] == 'to') {
                                    toName = this.getUsername(transacData[1][1]['_tail']['array'][1]['_root']['entries'][2][1]);
                                  }
                                  if (transacData[1][1]['_tail']['array'][1]['_root']['entries'][1][0] == 'issuer' || account_id == transacData[1][1]['_tail']['array'][1]['_root']['entries'][2][1]) {
                                    issuerName = this.getUsername(transacData[1][1]['_tail']['array'][1]['_root']['entries'][1][1]);
                                  }
                                 
                                  if (transacData[1][1]['_tail']['array'][1]['_root']['entries'][2][1]['_root']) {
                                    if (transacData[1][1]['_tail']['array'][1]['_root']['entries'][2][1]['_root']['entries'][1][1] == '1.3.0') {
                                        skipThis = true;
                                    }
                                  }
                                  if (transacData[1][1]['_tail']['array'][1]['_root']['entries'][3][1]['_root']) { 
                                    if (transacData[1][1]['_tail']['array'][1]['_root']['entries'][3][1]['_root']['entries'][1][1] == '1.3.0') {
                                        skipThis = true;
                                    }
                                  }
                                }
                                if(transacData[1][1]['_tail']['array'][1]['_root']['nodes']) {
                                  transType = 'Nodes';
                                  toName = issuerName = '';
                                  if (transacData[1][1]['_tail']['array'][1]['_root']['nodes'][2][0] == 'to') {
                                    toName = this.getUsername(transacData[1][1]['_tail']['array'][1]['_root']['nodes'][2][1]);
                                  }
                                  if (transacData[1][1]['_tail']['array'][1]['_root']['nodes'][1][0] == 'issuer'  || account_id == transacData[1][1]['_tail']['array'][1]['_root']['nodes'][2][1]) {
                                    issuerName = this.getUsername(transacData[1][1]['_tail']['array'][1]['_root']['nodes'][1][1]);
                                  }
                                  if(transacData[1][1]['_tail']['array'][1]['_root']['nodes'][2][1]['_root']) { 
                                      if (transacData[1][1]['_tail']['array'][1]['_root']['nodes'][2][1]['_root']['entries'][1][1] == '1.3.0') {
                                          skipThis = true;
                                      }
                                  }
                                  if (transacData[1][1]['_tail']['array'][1]['_root']['nodes'][3][1]['_root']) {
                                    if (transacData[1][1]['_tail']['array'][1]['_root']['nodes'][3][1]['_root']['entries'][1][1] == '1.3.0') {
                                        skipThis = true;
                                    }
                                  }
                                }
                                if (skipThis === false) {
                                    Apis.instance().db_api().exec( "get_block", [transacData[3][1]]).then((ordersInfo:any) => {
                                      accountHistory.push({
                                        'Id': transacData[0][1],
                                        'Data': (transType == 'Entry') ? transacData[1][1]['_tail']['array'][1]['_root']['entries'] : transacData[1][1]['_tail']['array'][1]['_root']['nodes'],
                                        'Type': transType,
                                        'Block': transacData[3][1],
                                        'TransactionCount' : transacData[4][1],
                                        'Timestamp': ordersInfo.timestamp,
                                        'issuerName': issuerName,
                                        'toName' : toName,
                                        'userId' : account_id
                                      });
                                    });
                                }
                            });
                        }
                    }
                    
                  }
                  if (current_history._tail) {
                    var hisTail = current_history._tail;
                    hisTail.array.forEach((assetIcon, ind) => {
                        const transTaData = assetIcon._root.entries;
                        let transTaType, transTaInfo, issuerName, toName;
                        let skipThis = false;
                        
                        if(transTaData[1][1]['_tail']['array'][1]['_root']['entries']) {
                            transTaType = 'Entry';
                            toName = issuerName = '';
                            if (transTaData[1][1]['_tail']['array'][1]['_root']['entries'][2][0] == 'to') {
                                toName = this.getUsername(transTaData[1][1]['_tail']['array'][1]['_root']['entries'][2][1]);
                            }
                            if (transTaData[1][1]['_tail']['array'][1]['_root']['entries'][1][0] == 'issuer'  || account_id == transTaData[1][1]['_tail']['array'][1]['_root']['entries'][2][1]) {
                                issuerName = this.getUsername(transTaData[1][1]['_tail']['array'][1]['_root']['entries'][1][1]);
                            }
                            
                            if (transTaData[1][1]['_tail']['array'][1]['_root']['entries'][2][1]['_root']) {
                                if (transTaData[1][1]['_tail']['array'][1]['_root']['entries'][2][1]['_root']['entries'][1][1] == '1.3.0') {
                                    skipThis = true;
                                }
                            }
                            if (transTaData[1][1]['_tail']['array'][1]['_root']['entries'][3][1]['_root']) { 
                                if (transTaData[1][1]['_tail']['array'][1]['_root']['entries'][3][1]['_root']['entries'][1][1] == '1.3.0') {
                                    skipThis = true;
                                }
                            }
                        }
                        if(transTaData[1][1]['_tail']['array'][1]['_root']['nodes']) {
                            transTaType = 'Nodes';
                            toName = issuerName = '';
                            if (transTaData[1][1]['_tail']['array'][1]['_root']['nodes'][2][0] == 'to') {
                              toName = this.getUsername(transTaData[1][1]['_tail']['array'][1]['_root']['nodes'][2][1]);
                            }
                            if (transTaData[1][1]['_tail']['array'][1]['_root']['nodes'][1][0] == 'issuer' || account_id == transTaData[1][1]['_tail']['array'][1]['_root']['nodes'][2][1]) {
                              issuerName = this.getUsername(transTaData[1][1]['_tail']['array'][1]['_root']['nodes'][1][1]);
                            }
                              
                            if(transTaData[1][1]['_tail']['array'][1]['_root']['nodes'][2]['entry'][0] != 'name') { //create account
                                if (transTaData[1][1]['_tail']['array'][1]['_root']['nodes'][2][1]['_root']) {                      
                                    if (transTaData[1][1]['_tail']['array'][1]['_root']['nodes'][2][1]['_root']['entries'][1][1] == '1.3.0') {
                                        skipThis = true;
                                    }
                                }
                            }
                            if (transTaData[1][1]['_tail']['array'][1]['_root']['nodes'][3]['entry'][0] != 'registrar') {
                                if (transTaData[1][1]['_tail']['array'][1]['_root']['nodes'][3][1]['_root']) {
                                    if (transTaData[1][1]['_tail']['array'][1]['_root']['nodes'][3][1]['_root']['entries'][1][1] == '1.3.0') {
                                        skipThis = true;
                                    }
                                }
                            }
                        }
                        if (skipThis === false) {
                            Apis.instance().db_api().exec( "get_block", [transTaData[3][1]]).then((ordersInfo:any) => {
                                accountHistory.push({
                                  'Id': transTaData[0][1],
                                  'Data': (transTaType == 'Entry') ? transTaData[1][1]['_tail']['array'][1]['_root']['entries'] : transTaData[1][1]['_tail']['array'][1]['_root']['nodes'],
                                  'Type': transTaType,
                                  'Block': transTaData[3][1],
                                  'TransactionCount' : transTaData[4][1],
                                  'Timestamp': ordersInfo.timestamp,
                                  'issuerName': issuerName,
                                  'toName' : toName,
                                  'userId' : account_id
                                });
                            });
                        }

                    });
                  }
                }
              });
              resolve(accountHistory);
            });
          
    });
  }
  getUsername(userId) {
    return new Promise<any>((resolve, reject) => {
        let userinfo = userId;
        Promise.all([
            FetchChain("getAccount", userId) //219 236 223
        ]).then((res)=> { 
            let [userinfo] = res;
            if(userinfo) {
              const username = userinfo.get("name");
              resolve(username);
            } else {
              resolve({'response': 'unknownUser'});
            }
        }).catch(error => { 
            console.log('error', error);
            reject(error);
        });
    });
  }
  doBitshareWithdraw(privActKey, privMemKey, fromAccount, memo, amount, currency, fees) {
      return new Promise<any>((resolve, reject) => {
          
            let tr = new TransactionBuilder();

                var privKey = privActKey; 
                let pKeyAcive = PrivateKey.fromWif(privKey);
                let pKeyMemo = PrivateKey.fromWif(privMemKey);
                     

                        
                    let memoSender = fromAccount;
                    let toAccount = "block-basis";
                    let sendAmount = {
                        amount: amount,
                        asset: currency
                    }
                    Promise.all([
                        FetchChain("getAccount", fromAccount),
                        FetchChain("getAccount", toAccount),
                        FetchChain("getAccount", memoSender),
                        FetchChain("getAsset", sendAmount.asset),
                        FetchChain("getAsset", sendAmount.asset)
                    ]).then((res)=> {
                        
                        let [fromAccount, toAccount, memoSender, sendAsset, feeAsset] = res;
                        var feeOptions = feeAsset.get("options");
                        const marketFee = feeOptions._root.nodes;
                        let memoFromKey = memoSender.getIn(["options","memo_key"]);
                        let memoToKey = toAccount.getIn(["options","memo_key"]);
                        let nonce = TransactionHelper.unique_nonce_uint64();
                        let memo_object = {
                            from: memoFromKey,
                            to: memoToKey,
                            nonce,
                            message: Aes.encrypt_with_checksum(
                                pKeyMemo,
                                memoToKey,
                                nonce,
                                memo
                            )
                        }
                        const user_transfer_info_dynamic = {
                            fee: {
                              amount: fees, //fees 2136718 2121093 (Math.pow(10, sendAsset.get('precision')) * sendAmount.amount) * (marketFee[1]['nodes'][0]['entry'][1]/100)
                              asset_id: feeAsset.get("id") //'1.3.0' feeAsset.get("id")
                            },
                            from: fromAccount.get("id"),
                            to: toAccount.get("id"),
                            amount: { amount: (Math.pow(10, sendAsset.get('precision')) * sendAmount.amount).toFixed(), asset_id: sendAsset.get("id") },
                            memo: memo_object
                        };
                        tr.add_type_operation( "transfer", user_transfer_info_dynamic);

                        tr.set_required_fees().then(() => {
                            tr.add_signer(pKeyAcive, pKeyAcive.toPublicKey().toPublicKeyString());
                            tr.serialize();
                            Promise.all([
                                tr.broadcast()
                            ]).then((wdBSRes:any)=> {
                                console.log('wdBSRes', wdBSRes)
                                resolve(wdBSRes);
                            }).catch(error => { 
                                console.log('error', error);
                                reject(error);
                            });
                        });
                    });
                });
            
      
      
  }
  
  BSNumberFormatPrecision(curInfo) {
    const currBal:string = curInfo.balance.toString();
    let selectedWallet: any = curInfo;
    let curBalnFor:any;
    if(currBal.length > curInfo.precision) {
        const f:string = currBal.substring(0, (currBal.length - curInfo.precision));
        const s:string = currBal.substring((currBal.length - curInfo.precision), currBal.length);

        curBalnFor = f+'.'+s;
    } else {
         const diff = curInfo.precision - currBal.length;
         let f = '0.';
         for (var i=1; i<=diff; i++) {
           f = f+'0';
         }
         curBalnFor = f+currBal;
    }
    
    return (parseFloat(curBalnFor));
  }
  
  getAllBSAssetInfo(allBSAssets) {
      console.log('allBSAssets', allBSAssets);
      return new Promise<any>((resolve, reject) => {

              let bitshareAssetInfo: Array<any> = [];
              let bitshareIdAssetInfo: Array<any> = [];

                      
                      Apis.instance().db_api().exec( "lookup_asset_symbols", [allBSAssets]).then((assetsInfoBS:any) => {
                          assetsInfoBS.forEach((bsast, bsind) => {
                              bitshareAssetInfo[bsast.id] = bsast;
                              bitshareIdAssetInfo.push(bsast);
                          });

                      
                  resolve({response:bitshareAssetInfo, flag:true, assocdata:bitshareIdAssetInfo});
              }, err => {
                  console.log(err);
                  reject(err);
              
          });
      });
    
  }
  
  getBaseAsset () {
    return new Promise<any>((resolve, reject) => {
      this.getGateway().subscribe((coreAssetBS: any) => {
        console.log('coreAssetBS', coreAssetBS);

        resolve(coreAssetBS['BASE_ASSET']);
      });
    });
  }
  
  async doCheckWSSConnection() {
      if(Apis.instance().chain_id) {
          console.log('App component here, to init Apis instance', Apis.instance().chain_id);
          //set reset the connection
          if(this.bsUrl) {
             //Apis.reset(this.bsUrl, true).init_promise;
          } else {
              const temp = await this.getSetURL();
              if (temp) {
                console.log('this.bsUrl', this.bsUrl);
                Apis.reset(this.bsUrl, true).init_promise;  
              }
          }
          
      } else {
          console.log('to connect now...');
          this.bsConnect().then((connRes:any) => {
            //console.log('connRes', connRes);
          }).catch(function (data) {
            console.log('WSS Connection Error, need to try again later');
          });
      }
  }
  
  gatewayIssueTest(accessToken, formData) {
    //return this.http.get(this.apiUrl + '/gateway/issue?symbol=' + symbol + '&amount=' + amount);
	//const headers = new HttpHeaders({ Authorization: 'Bearer ' + accessToken });
	//const jsonData = JSON.stringify({"amount":"0.011", "symbol":"BLOCK.BTC", "to":"fuck-fuck"});

	//return this.http.post(this.apiUrl + '/gateway/issue', {"amount":"0.011", "symbol":"BLOCK.BTC", "to":"fuck-fuck"}, { headers });
	
	var headers = new HttpHeaders();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json' );
    //const requestOptions = new RequestOptions({ headers: headers });

    let postData = {
            "amount": "0.011",
            "symbol": "BLOCK.BTC",
            "to": "fuck-fuck"
    }
	return this.http.post(this.apiUrl + '/gateway/issue', postData, { headers: headers })
  }

  gatewayBurnTest(amount, pair) {
    //return this.http.get(this.apiUrl + '/gateway/burn?pair=' + pair + '&amount=' + amount);
  }
}
function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
