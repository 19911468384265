import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/storage';
import { BitshareService } from '../service/bitshare.service';
import { BitshareuserService } from '../service/bitshareuser.service';
import { UserService } from '../service/user.service';
import { FormGroup, Validators, FormControl, FormGroupDirective, NgForm, FormBuilder, AbstractControl } from '@angular/forms';
import { NotificationService } from '../service/notification.service';


export interface DialogData {
  password: string;
}

@Component({
  selector: 'unlock-popup',
  templateUrl: './unlock-popup.html',
  styleUrls: ['./unlock-popup.css']
})
export class UnlockPopup {
  ref: AngularFireStorageReference;
  task: AngularFireUploadTask;
  public signUpForm: FormGroup;
  public successMessage: string;
  public errorMessage: string;
  public user: any;
  public email: any;
  public password: string;
  public loading = false;
  hide = true;

  constructor(
    public dialogRef: MatDialogRef<UnlockPopup>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public bitshareService: BitshareService,
    public bitshareuserService: BitshareuserService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private notifyService: NotificationService
      ) {
    this.createSignUpForm();

    
	this.userService.getCurrentUser().then(([user, udid]) => {
        if (user) {
            this.user = user;
            this.email = user.email;
        }
    });
    this.password = data.password;
    
  }
  validationMessages = {
    password: [
      { type: 'required', message: 'Passphrase is required.' },
      // { type: 'minlength', message: 'Minimum 12 character.' },
    ]
  };

  get f() { return this.signUpForm.controls; }
  onNoClick(): void {
    this.dialogRef.close();
  }
  onSucceesClose(res): void {
    this.dialogRef.close({'token': res.key, 'memo': res.memo});
  }
  createSignUpForm() {
    this.signUpForm = new FormGroup({
      password: new FormControl('', Validators.compose([
        Validators.required,
        // Validators.minLength(12),
      ])),
    });
  }
  showToasterSuccess() {
    this.notifyService.showSuccess("Account is unlocked");
  }
  registerWithUsername(data) {
    if (this.signUpForm.invalid) {
      return;
    }
    this.loading = true;
    data.username = this.user.accountName;
    this.bitshareService.loginAccountBS(data).then(logInBS => {
      console.log('logInBS', logInBS);
      this.loading = false;
      if (logInBS.error) {
        this.loading = false;
        this.errorMessage = logInBS.error;
        // this.ngxService.stop();
      } else {
        this.showToasterSuccess();
        this.signUpForm.reset();
        this.successMessage = logInBS.success;
        this.bitshareuserService.token = logInBS.key;
        this.bitshareuserService.lockedFlag = true;
        this.bitshareuserService.memo = logInBS.memo;
        this.onSucceesClose(logInBS);
      }
    });
  }
}

