import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivationEnd, NavigationEnd } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TranslateService } from '@ngx-translate/core';
import { Location } from "@angular/common";
import { UserService } from './service/user.service';
import { AuthService } from './service/auth.service';
import { BitshareService } from './service/bitshare.service';
import { BitshareuserService } from './service/bitshareuser.service';
import { UnlockPopup } from './unlock-popup/unlock-popup';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from './service/notification.service';
import { environment } from './../environments/environment';
import { Global } from './global/global';
import { Apis, ChainConfig} from 'bitsharesjs-ws';
import { OnPageVisible, OnPageHidden, OnPageVisibilityChange, AngularPageVisibilityStateEnum, OnPagePrerender, OnPageUnloaded } from 'angular-page-visibility';
import { PreviousrouteService } from './service/previousroute.service';

export interface DialogData {
  password: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Block Basis';
  mailPoetEmail = '';
  mailPoetEmailEmpty: string;
  currentLanguage: string;
  public successMessage: string;
  public componentName: any;
  public bar: any;
  public kycBar: any;
  public route: string;
  public password: string;
  public appStoreURL;
  public playStoreURL;
  public headerFooter;
  public kycMessage: boolean = false;
  public kycMessageVerify: boolean = false;
  constructor(
    public global: Global,
    private ngxService: NgxUiLoaderService,
    private authService: AuthService,
    private userService: UserService,
    private translate: TranslateService,
    private router: Router,
    private bitshareService: BitshareService,
    public bitshareuserService: BitshareuserService,
    public location: Location,
    public dialog: MatDialog,
    private notifyService: NotificationService,
    public previousRouteService: PreviousrouteService
  ) {
    console.log(environment.apiUrl);
    this.getCurrentUser();
    translate.setDefaultLang('en');
    router.events.subscribe(val => {
      if (location.path() !== '') {
        this.route = location.path();
        if (this.route === '/b/create') {
          this.componentName = false;
        } else {
          this.componentName = true;
        }
        if (this.route === '/kyc') {
          this.bar = false;
        } else  {
          this.bar = true;
        }
        if (this.route === '/mobile-app') {
          this.headerFooter = false;
        } else {
          this.headerFooter = true;
        }
      }
    });
  }

  ngOnInit() {
    (function(d, m){
      var kommunicateSettings = {"appId":"3e1c54e9c2b9336130c71f0f0bb0f1d71","conversationTitle":"Mark Dencker","chatLauncherHtml":"<img src='../../assets/images/chat-img.png'>"};
      var s = document.createElement("script"); s.type = "text/javascript"; s.async = true;
      s.src = "https://api.kommunicate.io/v2/kommunicate.app";
      var h = document.getElementsByTagName("head")[0]; h.appendChild(s);
      (window as any).kommunicate = m; m._globals = kommunicateSettings;
    })(document, (window as any).kommunicate || {});

    this.bitshareService.getClientConfig().subscribe(configData => {
      this.appStoreURL =  configData["APPSTORE_URL_IOS "];
      this.playStoreURL = configData['APPSTORE_URL_ANDROID'];
    }, err => {
      console.log('err', err);
    });
    
    /*
    setInterval(() => {
      this.doCheckWSSConnection();
    }, 10000);
    */
  }
  subscriptionToMailpoet() {
    this.mailPoetEmailEmpty = '';
    this.successMessage = '';
    if (this.mailPoetEmail === '' || this.mailPoetEmail === null) {
      this.mailPoetEmailEmpty = 'Please remember to input your email';
      console.log('122');
    } else {
      this.userService.subscribeToMailPoet(this.mailPoetEmail).subscribe(res => {
        this.successMessage = 'Thank you for subscribing';
      }, err => {
        console.log('mail', err.message);
        this.mailPoetEmailEmpty = 'This email is already subscribed';
      });
    }
  }
  switchLanguage(language) {
    localStorage.setItem('language', language);
    if (language === 'en') {
      this.currentLanguage = 'en';
      this.translate.use('en');
    } else if (language === 'da') {
      this.currentLanguage = 'da';
      this.translate.use('da');
    } else {
      this.currentLanguage = language.target.value;
      this.translate.use(language.target.value);
    }
    if (language) {
      this.translate.use(language);
    } else {
      this.translate.use('en');
    }
  }

  signOut() {
    this.authService.signOut();
  }

  getCurrentUser() {
    this.userService.getCurrentUser().then(user => {
      // if (!user) {
      //   this.router.navigate(['/login']);
      // }
      if (user) {
        if (user[0].mobileNo === null && user[0].mobileNoVerified === false) {
          this.router.navigate(['/verify-mobile']);
        } else if (user[0].accountName === null || user[0].accountName === '') {
          this.router.navigate(['/b/create']);
        } else if (user[0].accountName && user[0].accountName !== '' && user[0].accountName !== null) {
          console.log('Dashboard Six');
          const isChartUrl = this.route.split("chart-sample");
          if(!isChartUrl.length) { //this.route != '/chart-sample'
            this.router.navigate(['/dashboard']);
          }
        } 
        // if (user[0].kyc) {
        if (user[0].language) {
              this.translate.setDefaultLang(user[0].language);
              this.currentLanguage = user[0].language;
            } else {
              this.translate.setDefaultLang('en');
              this.currentLanguage = 'en';
            }
          // if(user[0].kyc.status === 'PENDING') {
          //       this.kycMessage = true;
          //     }
          // if (user[0].kyc.status === 'READY_FOR_KYC' || user[0].kyc.status === 'REJECTED' || user[0].kyc.status === 'REJECTED_PROCESSED' || user[0].kyc.status === 'COLLECTING') {
          //   this.kycMessageVerify = true;
          // }
          //  else if (user[0].kyc.status === 'PASSED' || user[0].kyc.status === 'PENDING') {
          //   this.router.navigate(['/dashboard']); //to load wss test page
			
          // }
        // }
      } else {
		this.translate.setDefaultLang('en');
		this.currentLanguage = 'en';
	  }
    });
  }
  deleteKeys() {
    this.bitshareuserService.walletLock();
    // this.showToasterSuccess();
  }
  // showToasterSuccess() {
  //   this.notifyService.showSuccess("Account is locked");
  // }
  
  openDialog() {
    const dialogRef = this.dialog.open(UnlockPopup, {
      height: 'auto',
      width: '700px',
      data: { password: this.password }
    });
    
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if(result) {
        if(result.success) {
          if (result.token) {
            this.bitshareuserService.token = result.token;
          }
          if(result.memo) {
            this.bitshareuserService.memo = result.memo;
          }
        }
      }
    });
  }
  /*
  @HostListener('document:mousemove', ['$event']) 
    onMouseMove(e) {
      console.log(e);
      this.doCheckWSSConnection();
    }
     
  @HostListener('document:keypress', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) { 
      console.log(event.key);
      this.doCheckWSSConnection();
    }
    
  @OnPageHidden()
    logWhenPageHidden (): void {
      console.log( 'OnPageHidden => hidden' );
    }
  
  @OnPageVisible()
    logWhenPageVisible (): void {
      console.log( 'OnPageVisible => visible' );
      this.doCheckWSSConnection();
    }  
  
  doCheckWSSConnection() {
      if(Apis.instance().chain_id) {
          console.log('App component here, to init Apis instance ', Apis.instance().chain_id);
          //set reset the connection
          if(!this.bitshareService.bsUrl) {
              const temp = this.bitshareService.getSetURL();
              console.log('temp', temp);
              console.log('this.bitshareService.bsUrl', this.bitshareService.bsUrl);
          }
          Apis.reset(this.bitshareService.bsUrl, true).init_promise;
      } else {
          console.log('to connect now...');
          this.bitshareService.bsConnect().then((connRes:any) => {
            //console.log('connRes', connRes);
          }).catch(function(data) {
            console.log('WSS Connection Error, need to try again later');
          });
      }
  }
  */
}
