import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BitshareService } from '../service/bitshare.service';
import { BitshareuserService } from '../service/bitshareuser.service';
import { UserService } from '../service/user.service';

@Component({
  selector: 'app-buy-sell',
  templateUrl: './buy-sell.component.html',
  styleUrls: ['./buy-sell.component.scss']
})
export class BuySellComponent implements OnInit {
public wallet: any;
public email: any;
public url: string;
public moonPayQuote: any;
public walletKey: any;
public urlSafe: SafeResourceUrl;
public iframeLoadVar = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    public sanitizer: DomSanitizer,
    private bitshareService: BitshareService,
  public bitshareuserService: BitshareuserService,
  private userService: UserService,
  ) { 
    this.activatedRoute.params.subscribe(allParams => {
      if (allParams) {
        const symbol = allParams.wallet
          this.wallet = symbol.toLowerCase();
          this.email = allParams.email;
      }
      console.log('allParams', this.wallet);
      if(this.wallet === 'btc') {
        this.walletKey = "bc1qpp5p6qtvmq2v4sr4wvyx9r5vp92tqz8jqdlktg";
      } else if(this.wallet === 'eth') {
        this.walletKey = "0x9bEc36393eF54C00Cbd8b1d98fD9336553399c65";
      } else if(this.wallet === 'ltc') {
        this.walletKey = "ltc1q9gc85d5gchmea52u7evvw96s28wsah5fc29l40";
      }
    });
  }

  ngOnInit(): void {
    this.userService.getCurrentUser().then(user => {
      if (user) {
        this.bitshareuserService.username = user[0].accountName;
        // setTimeout(() => {
          const stagURL = 'https://buy-staging.moonpay.com/?apiKey=pk_test_hvCod142wP9zgevCjoELcJgb7DHlz6';
          const prodURL = 'https://buy.moonpay.com/?apiKey=pk_live_o6IoqSubJHv16Hkmxk1ULlcqoHNZ3';
          this.url = prodURL + '&currencyCode='+ this.wallet +'&email='+this.email +'&walletAddress=' + this.walletKey + '&externalCustomerId=' + this.bitshareuserService.username;
            console.log('this.url ================', this.url);
           
        document.getElementById("myframe").setAttribute("src", this.url);

      // }, 1000);
        console.log('this.bitshareuserService.username', this.bitshareuserService.username);  
      }
    });
  
  }
  getMoonpayQuote() {
    console.log('Moobpoay')
      this.bitshareService.getMoonpayQuote(this.wallet, '20', 'usd').subscribe(quote => {
        this.moonPayQuote = quote;
        console.log('this.moonPayQuote', this.moonPayQuote)
        this.loadIframe();
      }, error => {
        console.log('error moonpay', error);
      });
  }
  loadIframe() {
    console.log('iframe')
    const selectedAsset = this.wallet ;
    const redirectURL = location.protocol + '//' + location.host + '/buy-success';
    const transactionBuy= {
      buy_coin: selectedAsset,
      wallet_address: '0xde0b295669a9fd93d5f28d9ec85e40f4cb697bae',
      paying_currency: 'usd',
      type: 'BUY',
      transaction_id: null,
      payment_status: null
    };
    console.log('transactionBuy', transactionBuy)

    localStorage.setItem('transactionBuy', JSON.stringify(transactionBuy));
    this.url = 'https://buy.moonpay.io?apiKey=pk_test_hvCod142wP9zgevCjoELcJgb7DHlz6&currencyCode='+selectedAsset+'&walletAddress=0xde0b295669a9fd93d5f28d9ec85e40f4cb697bae&baseCurrencyAmount=20&email='+this.email+'&baseCurrencyCode=usd&redirectURL=' + redirectURL;
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }
}

