import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl, FormGroupDirective, NgForm, FormBuilder, AbstractControl } from '@angular/forms';
import { BitshareService } from '../service/bitshare.service';
import { UserService } from '../service/user.service';
import { Global } from '../global/global';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CountriesService } from '../service/countries.service';
import { DatePipe } from '@angular/common';
import { FileValidator } from '../validator/file-validator';
import { FileValueAccessor } from '../validator/file-value-accessor';

@Component({
  selector: 'app-kyc',
  templateUrl: './kyc.component.html',
  styleUrls: ['./kyc.component.scss'],
  providers: [DatePipe]
})
export class KycComponent implements OnInit {
  public loading = false;
  public kycForm: FormGroup;
  public submitted = false;
  public accessToken: string;
  public stateInfo: any[] = [];
  public countryInfo: any[] = [];
  public cityInfo: any[] = [];
  public address = false;
  public details = true;
  public proof = false;
  public docFrontImg: boolean = false;
  public docBackImg: boolean = false;
  public frontImgselectedFile = null;
  public backImgselectedFile = null;
  public errorMsg: string;
  public successMsg: string;
  public waitingMsg: any;
  public countryName: any;
  public bitshareAccountError: string;
  public kycStatus: any;
  public phoneNumber: any;
  public fname: any;
  public lname: any;
  public docType: any;
  public street: any;
  public zip: any;
  public city: any;
  public countries: any;
  public citizen: any;
  public autofillCountry: any;
  public dob: any;
  public userDocId: string;

  constructor(
    private country: CountriesService,
    private formBuilder: FormBuilder,
    private bitshareService: BitshareService,
    private userService: UserService,
    private global: Global,
    private router: Router,
    public datepipe: DatePipe

  ) {
    this.userService.detectMobileDevices();
   }
  validationMessages = {
    first_name: [
      { type: 'required', message: 'First Name is required.' },
    ],
    last_name: [
      { type: 'required', message: 'Last Name is required.' },
    ],
    date_of_birth: [
      { type: 'required', message: 'Date Of Birth is required.' },
    ],
    phone_number: [
      { type: 'required', message: 'Phone Number is required.' },
    ],
    country: [
      { type: 'required', message: 'Country is required.' },
    ],
    city: [
      { type: 'required', message: 'City is required.' },
    ],
    citizenship: [
      { type: 'required', message: 'City is required.' },
    ],
    zip: [
      { type: 'required', message: 'zipcode is required.' },
    ],
    street: [
      { type: 'required', message: 'Street is required.' },
    ],
    document_type: [
      { type: 'required', message: 'Document Type is required.' },
    ],
    document_front_image: [
      { type: 'required', message: 'Document Front Image is required.' },
    ],
    document_back_image: [
      { type: 'required', message: 'Document Back Image is required.' },
    ],
  };


  ngOnInit() {
    this.kycForm = this.formBuilder.group({
      first_name: new FormControl('', [Validators.required]),
      last_name: new FormControl('', [Validators.required]),
      date_of_birth: new FormControl('', [Validators.required]),
      phone_number: new FormControl('', [Validators.required]),
      citizenship: new FormControl('', [Validators.required]),
      national_id: new FormControl(''),
      ssn: new FormControl(''),
      itin: new FormControl(''),
      country: new FormControl('', [Validators.required]),
      state: new FormControl(''),
      city: new FormControl('', [Validators.required]),
      zip: new FormControl('', [Validators.required]),
      street: new FormControl('', [Validators.required]),
      building_number: new FormControl(''),
      flat_number: new FormControl(''),
      document_type: new FormControl('', [Validators.required]),
      document_front_image: new FormControl('', [FileValidator.validate]),
      document_back_image: new FormControl('', [FileValidator.validate]),
    });
    this.userService.getCurrentUser().then(([user, udid]) => {
      this.userDocId = udid;
      if (!user) {
        this.router.navigate(['/login']);
      }
      if (user.emailVerified === false) {
        this.router.navigate(['/verify-email']);
      }
      if (user.mobileNoVerified === false) {
        this.router.navigate(['/verify-mobile']);
      }
      if (user.accountName === '') {
        this.router.navigate(['/b/create']);
      }
      if (user.kyc) {
        if (user.kyc.status === 'READY_FOR_KYC' || user.kyc.status === 'REJECTED' || user.kyc.status === 'REJECTED_PROCESSED' || user.kyc.status === 'COLLECTING') {
          this.router.navigate(['/kyc']);
        } 
      }
      this.accessToken = this.global.authToken;
      this.phoneNumber = user.mobileNo;
      this.getUserAction(this.userDocId);
      console.log('Console for Safari in Mac fixes');
    });
    this.getCountries();
    this.getCurrentUser();
    setTimeout(() => {
      (document.querySelector('#kycphone input') as HTMLInputElement).readOnly = true;
      (document.querySelector('#kycphone button') as HTMLButtonElement).disabled = true;
    }, 2000);
  }
  get f() {
    return this.kycForm.controls;
  }
  
  getCountries() {
    this.country.allCountries().
      subscribe(
        data2 => {
          this.countryInfo = data2.Countries;
        },
        err => console.log(err),
        () => console.log('complete')
      )
  }
  onChangeCountry(countryValue, value) {
    const val = countryValue.split('/');
    if (value === 'citizenship') {
      this.countryName = val[0];
    }
    this.autofillCountry = this.citizen;
    this.stateInfo = this.countryInfo[val[1]].States;
    this.cityInfo = this.stateInfo[0].Cities;
  }

  onChangeState(stateValue) {
    const val = stateValue.split('/');
    console.log(val);
    this.cityInfo = this.stateInfo[val[1]].Cities;
  }


  verifyPersonal(form) {
    this.details = false;
    this.address = true;
    this.proof = false;
  }
  verifyAdress(form) {
    console.log('adre')
    this.details = false;
    this.address = false;
    this.proof = true;
  }
  backToAddress() {
    this.details = false;
    this.address = true;
    this.proof = false;
  }
  backToPersonalDetails() {
    this.details = true;
    this.address = false;
    this.proof = false;
  }
  onChangeDocType(value) {
    this.docFrontImg = true;
    console.log('value', value);
    if (value == 'passport') {
      this.kycForm.get('document_back_image').clearValidators();
      this.kycForm.get('document_back_image').updateValueAndValidity();
      this.docBackImg = false;
    } else {
      //this.kycForm.get('document_back_image').setValidators([FileValidator.validate]);
      //this.kycForm.get('document_back_image').updateValueAndValidity();
      
      this.kycForm.get('document_back_image').setValue(null);
      this.kycForm.get('document_back_image').setValidators(Validators.nullValidator);
      this.kycForm.get('document_back_image').setValidators(Validators.required);
      this.kycForm.get('document_back_image').setValidators([FileValidator.validate]);
      this.docBackImg = true;
    }
    
  }
  onFrontImgSelected(event) {
    this.frontImgselectedFile = event.target.files[0];
  }
  onBackImgSelected(event) {
    this.backImgselectedFile = event.target.files[0];
  }

  verifyKyc() {
    this.loading = true;
    this.waitingMsg = 'Verification is in progress, please wait. This might take up to 60 seconds.';
    const citizenship = this.kycForm.value.citizenship.split('/');
    const country = this.kycForm.value.country.split('/');
    const state = this.kycForm.value.state.split('/');
    const date = this.datepipe.transform(this.kycForm.value.date_of_birth, 'yyyy-MM-dd');
    this.submitted = true;
    const formData = new FormData();
    formData.append('first_name', this.kycForm.value.first_name);
    formData.append('last_name', this.kycForm.value.last_name);
    formData.append('date_of_birth', date);
    formData.append('phone_number', this.kycForm.value.phone_number);
    formData.append('citizenship', citizenship[0]);
    formData.append('national_id', this.kycForm.value.national_id);
    formData.append('ssn', this.kycForm.value.ssn);
    formData.append('itin', this.kycForm.value.itin);
    formData.append('country', country[0]);
    formData.append('state', state[0]);
    formData.append('city', this.kycForm.value.city);
    formData.append('zip', this.kycForm.value.zip);
    formData.append('street', this.kycForm.value.street);
    formData.append('building_number', this.kycForm.value.building_number);
    formData.append('flat_number', this.kycForm.value.flat_number);
    formData.append('document_type', this.kycForm.value.document_type);
    formData.append('document_front_image', this.frontImgselectedFile);
    formData.append('document_back_image', this.backImgselectedFile);
    console.log('form', formData);
    this.bitshareService.verifyKyc(this.accessToken, formData).subscribe((res: any) => {
      console.log('resssss', res);
      this.successMsg = res.message;
      if (res.message) {
        this.kycStatus = 'Thanks for verifying your identity. It can take up to 24 hours to verify, but in most cases, it will be done within minutes. We will send you an email once your identity is verified.';
      }
      this.bitshareAccountError = res.status;
      this.loading = false;
      this.waitingMsg = '';
      // setTimeout(() =>{
      this.getCurrentUser();
      // },8000);
      console.log('success', this.successMsg);
    }, err => {
      this.loading = false;
      console.log(err);
      if(err.error.message === 'KYC already passed') {
        this.kycStatus = 'Thanks for verifying your identity. It can take up to 24 hours to verify, but in most cases, it will be done within minutes. We will send you an email once your identity is verified.';
      }
      // this.errorMsg = err.error.message;
      this.waitingMsg = '';
      console.log('err.error.message', err.error.message);
    });
  }
  getCurrentUser() {
    this.userService.getCurrentUser().then(user => {
      console.log('userData', user)
      if (user) {
        if (user[0].kyc.status === 'PENDING') {
          this.kycStatus = 'Thanks for verifying your identity. It can take up to 24 hours to verify, but in most cases, it will be done within minutes. We will send you an email once your identity is verified.';
        } else if (user[0].kyc.status === 'PASSED') {
          this.router.navigate(['/dashboard']);
          this.kycStatus = 'Your KYC data has been verified';
        }
      }
    });
  }
  getUserAction(uid) {
    const checkdata = setInterval(() => {
      this.userService.doCheckUSer(uid).then(userAction => {
        if (userAction.kyc.status === 'PASSED') {
          this.router.navigate(['/dashboard']);
        }
      });
    }, 3000);
  }
  
  findInvalidControls() {
    const invalid = [];
    const controls = this.kycForm.controls;
    for (const name in controls) {
        if (controls[name].invalid) {
            invalid.push(name);
        }
    }
    console.log('invalid fields', invalid);
    return invalid;
  }
}
