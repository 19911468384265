import { Component, OnInit } from '@angular/core';
import { BitshareService } from '../service/bitshare.service';
import { FormGroup, FormControl, NgForm, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '../service/user.service';
import { Apis } from 'bitsharesjs-ws';
var { Login, ChainStore, FetchChain } = require("bitsharesjs");

export interface currency {
  value: string;
  img: string;
}
export interface usercurrency {
  value: string;
  img: string;
}
@Component({
  selector: 'app-exchange',
  templateUrl: './exchange.component.html',
  styleUrls: ['./exchange.component.scss']
})
export class ExchangeComponent implements OnInit {
  public conversionForm: FormGroup;
  public GATEWAY_WALLETS: string;
  public moonPayPrice = [];
  public fromCurrency: any;
  public toCurrency: any;
  public choosedFromCurrency: any;
  public BASE_ASSET: string;
  public currencyLowercase;
  public user: any;
  public userDocId: any;
  public conversionAmount: any;
  public fromAmount: any
  public remainingCurrency: any;
  public secondCoinPair: any;
  public firstCoinPair: any;
  public fiat: any;
  public crypto: any;
  public currency: any;
  currencies: currency[] = [
    { value: 'BTC', img: '../../assets/images/currency_logo/btccoin.png' },
    { value: 'ETH', img: '../../assets/images/currency_logo/ethcoin.png' },
    { value: 'LTC', img: '../../assets/images/currency_logo/ltccoin.png' }
  ];
  usercurrencies: usercurrency[] = [
    { value: 'USD', img: '../../assets/images/currency_logo/usdcoin.png' },
    { value: 'EUR', img: '../../assets/images/currency_logo/eurcoin.png' }
  ];
  
  constructor(
    private bitshareService: BitshareService,
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UserService,
  ) {
   }

   ngOnInit(): void {
    this.conversionForm = this.formBuilder.group({
      selectFromCurrency: new FormControl(),
      selectToCurrency: new FormControl(),
      fromText: new FormControl(),
      toText: new FormControl(),
    });
    this.GATEWAY_WALLETS = JSON.parse(localStorage.getItem('clientConfig')).GATEWAY_WALLETS;
    this.fromCurrency = this.GATEWAY_WALLETS[0];
    this.userService.getCurrentUser().then(([user, udid]) => {
      if (!user) {
        this.router.navigate(['/login']);
      }
      this.user = user;
      this.userDocId = udid;
      this.currency = user.currency;
      this.currencyLowercase = (user.currency).toLowerCase();
      this.toCurrency = user.currency;
    });
    // let server = "wss://api.61bts.com ";
    // Apis.instance(server, true).init_promise.then((res) => { 
    //     console.log('server conn res', res);
    //     ChainStore.init(false).then(() => {
    //       let firstCoinpair: Array<any> = [];
    //       firstCoinpair.push("BTC");
    //       firstCoinpair.push("USD");
    //       Apis.instance().db_api().exec( "lookup_asset_symbols", [firstCoinpair]).then((assetsInfoBSFirst:any) => {
    //           console.log('assetsInfoBS', assetsInfoBSFirst);
    //       }, err => {
    //         console.log('err', err);
    //       });
    //       let secondCoinpair: Array<any> = [];
    //       secondCoinpair.push("ETH");
    //       secondCoinpair.push("USD");
    //       Apis.instance().db_api().exec( "lookup_asset_symbols", [secondCoinpair]).then((assetsInfoBSSecond:any) => {
    //           console.log('assetsInfo', assetsInfoBSSecond);
    //       }, err => {
    //         console.log('err', err);
    //       });
    //     });
    // });
  }
  getMoonPayPrice() {
    this.crypto = (this.fromCurrency ).toLowerCase();
    this.fiat = (this.toCurrency).toLowerCase();
    if(this.fiat === 'usd' || this.fiat === 'eur') {
      this.bitshareService.ConversionRateFromMoonPay(this.crypto, this.fiat).subscribe(res => {
        for (var key in res) {
          if (res.hasOwnProperty(key)) {
            this.moonPayPrice[this.BASE_ASSET +"."+key] = [];
            this.moonPayPrice[this.BASE_ASSET +"."+key][this.toCurrency] = res[key][this.toCurrency]
            this.conversionAmount = this.fromAmount * this.moonPayPrice[this.BASE_ASSET +"."+key][this.toCurrency];
          }
        }
      });
    } else {
    console.log('else')
    this.convertCrypto();
    }
  }
  convertCrypto() {
    this.bitshareService.ConversionRateFromMoonPay(this.crypto, this.currencyLowercase).subscribe(res => {
      for (var key in res) {
        if (res.hasOwnProperty(key)) {
          this.moonPayPrice[this.BASE_ASSET +"."+key] = [];
          this.moonPayPrice[this.BASE_ASSET +"."+key][ this.currency] = res[key][ this.currency]
          this.firstCoinPair = this.moonPayPrice[this.BASE_ASSET +"."+key][ this.currency];
        }
      }
    });
    this.bitshareService.ConversionRateFromMoonPay(this.fiat, this.currencyLowercase).subscribe(res => {
      for (var key in res) {
        if (res.hasOwnProperty(key)) {
          this.moonPayPrice[this.BASE_ASSET +"."+key] = [];
          this.moonPayPrice[this.BASE_ASSET +"."+key][this.currency] = res[key][this.currency]
          this.secondCoinPair = this.moonPayPrice[this.BASE_ASSET +"."+key][this.currency];
        }
      }
    });
    setTimeout(() => {
      this.conversionAmount = (this.firstCoinPair / this.secondCoinPair) * this.fromAmount ;
      console.log('this.conversionAmount', this.conversionAmount);
    }, 2000);
  }
  returnToDashboard() {
    this.router.navigate(['/dashboard']);
  }
  onCurrencyChange(newObj) {
    if (newObj) {
      var val = JSON.parse(localStorage.getItem('clientConfig')).GATEWAY_WALLETS;
      var remainingCurrency = val.filter(function(val) {
        return val !== newObj;
      });
      this.usercurrencies =[];
      for (var currency of remainingCurrency ) {
        this.usercurrencies.push({ value: currency, img: '../../assets/images/currency_logo/'+ currency +'.png' });
      }
      this.usercurrencies.push({ value: 'USD', img: '../../assets/images/currency_logo/usdcoin.png' }),
      this.usercurrencies.push({ value: 'EUR', img: '../../assets/images/currency_logo/eurcoin.png' });
    }
  }
}