import { Component, OnInit } from '@angular/core';
import { Global } from '../global/global';
import { BitshareService } from '../service/bitshare.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mobile-app',
  templateUrl: './mobile-app.component.html',
  styleUrls: ['./mobile-app.component.scss']
})
export class MobileAppComponent implements OnInit {
public playStoreURL;
public appStoreURL;
public BASEURL;
  constructor(
    private global: Global,
    private bitshareService: BitshareService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.global.detectDevice = false;
    console.log('detectDevice',this.global.detectDevice);
    const BASEURL_PROP = this.router;
    this.BASEURL = BASEURL_PROP['location']['_platformLocation']['location']['origin'];
    this.bitshareService.getClientConfig().subscribe(configData => {
      this.appStoreURL =  configData["APPSTORE_URL_IOS "];
      this.playStoreURL = configData['APPSTORE_URL_ANDROID'];
      console.log('sfsdfsdf', this.appStoreURL)
    }, err => {
      console.log('err', err);
    });
  }
  redirectToSite() {
    this.global.redirectTo = true;
    this.router.navigate(['/login']);
  }
}
