import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/storage';
import { BitshareService } from '../service/bitshare.service';
import { BitshareuserService } from '../service/bitshareuser.service';
import { UserService } from '../service/user.service';
import { FormGroup, Validators, FormControl, FormGroupDirective, NgForm, FormBuilder, AbstractControl } from '@angular/forms';
import { NotificationService } from '../service/notification.service';
import { AuthService } from '../service/auth.service';

export interface DialogData {
  newPassword: string;
  confirmPassword: string;
  currentPassword: string;
}
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {
  ref: AngularFireStorageReference;
  task: AngularFireUploadTask;
  public errorMsg: string;
  public newPassword: string;
  public confirmPassword: string;
  public currentPassword: string;
  public changePasswordForm: FormGroup;
  hide = true;
  public loading = false;

  constructor(
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public bitshareService: BitshareService,
    public bitshareuserService: BitshareuserService,
    private userService: UserService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private notifyService: NotificationService
  ) { 
    this.createChangePasswordForm();
    this.newPassword = data.newPassword;
    this.confirmPassword = data.confirmPassword;
    this.currentPassword = data.currentPassword;

  }
  validationMessages = { 
    currentPassword: [
      { type: 'required', message: 'Current password is required.' },
      { type: 'invalid', message: 'Current password is invalid.' },
    ],
    newPassword: [
      { type: 'required', message: 'New password is required.' },
      { type: 'minlength', message: ' Minimum 6 character.' },
    ],
    confirmPassword: [
      { type: 'required', message: 'Confirm password is required.' },
      { type: 'passwordMismatch', message: 'Passwords do not match.' },
    ],
  }
  get fp() { return this.changePasswordForm.controls; }
  createChangePasswordForm() {
    this.changePasswordForm = new FormGroup({
      currentPassword: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      newPassword: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(6),
      ])),
      confirmPassword: new FormControl('', Validators.compose([
        Validators.required,
      ])),
    });
  }
  onPasswordChange() {
    if (this.changePasswordForm.controls['confirmPassword'].value === this.changePasswordForm.controls['newPassword'].value) {
      this.changePasswordForm.controls['confirmPassword'].setErrors(null);
    } else {
      this.changePasswordForm.controls['confirmPassword'].setErrors({ passwordMismatch: true });
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  updatePassword(data) {
    if (this.changePasswordForm.invalid) {
      return;
    }
    this.loading = true;
    this.userService.getCurrentUser().then(([user, udid]) => {
      const currentUserCred = {
        email: user.email,
        password: data.currentPassword,
        newPassword: data.newPassword,
      };
      this.authService.doLogin(currentUserCred).then(res => {
        if (res.user.uid !== undefined) {
          this.authService.updatePassword(currentUserCred.newPassword).then(result => {
            this.loading = false;
            this.showToaster();
            this.onNoClick();
          }, err => {
            this.loading = false;
            if (err.code === 'auth/requires-recent-login') {
              this.errorMsg = 'Recent login is so long please Re-Login to change your password';
              this.loading = false;
            } else {
              this.errorMsg = 'Something went wrong, please try again';
              this.loading = false;
            }
          });
        }
      }, error => {
        if (error.code === 'auth/wrong-password') {
          this.loading = false;

          this.changePasswordForm.controls['currentPassword'].setErrors({ invalid: true });
        } else {
          this.errorMsg = 'Something went wrong, please try again';
          this.loading = false;

        }

      });
    });
  }
  showToaster() {
    this.notifyService.showSuccess("Password updated successfully");
  }

}
