import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { switchMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { BitshareService } from '../service/bitshare.service';
import { Global } from '../global/global';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public userDoc: AngularFirestoreDocument;
  public uploadPercent: Observable<number>;
  public downloadURL: Observable<string>;
  public httpOptions: any;
  constructor(
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    private global: Global,
    public storage: AngularFireStorage,
    public bitshareService: BitshareService,
    private http: HttpClient,
    private router: Router,
    
  ) { }

  getCurrentUser() {
    return new Promise<any>((resolve, reject) => {
      let loggedUser = null;
      const users = this.afAuth.authState.pipe(
        switchMap(user => {
          if (user) {
            loggedUser = user;
            return this.afs.doc(`Users/${user.uid}`).valueChanges();
          } else {
            return of(null);
          }
        })
      );
      if (users) {
        users.subscribe(user => {
          if (user) {
            //user.uid = loggedUser.uid;
            const userDocId = loggedUser.uid;
            firebase.auth().currentUser.getIdToken().then(authToken => {
              this.global.authToken = authToken;
              // this.bitshareService.getSupportedAsset(authToken).subscribe((supportedAssets: any) => {
                // this.global.supportedAssets = supportedAssets;
                // this.global.supportedAssets = supportedAssets['assets_supported'];
                if (user.emailVerified && user.mobileNoVerified) {
                  this.global.loggedIn = true;
                  this.global.loggedInUser = user;
                } else {
                  this.global.loggedInUser = user;
                }
                resolve([user, userDocId]);
              // });
            });
          } else {
            resolve(false); // new user so create necessary record
          }
        }, err => reject(err));
      } else {
        reject('No user logged in');
      }
    });
  }

  addUserDetails(uid, user) {
    return new Promise<any>((resolve, reject) => {
      const userRef: AngularFirestoreDocument<any> = this.afs.doc(`Users/${uid}`);
      resolve(userRef.set(user, { merge: true }));
    });
  }
  doCheckUSer(uid) {
    return new Promise<any>((resolve, reject) => {
      firebase.firestore().collection('Users').doc(uid).onSnapshot( (snapshot) =>{
        resolve(snapshot.data());
      }, err => reject(err));
    });
  }
  updateUserDetails(user, udid) {
    console.log('user updating', user);
    console.log('udid', udid);
    return new Promise<any>((resolve, reject) => {
      resolve(this.afs.doc(`Users/${udid}`).update(user));
    });
  }

  uploadUserProfilePic(event, fileName, uid) {
    return new Promise<any>((resolve, reject) => {
      const file = event;
      const filePath = `users/${uid}/${fileName}`;
      const task = this.storage.upload(filePath, file);
      this.uploadPercent = task.percentageChanges();
      if (task) {
        resolve(this.uploadPercent);
      } else {
        reject('Error in uploding image');
      }
    });
  }

  getUserProfilePic(fileName, uid) {
    return new Promise<any>((resolve, reject) => {
      const fileRef = this.storage.ref(`users/${uid}/${fileName}`);
      fileRef.getDownloadURL().subscribe(response => {
        this.downloadURL = response;
        resolve(this.downloadURL);
      }, err => reject('File not found'));
    });
  }

  getUserByEmail(email) {
    const user = this.afs.collection('Users', ref => ref.where('email', '==', email)).snapshotChanges().pipe(
      map(changes => changes.map(a => {
        const data = a.payload.doc.data() as any;
        data.uid = a.payload.doc.id;
        return data;
      }))
    );
    return user;
  }
  getUserByName(firstName) {
    const user = this.afs.collection('Users', ref => ref.where('firstName', '==', firstName)).snapshotChanges().pipe(
      map(changes => changes.map(a => {
        const data = a.payload.doc.data() as any;
        data.uid = a.payload.doc.id;
        return data;
      }))
    );
    return user;
  }
  
  subscribeToMailPoet(emailAddress) {
    return this.http.get('https://blockbasis.com/wp-json/add-subscriber?email=' + emailAddress, {});
  }
  getUserAction() {
    return this.afs.collection('Users').snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        data.uid = id;
        return data;
      }))
    );
  }
  detectMobileDevices() {
    const BASEURL_PROP = this.router;
    let BASEURL = BASEURL_PROP['location']['_platformLocation']['location']['origin'];
    console.log('this.global.detectDevice', this.global.detectDevice)
    if (this.global.detectDevice === true) {
      (function(a,b){
        if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0,4)))
        (window as any).location=b })
      (navigator.userAgent || navigator.vendor || (window as any).opera, BASEURL +'/mobile-app');
      console.log('if', this.global.detectDevice)
    } else {
      console.log('else', this.global.detectDevice)
    }
  }
}
