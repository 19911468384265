import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, Validators, FormControl } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ngCopy } from 'angular-6-clipboard';
import * as firebase from 'firebase/app';
import { NotificationService } from '../service/notification.service';
import { BitshareService } from '../service/bitshare.service';
import { UserService } from '../service/user.service';
import { Global } from '../global/global';
import { ReplaceOpenPipe } from '../pipe/replace-open.pipe';
import { ReplaceBlockPipe } from '../pipe/replace-block.pipe'
import { BitshareuserService } from '../service/bitshareuser.service';
import { Apis } from 'bitsharesjs-ws';
import { UnlockPopup } from '../unlock-popup/unlock-popup';
var { Login, ChainStore, FetchChain, TransactionBuilder, TransactionHelper, Aes, key, PrivateKey, ops } = require("bitsharesjs");
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActionPopup } from '../action-popup/action-popup';

export interface celsiusAddress {
  coinName: string,
  coinAddress: string,
}
export interface DialogData {
  password: string;
}

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent implements OnInit {
  public loading = false;
  public show = true;
  public currentOrientation = 'vertical';
  public withdrawForm: FormGroup;
  public walletBalance: Array<any> = [];
  public userBalance: any;
  public walletHistory: any = [];
  public transactions: any = [];
  public allTransactions: any = [];
  public nextTransactions: any = [];
  public user: any;
  public email: any;
  public userDocId: string;
  public modalReference: any;
  public currentWallet: any;
  public testnetURL: any;

  public walletAddress: string;
  public depositError = false;
  public loader = false;
  public depositErrorMsg: string;
  public textCopyStatus = false;
  public networkFee: number;
  public networkFeeParam: any;
  public serviceFee: any;
  public withdrawAmount: number;
  public destinationAddress: string;
  public celsiusWalletAddress: string;
  public withdrawFeeStatus = true;
  public withdrawStatus = true;
  public errorMsg: string;
  public successMsg: string;
  public blockbasisConfig = JSON.parse(localStorage.getItem('blockbasisConfig'));
  public iconsOfAsset: any = [];
  public isGetAllAssetIcons: string;
  public memoDisplay = false;
  public tag = false;
  public orderWallet = [];
  public orderedWalletBalance: any;
  public marketPrices: any = [];
  public privKeyBS: string;
  public privKeyMemo: string;
  public BSBalance: any;
  public bitshareFees: any;
  public choosedCurrencyAsset: string;
  public firstThreeAssetBalance: any;
  public activeWallet: string;
  public tpCode: string;
  public transaction_id: string;
  public BSTransHistory: any = [];
  public allBSAssets: any = [];
  public bitshareAssetInfo: Array<any> = [];
  public bitshareAssetData: Array<any> = [];
  public getAssetsInfoFlg: boolean = false;
  public kycMessageVerify: boolean = false;
  public password: string;
  public newDestinationAddress: any;
  public newTag: any;
  public memoID: any;
  public Xrptag: any;
  public memo: any;
  public estimatedFee: Array<any> = [];
  public BASE_ASSET:string;
  public resetConn: any;
  public showButtons: boolean = false;
  
  //Save first document in snapshot of items received
  firstInResponse: any = [];

  //Save last document in snapshot of items received
  lastInResponse: any = [];

  //Keep the array of first document of previous pages
  prev_strt_at: any = [];

  //Maintain the count of clicks on Next Prev button
  pagination_clicked_count = 0;

  //Disable next and prev buttons
  disable_next: boolean = true;
  disable_prev: boolean = true;
  display_limit: number = 25;
  display_start: number = 0;
  display_end: number = 25;
  depositCurrent: boolean = false;
  accountToggle: boolean = true;
  tryAgain: boolean = false;

  constructor(
    private bitshareService: BitshareService,
    private userService: UserService,
    private global: Global,
    private ngxService: NgxUiLoaderService,
    private router: Router,
    private modalService: NgbModal,
    public bitshareuserService: BitshareuserService,
    public dialog: MatDialog,
    private notifyService: NotificationService
  ) {
    this.userService.detectMobileDevices();
   }
 
  validationMessages = {
    withdrawAmount: [
      { type: 'required', message: 'Amount is required.' },
      { type: 'balanceValidator', message: 'Low balance in your account.' },
      { type: 'addressValidator', message: 'Please enter the address above.' },
    ],
    networkFee: [
      { type: 'required', message: 'Fee is required.' },
      { type: 'validating', message: 'Calculating fee.' },
    ],
    address: [
      { type: 'required', message: 'Address is required.' },
    ],
    tag: [
      { type: 'required', message: 'Destination Tag is required.' },
    ],
    memo: [
      { type: 'required', message: 'Memo is required for EOS withdraw.' },
    ]
  };

  zeroBalanceOrder = [
    'BTC',
    'ETH',
    'XRP',
    'LTC',
    'EOS',
    'DASH',
    'ZEC',
    'OMG',
    'EUR',
    'USD',
    'CNY',
    'BTS'
  ];
  allCelsiusAddress: celsiusAddress[] = [
    { coinName: 'BTC', coinAddress: '3HdJo1GyYt9AAjLykAmHERovV9es5rxedF' },
    { coinName: 'ETH', coinAddress: '0x33E5718252137292de12CB6e175ae36192598c24' },
    { coinName: 'XRP', coinAddress: 'rNJ4rRvx8AL7KBRCAxJjhqfUGZMtL5eDpV' },
    { coinName: 'LTC', coinAddress: 'MSabkkABm4kPHRvnwGeeoUWrCEsXai32nz' },
    { coinName: 'ZEC', coinAddress: 't3UrGiufU3p4Kg9ybBDPAUchKhoWmuaqs68' },
    { coinName: 'OMG', coinAddress: '0x33E5718252137292de12CB6e175ae36192598c24' },
    { coinName: 'DASH', coinAddress: '7aNnnuKNQ8qDh6HWSQxEJA3f5kHCRXKda1' },
    { coinName: 'USDT', coinAddress: '0x33E5718252137292de12CB6e175ae36192598c24' },
    { coinName: 'EOS', coinAddress: 'devcspo5z5mi' }
  ];

  get f() { return this.withdrawForm.controls; }

  ngOnInit() {
    this.userService.getCurrentUser().then(([user, udid]) => {
      console.log('account user', udid)
      this.email = user.email;
      if (!user) {
        this.router.navigate(['/login']);
      }
      if (user) {
        if (user.accountName === '') {
          this.router.navigate(['/b/create']);
        }
        //  else if (user.kyc.status === 'READY_FOR_KYC' || user.kyc.status === 'REJECTED' || user.kyc.status === 'REJECTED_PROCESSED' || user.kyc.status === 'COLLECTING') {
        //   this.kycMessageVerify = true;
        // }
      }
    });
    if (Apis.instance().chain_id) {
      this.initPage();
    } else {     
      this.bitshareService.bsConnect().then((connRes:any) => {
        console.log('connRes', connRes);
        this.initPage()
      });
    }
    this.bitshareService.getClientConfig().subscribe(configData => {
      this.testnetURL = 'https://' + configData['PRO_HOSTNAME'];
    }, err => {
      console.log('err', err);
    });
  }
  
  initPage() {
    const ROU = this.router

    if (ROU['location']['_platformLocation']['hostname'] === 'brilliantr-blockbasis-api-dev.firebaseapp.com' || ROU['location']['_platformLocation']['hostname'] === 'localhost' || ROU['location']['_platformLocation']['hostname'] === 'app.testnet.blockbasis.com') { //localhost
      //hide toggles
      this.accountToggle = false;
    }
    this.isGetAllAssetIcons = 'false';
    this.createWithdrawForm();
    //this.ngxService.start();
    if (localStorage.getItem('BASE_ASSET')) {
      this.BASE_ASSET = localStorage.getItem('BASE_ASSET');
    } else {
      this.bitshareService.getBaseAsset().then(BASE_ASSET => {
        localStorage.setItem('BASE_ASSET', BASE_ASSET);
        this.BASE_ASSET = BASE_ASSET;
      })
    }
    console.log('this.BASE_ASSET', this.BASE_ASSET);
    
    this.userService.getCurrentUser().then(([user, udid]) => {
      this.user = user;
      this.userDocId = udid;
      // this.getAllAssetIcons();
      this.getMarketPrice();
      if (this.user.accountName && this.user.accountName !== '' && this.user.accountName !== null) {
        this.getBitshareAssetsFees();
        this.getBSUserBalance(this.user.accountName).then(bitshareBalance => {
        console.log('bitshareBalance', bitshareBalance)
          if (bitshareBalance.message) {
            this.getWalletBalance();

          } else {
            this.userBalance = bitshareBalance;
            this.walletBalance = bitshareBalance;
            this.getBSREcentHistory();
            this.getWalletBalance();
          }
          this.getFeeDetails();
        });

      }
    }, error => {
      //this.ngxService.stop();
    });
    if (!this.bitshareuserService.lockedFlag) {
      // this.showAccountToasterLock();
      }
  }
  // showAccountToasterLock() {
  //   this.notifyService.showSuccess("Account is locked");
  // }
  setIntervalWSSConn() {
    this.resetConn = setInterval(() => {
      this.bitshareService.doCheckWSSConnection(); 
    }, 10000);
  }
  // buy(wallet) {
  //   const coinName = new ReplaceBlockPipe().transform(wallet.symbol);
  //   console.log('wallet', coinName);
  //   const url = 'https://buy.moonpay.io/?apiKey=pk_test_hvCod142wP9zgevCjoELcJgb7DHlz6&email='+ this.email +'&currencyCode=' + coinName;
  //   window.open(url, '_blank');
  // }
  buy() {
    // const url = 'https://buy.moonpay.io/';
    // window.open(url, '_blank');
    console.log('buytest')
    var self = this;
    self.router.navigate(['/buy-sell']);
  }
  ngOnDestroy() {
    this.removeIntervalWSSConn();
  }
  
  removeIntervalWSSConn() {
    if (this.resetConn) {
      clearInterval(this.resetConn);
    }
  }
  
  getFeeDetails() {
    this.bitshareService.getEstimateFee().then((estimatedFee) => {
      this.estimatedFee = estimatedFee;
      this.setIntervalWSSConn();
      console.log(this.estimatedFee, 'this.estimatedFee')
    });
  }
  showToasterSuccess() {
    this.notifyService.showSuccess("Address copied to clipboard");
    // setTimeout(() => {
    //   document.getElementById("toast-container").className = "toast-bottom-center toast-container";
    // }, 2000);
  }
  // getAllAssetIcons() {
  //   const that = this;
  //   const storageRef = firebase.storage().ref().child('assets');
  //   storageRef.listAll().then(function (assetStorages) {
  //     const iconLoadedLen = assetStorages.items.length;
  //     assetStorages.items.forEach((assetIcon, ind) => {
  //       const iconName = assetIcon.name.replace('.jpg', '').replace('.png', '');
  //       assetIcon.getDownloadURL().then(response => {
  //         that.iconsOfAsset.push({ name: iconName, path: response });
  //         if (ind === (iconLoadedLen - 1)) {
  //           that.isGetAllAssetIcons = 'true';
  //         }
  //       }, err => { console.log('not able to get asset icon path') });
  //     });
  //   }).catch(function (error) {
  //     console.log('not able to get list of assets from storage')
  //   });
  // }

  getMarketPrice() {
    this.bitshareService.getPairMarketPrice().subscribe((marketPrices) => {
      this.marketPrices = marketPrices;
    });
  }

  createWithdrawForm() {
    this.withdrawForm = new FormGroup({
      withdrawAmount: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      networkFee: new FormControl('', Validators.compose([])),
      address: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      tag: new FormControl('', Validators.compose([])),
      memo: new FormControl({value: '', disabled: true})
    });
  }
  trade() {
    this.router.navigate(['/trade']);
  }


  getBSUserBalance(usernameBS) {
    return new Promise<any>((resolve, reject) => {
      this.bitshareService.getBitshareUserbalance(usernameBS).then(userBalance => {
        console.log('userBalance', userBalance);
        resolve(userBalance);
      }, err => {
        reject(err);
      });
    });
  }
  getWalletBalance() {
    this.bitshareService.getGatewayCoins().subscribe((allcoins: any) => {
      console.log('allcoins', allcoins)
      allcoins.forEach((coinSym, ci) => {
        this.allBSAssets.push(this.BASE_ASSET+'.' + coinSym);
        
      });
      console.log('this.walletBalance', this.walletBalance)
      this.activeWallet = allcoins;
      //this.BSBalance = bitshareBalance; //no need
      //get Asset data
        Apis.instance().db_api().exec( "lookup_asset_symbols", [this.allBSAssets]).then((assetsInfoBS:any) => {
            console.log('assetsInfoBS ', assetsInfoBS);
            assetsInfoBS.forEach((asst, assi) => {
                let hasBal = false;
                this.walletBalance.forEach((bsast, bsind) => {
                    if(asst.symbol == bsast.symbol) {
                        hasBal = true;
                    }
                });
                if (hasBal == false) {
                    const bslen = this.walletBalance.length;
                    this.walletBalance[bslen] = assetsInfoBS[assi];
                    this.walletBalance[bslen]['balance'] = 0;
                }
            });
        });
      this.linkHistoryBalance();

    }, error => {
      console.log('error', error);
    });
  }

  linkHistoryBalance() {
    setTimeout(() => {
      if (this.BSTransHistory.length && this.walletBalance.length) {
        this.BSTransHistory.forEach((trans, tid) => {
          this.walletBalance.forEach((winfo, wind) => {
            if (winfo.id) {
              if (trans['Data'][3][0] == 'amount') {
                if (winfo.id == trans['Data'][3][1]['_root']['entries'][1][1]) {
                  const transData = this.BSTransHistory[tid];
                  if (!this.walletBalance[wind]['history']) {
                    this.walletBalance[wind]['history'] = [];
                  }
                  this.walletBalance[wind]['history'][this.walletBalance[wind]['history'].length] = transData;
                }
              }

              if (trans['Data'][2][0] == 'asset_to_issue') {
                //console.log(trans['Id']+') '+winfo.id + '==' + trans['Data'][2][1]['_root']['entries'][1][1]);
                if (winfo.id == trans['Data'][2][1]['_root']['entries'][1][1]) {
                  const transData = this.BSTransHistory[tid];
                  if (!this.walletBalance[wind]['history']) {
                    this.walletBalance[wind]['history'] = [];
                  }
                  this.walletBalance[wind]['history'][this.walletBalance[wind]['history'].length] = transData;
                }
              }
            }
          })

        })
        console.log('walletBalance', this.walletBalance);
        this.bitshareService.getAllBSAssetInfo(this.allBSAssets).then((assetsInfoBS) => {
          this.bitshareAssetInfo = assetsInfoBS.response;
          this.getAssetsInfoFlg = assetsInfoBS.flag;
          this.bitshareAssetData = assetsInfoBS.assocdata;
          console.log('this.bitshareAssetInfo', this.bitshareAssetInfo);
          
          assetsInfoBS.assocdata.forEach((asInfo, asInde) => {
            const coinSym = asInfo.symbol;
            let conExtinBal: boolean = false;
            if (this.userBalance) {
                this.userBalance.forEach((bsaInfo, bsi) => {
                  if(bsaInfo.symbol == coinSym) {
                    conExtinBal = true;
                  }
                });
            }
            
            if (conExtinBal === false) {
              const l = this.walletBalance.length;
              this.walletBalance[l] = asInfo; 
              this.walletBalance[l]['balance'] = 0;
            }   
          });
                
        });
      }
    }, 2500);

  }
  readMoreTransactions() {

    if (this.display_start <= this.allTransactions.length) {
      this.nextTransactions.length = 0
      this.allTransactions.forEach((item, ind) => {
        if (ind >= this.display_start && ind < this.display_end) {
          this.transactions.push(item);
          this.nextTransactions.push(item);
        }
      });
      this.display_start = this.display_end;
      if (this.display_end > this.allTransactions.length) {
        this.disable_next = false;
      }
      this.display_end = this.display_start + this.display_limit;

      this.walletHistory.forEach((whit, whin) => {
        this.nextTransactions.forEach((ntit, ntin) => {
          if (whit.symbol == ntit.amount.symbol) {
            this.walletHistory[whin].history.push(ntit);
          }
        });

      });

    } else {
      this.disable_next = false;
    }

  }
  //Add document
  push_prev_startAt(prev_first_doc) {
    this.prev_strt_at.push(prev_first_doc);
  }
  openWithdrawPopup(wallet, withdrawPopup) {
    //console.log('wallet', wallet);
    this.show = true;
    if (this.user.accountName && this.user.accountName !== '' && this.user.accountName !== null) {
 
          
          this.withdrawAmount = null;
          this.currentWallet = wallet;
          this.memoDisplay = false;
          this.tag = false;
          if (wallet.symbol === (this.BASE_ASSET+'.EOS')) {
            this.memoDisplay = true;
            this.withdrawForm.controls.memo.enable();
            this.withdrawForm.controls.memo.validator;
          }
          if (wallet.symbol === (this.BASE_ASSET+'.XRP')) {
            this.tag =true;
          }
          if (this.tag === true) {
            this.withdrawForm.controls['tag'].setErrors({ required: true });
          }
    
          //console.log('this.walletBalance', this.walletBalance)
          /*
          this.walletBalance.forEach((item, ind) => {
            if (item.symbol == wallet.symbol || (this.BASE_ASSET+'.' + wallet.symbol == item.symbol)) {
              //this.currentWallet.amount = this.bitshareService.BSNumberFormatPrecision(item);
              this.currentWallet.amount = item.balance;
              console.log('this.currentWallet', this.currentWallet);
            }
          });
      */
          this.errorMsg = '';
          this.successMsg = '';
          this.withdrawForm.reset();
          this.modalReference = this.modalService.open(withdrawPopup, { size: 'lg' });
    } else {
      setTimeout(() => {
        this.router.navigate(['b/create']);
      }, 3000);
    }

  }

  buyMaximumCurrency() {
    if (this.destinationAddress) {
        if (this.currentWallet.balance > 0) {
            this.withdrawAmount = this.currentWallet.balance
            if (this.networkFee) {
                this.withdrawAmount -= this.networkFee;
            }
        } else {
          this.withdrawAmount = 0;
        }
    } else {
        this.withdrawForm.controls['withdrawAmount'].setErrors({ required: true });
        this.withdrawForm.controls['withdrawAmount'].setErrors({ addressValidator: true });
    }
  }
  openDepositPopup(wallet, depositPopup) {
    this.loader = true;
    this.depositError = false;
    this.textCopyStatus = false;
    this.walletAddress = '';
    this.currentWallet = wallet;
    this.depositCurrent = false;
    this.celsiusWalletAddress = '';
    const coinName = new ReplaceBlockPipe().transform(wallet.symbol);
    this.bitshareService.getDepositAddress(this.global.authToken, coinName).subscribe((bsDepositAddress: any) => {
      this.loader = false;
      console.log('bsDepositAddress', bsDepositAddress);
      if (bsDepositAddress.address) {
        this.celsiusWalletAddress = bsDepositAddress.address;
        this.splitAddressTag(this.celsiusWalletAddress);
      } else {
        this.tryAgain = true;
      }
    }, error => {
      this.loader = false;
      console.log('deposit ADDRESS error', error.error);
      if (error.error.message) {
        this.depositError = true;
        this.depositErrorMsg = error.error.message;
      } else {
        this.tryAgain = true;
      }
    });
    if (this.tryAgain == true) {
      this.celsiusWalletAddress = this.getCelsiusWalletAddress(this.currentWallet.symbol);
      this.bitshareService.getDepositKey(this.global.authToken, wallet.symbol).subscribe((address: any) => {
        console.log('address', address);
        this.walletAddress = address.address;
      }, error => {
        console.log('deposit error', error.error);
        this.depositError = true;
        this.depositErrorMsg = error.error.status;
      });
    }
    this.modalReference = this.modalService.open(depositPopup, { size: 'lg' });
  }
  splitAddressTag(celsiusWalletAddress) {
    let addressArray;
    if (celsiusWalletAddress.indexOf("?dt=") !== -1) {
      addressArray = celsiusWalletAddress.split("?dt=");
      this.newDestinationAddress = addressArray[0];
      this.newTag = addressArray[1];
    } else if (celsiusWalletAddress.indexOf("?memoId=") !== -1) {
      addressArray = celsiusWalletAddress.split("?memoId=");
      this.newDestinationAddress = addressArray[0];
      this.newTag = addressArray[1];
    } else {
      this.newDestinationAddress = celsiusWalletAddress;
      this.newTag = '';
    }
  }

  

  closePopup() {
    this.tpCode = '';
    this.errorMsg = '';
    this.successMsg = '';
    this.withdrawForm.reset();
    this.destinationAddress = '';
    this.loading = false;
    this.modalReference.close();
  }

  getBitshareAssetsFees() {
    this.bitshareService.getBitshareFees().subscribe(bsFees => {
      console.log('bsFees', bsFees);
      this.bitshareFees = bsFees;
    });
  }
 

  getCelsiusWalletAddress(symbol) {
    let caddress;
    this.allCelsiusAddress.forEach((coin) => {
      if (coin.coinName === symbol) {
        caddress = coin.coinAddress;
      }
    });
    return (caddress ? caddress : symbol + ' is disabled.');
  }

  feecalcWithdraw() {
    if (this.withdrawAmount > 0 && this.destinationAddress) {
      this.bitshareService.feecalcWithdraw(this.global.authToken, this.withdrawAmount, this.currentWallet.symbol, this.destinationAddress).subscribe((feeResp: any) => {
        this.networkFee = (feeResp.network_fee.amount).toFixed(8);
        this.serviceFee = (feeResp.service_fee.amount).toFixed(8);
        this.withdrawFeeStatus = true;
      }, error => {
        console.log('error', error);
        if (!error.error.transaction_ok) {
          this.withdrawFeeStatus = false;
          if (error.error.msg === 'Balance is < total purchase price') {
            this.errorMsg = 'You don’t have enough on your account to withdraw this amount';
          } else if (error.error.msg === 'Withdraw address validation failed.') {
            this.errorMsg = 'Destination withdraw address validation failed.';
          } else if (error.error.msg.status) {
            this.errorMsg = error.error.msg.status;
          } else {
            const engRes = error.error.msg;
            const filterRes = engRes.split(' must be minimum ');
            if (filterRes.length > 1) {
              const coinCur = filterRes[0].split('Withdrawal of ');
              this.errorMsg = 'Minimum withdrawal is ' + filterRes[1] + coinCur[1];
            } else {
              this.errorMsg = error.error.msg;
            }
          }
        }
      });
    }
  }

  // checkDepositAvailable(asset) {
  //   console.log('blockbasisConfig', this.blockbasisConfig);
  //   if (Object.values(this.blockbasisConfig.assets_depositable).indexOf(asset) > -1) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }
  // checkWithdrawAvailable(asset) {
  //   if (Object.values(this.blockbasisConfig.assets_withdrawable).indexOf(asset) > -1) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  getAssetIcon(coinName) {
    coinName = new ReplaceBlockPipe().transform(coinName);
    coinName = coinName.toLowerCase();
    let iconPath = '../../assets/images/currency_logo/' + coinName + 'coin.png';

    if (this.isGetAllAssetIcons == 'true') {
      let resFound = 'false';
      for (let assets of this.iconsOfAsset) {
        if (assets.name == coinName) {
          iconPath = assets.path;
        }
      }
      return iconPath;
    } else {
      return iconPath;
    }
  }

 


  getBSREcentHistory() {
    this.bitshareService.getRecentHistory(this.user.accountName).then((transHistory: any) => {
      console.log('transHistory', transHistory);
      this.BSTransHistory = transHistory;
    });

  }

  formatAmountwithPrec(amount, assetId) {
    if (assetId) {
      //console.log('assetId', assetId)
      if (this.getAssetsInfoFlg === true) {
        var thisAssProp = this.bitshareAssetInfo[assetId];
        const currBal: string = amount.toString();
        let curBalnFor: any;

        if (assetId != '1.3.0') {
          if (thisAssProp && thisAssProp['precision']) {

            if (currBal.length > thisAssProp.precision) {
              const f: string = currBal.substring(0, (currBal.length - thisAssProp.precision));
              const s: string = currBal.substring((currBal.length - thisAssProp.precision), currBal.length);
              curBalnFor = f + '.' + s;
            } else {
              const diff = thisAssProp.precision - currBal.length;
              let f = '0.';
              for (var i = 1; i <= diff; i++) {
                f = f + '0';
              }
              curBalnFor = f + currBal;
            }
          }
        } else { //5
          const f: string = currBal.substring(0, (currBal.length - 5));
          const s: string = currBal.substring((currBal.length - 5), currBal.length);
          curBalnFor = f + '.' + s;
        }

        return curBalnFor;
      }
    } else {
      return amount;
    }
  }
  
  
  
  withdrawAction(wallet) {
      this.removeIntervalWSSConn();
      this.withdrawAmount = null;
      this.currentWallet = wallet;
      this.memoDisplay = false;
      this.tag = false;
      if (wallet.symbol === (this.BASE_ASSET+'.EOS')) {
        this.memoDisplay = true;
        this.withdrawForm.controls.memo.enable();
        this.withdrawForm.controls.memo.validator;
      }
      if (wallet.symbol === (this.BASE_ASSET+'.XRP')) {
        this.tag =true;
      }
      if (this.tag === true) {
        this.withdrawForm.controls['tag'].setErrors({ required: true });
      }
      const withdrawFormData = { 
          page: {
            heading: 'Withdraw'
          }, 
          formData: {
            allAssets: this.iconsOfAsset, 
            currentWallet: this.currentWallet,
            user: this.user,
            assetInfo: this.bitshareAssetInfo,
          },
          type: 'Withdraw'
      }
      console.log('withdrawFormData', withdrawFormData);
      const dialogRef = this.dialog.open(ActionPopup, {
        height: 'auto',
        width: '700px',
        data: withdrawFormData
      });
      
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed', result);
        this.setIntervalWSSConn();
      });
  }
  
  depositAction(wallet) {
      this.removeIntervalWSSConn();
      this.loader = true;
      this.depositError = false;
      this.textCopyStatus = false;
      this.walletAddress = '';
      this.currentWallet = wallet;
      this.depositCurrent = false;
      this.celsiusWalletAddress = '';
      console.log(wallet, 'wallet');
      const coinName = new ReplaceBlockPipe().transform(wallet.symbol);
      const depositFormData = { 
          page: {
            heading: 'Deposit'
          }, 
          formData: {
            walletAddress: this.walletAddress,
            currentWallet: this.currentWallet,
            allAssets: this.iconsOfAsset,
            coinName: coinName,
          },
          type: 'Deposit'
      }
      console.log('depositFormData', depositFormData);
      const dialogRef = this.dialog.open(ActionPopup, {
        height: 'auto',
        width: '700px',
        data: depositFormData
      });
      
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed', result);
        this.setIntervalWSSConn();
      });
      
      
  }
}
function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
