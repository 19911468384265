import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, Validators, FormControl } from '@angular/forms';

import { NgxUiLoaderService } from 'ngx-ui-loader';

import { AuthService } from '../service/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  public successMessage: string;
  public forgotPasswordForm: FormGroup;
  private EMAILPATTERN = /^(?:\d{8}|[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3})$/;
  constructor(
    private router: Router,
    private ngxService: NgxUiLoaderService,
    private authService: AuthService,
  ) {
    this.createForgotPasswordForm();
  }

  validationMessages = {
    email: [
      { type: 'required', message: 'Email is required.' },
      { type: 'pattern', message: 'Enter valid email.' }
    ],
  };

  get f() { return this.forgotPasswordForm.controls; }

  createForgotPasswordForm() {
    this.forgotPasswordForm = new FormGroup({
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern(this.EMAILPATTERN)
      ]))
    });
  }

  ngOnInit() {
  }

  login() {
    this.router.navigate(['/login']);
  }

  requestNewPassword(form) {
    this.ngxService.start();
    console.log(form.value);
    this.authService.doSentForgotlink(form.value).then(res => {
      this.ngxService.stop();
      this.successMessage = 'Password reset email is send to your email';
      this.forgotPasswordForm.reset();
    });
  }

}
