import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { NgxUiLoaderService } from 'ngx-ui-loader';
import * as firebase from 'firebase/app';

import { BitshareService } from '../service/bitshare.service';
import { UserService } from '../service/user.service';
import { TransferService } from '../service/transfer.service';

import { Global } from '../global/global';
import { BitshareuserService } from '../service/bitshareuser.service';
import { UnlockPopup } from '../unlock-popup/unlock-popup';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActionPopup } from '../action-popup/action-popup';

import { Apis } from 'bitsharesjs-ws';
var { Login, ChainStore, FetchChain, PrivateKey, TransactionHelper, Aes, TransactionBuilder, key, ops } = require("bitsharesjs");
export interface DialogData {
  password: string;
}
@Component({
  selector: 'app-bitshares-transfer',
  templateUrl: './bitshares-transfer.component.html',
  styleUrls: ['./bitshares-transfer.component.scss']
})
export class BitsharesTransferComponent implements OnInit {
  public loading = false;
  public transferForm: FormGroup;
  public walletBalance: any;
  public selectedWallet: any;
  public serviceFee = 0;
  public networkFee: number = 0;
  private feeDetails: any;
  public serviceFeeSymbol: string;
  public networkFeeSymbol: string;
  public transferAmount;
  public addedBlock;
  public currBal;
  public accessToken: string;
  public transferFeeStatus = false;
  public transferErrorMsg: string;
  public user: any;
  public successMsg: string;
  public password: string;
  public transaction_id: string;
  public errorMsg: string;
  public transferStatus: boolean;
  public userDocId: string;
  public usernameBS: string;
  public transferBy: string;
  public transferTo: string;
  public sendToUser: string;
  public usersBSBalance: any;
  public selectedCurrency:any;
  public kycMessageVerify: boolean = false;
  public noBalance:boolean = false;
  public bitshareAssets: any;
  public bitshareFees: any;
  public apiUrl: any;
  public BASE_ASSET:string;
  public totPrec: number = 4;
  public HOSTURL: string;
  public resetConn: any;
  public showTransferConf: boolean = false;
  public bbUsername: string;
  public addPlus: boolean;
  public showFeeandPay: boolean = false;
  
  constructor(
    private bitshareService: BitshareService,
    private userService: UserService,
    private transferService: TransferService,
    private global: Global,
    private ngxService: NgxUiLoaderService,
    public bitshareuserService: BitshareuserService,
    private http: HttpClient,
    private router: Router,
    public dialog: MatDialog,

  ) { 
    this.createTransferForm();
    this.userService.detectMobileDevices();
    
  }

  ngOnInit() {
    this.userService.getCurrentUser().then(([user, udid]) => {
      if (!user) {
        this.router.navigate(['/login']);
      }
      if (user) {
        if (user.accountName === '') {
          this.router.navigate(['/b/create']);
        } else if (user.kyc.status === 'READY_FOR_KYC' || user.kyc.status === 'REJECTED' || user.kyc.status === 'REJECTED_PROCESSED' || user.kyc.status === 'COLLECTING') {
          this.kycMessageVerify = true;
        }
      }
    });
    if (Apis.instance().chain_id) {
      this.initPage();
    } else {     
      this.bitshareService.bsConnect().then((connRes:any) => {
        console.log('connRes', connRes);
        setTimeout(() => {
            this.initPage()
        }, 1500);
      });
    }

  }
  
  initPage() {
      if (localStorage.getItem('BASE_ASSET')) {
          this.BASE_ASSET = localStorage.getItem('BASE_ASSET');
      } else {
          this.bitshareService.getBaseAsset().then(BASE_ASSET => {
            localStorage.setItem('BASE_ASSET', BASE_ASSET);
            this.BASE_ASSET = BASE_ASSET;
            
          })
      }
      console.log('this.BASE_ASSET', this.BASE_ASSET);
      this.userService.getCurrentUser().then(([user, udid]) => {
        this.user = user;
        console.log('this.user', this.user);
        if (user.accountName && user.accountName !== '' && user.accountName !== null) {
      
            this.accessToken = this.global.authToken;
            this.userDocId = udid;
            this.getWalletBalance(this.accessToken, user.currency);
            this.successMsg = localStorage.getItem('bitshareLoginMessage');
            this.usernameBS = this.user.accountName;
            localStorage.setItem('bitshareLoginMessage', '');
            this.transferBy = 'Username';
            this.getBSUserBalance(this.usernameBS);
            this.bitshareService.getGatewayCoins().subscribe(walletBS => {
              this.bitshareAssets = walletBS;
              this.selectedCurrency = this.bitshareAssets[0]; 
              console.log('this.selectedCurrency',this.selectedCurrency);
              this.getBalofCurr(this.selectedCurrency);
            });
            this.bitshareService.getBitshareFees().subscribe(bsFees => {
              this.bitshareFees = bsFees;
            });
        } else {
            this.router.navigate(['/b/create']);
        }
      });
      this.bitshareService.getClientConfig().subscribe(configData => {
          console.log('configData', configData);
          this.apiUrl = 'https://' + configData['API_HOSTNAME'];
          this.bbUsername = configData['GATEWAY_ACCOUNT'];
      }, err => {
          console.log('err', err);
      });
      console.log('this.apiUrl', this.apiUrl);
  }
  setIntervalWSSConn() {
    this.resetConn = setInterval(() => {
      this.bitshareService.doCheckWSSConnection(); 
    }, 10000);
  }
  
  ngOnDestroy() {
    this.removeIntervalWSSConn();
  }
  
  removeIntervalWSSConn() {
    
    if (this.resetConn) {
      clearInterval(this.resetConn);
    }
    
  }
  validationMessages = {
    toUsername: [
      { type: 'required', message: 'Email or Phone number is required.' },
      { type: 'phoneValidator', message: 'The phone number country code is currently not support. Use email instead.' },
    ],
    amount: [
      { type: 'required', message: 'Amount is required.' },
    ],
    currency: [
      { type: 'required', message: 'Currency is required.' },
    ],
  };
  
  get f() { return this.transferForm.controls; }
  
  createTransferForm() {
    this.transferForm = new FormGroup({
      toUsername: new FormControl('', Validators.compose([
        Validators.required
      ])),
      amount: new FormControl('', Validators.compose([
        Validators.required,
        Validators.maxLength(12)
      ])),
      currency: new FormControl('', [Validators.required]),
    });
  }
  
  getWalletBalance(authToken, asset) {
    this.ngxService.start();
    this.bitshareService.getBalance(this.userDocId).subscribe((balance: any) => {
    // this.bitshareService.getAccountBalance(authToken, asset).subscribe((balance: any) => {
      console.log('balance', balance);
      this.walletBalance = balance;
      const walletBalance = [];
      for (const balanc of balance) {
        // if (this.isSymbolSupported(balanc.symbol)) {
        //   walletBalance.push(balanc);
        // }
      }
      this.walletBalance = walletBalance;
      if (balance.length > 0) {
        //this.selectedWallet = balance[0];
      }
      this.ngxService.stop();
    }, error => {
      this.ngxService.stop();
      console.log('error', error);
    });
  }
 
  openDialog() {
    const dialogRef = this.dialog.open(UnlockPopup, {
      height: 'auto',
      width: '700px',
      data: { password: this.password }
    });
  
    dialogRef.afterClosed().subscribe(result => {
    
      console.log('The dialog was closed', result);
      if(result) {
        if(result.success) {
            if (result.token) {
              this.bitshareuserService.token = result.token;
            }
            if(result.memo) {
              this.bitshareuserService.memo = result.memo;
            }
        }
      }
      
      if(this.bitshareuserService.token && this.bitshareuserService.memo) {
        this.calcFee();
      }
    });
  };

  transferCoin(form) {
    console.log ('form', form)
    this.errorMsg = '';
    this.successMsg = '';
    if(this.bitshareuserService.token) {
        this.loading = true;
        const receiverEmail = form.value.email;
        // 1, check the user exist through email
        // 2, yes, do transer, send email
        // 3, no, send an invite email
        let receiverDetails = null;
        this.ngxService.start();
        this.doBSTransferByPKey(form.value);
    }
  }

  doBSTransferByPKey(formValue) {
    this.removeIntervalWSSConn();
    if (this.showTransferConf == true) {
      const transferFormData = { 
        page: {
          heading: 'Confirm Transfer'
        }, 
        formData: {
          user: this.user,
        },
        type: 'Transfer'
      }
      console.log('transferFormData', transferFormData);
      const dialogRef = this.dialog.open(ActionPopup, {
        height: 'auto',
        width: '700px',
        data: transferFormData
      });
      dialogRef.afterClosed().subscribe(result => {

        if (result == 'Success') {
          console.log('dialog confirmation result', result)
          //this.errorMsg = 'Send Currency To username does not exist in BitsharesJS';
          this.transferBy = 'Blockbasis';
          this.transferTo = this.bbUsername;
          this.confirmTransfer(formValue)
        } else {
          console.log('dialog confirmation closed', result)
          this.transferForm.get('toUsername').reset();
          this.errorMsg = 'Please enter Send Currency To username';
          //this.transferStatus = false;
          this.loading = false;
        }
        //this.loading = false;
        //this.setIntervalWSSConn();
      });
    } else {
      this.confirmTransfer(formValue);
    }
    
  }
  
  confirmTransfer(formValue) {
      if (this.bitshareuserService.lockedFlag == true) {
        let tr = new TransactionBuilder();
        let pKey = PrivateKey.fromWif(this.bitshareuserService.token);
        let pKeyMemo = PrivateKey.fromWif(this.bitshareuserService.memo);
        let fromAccount = this.usernameBS;
        let memoSender = fromAccount;
        let memo;
        if (this.transferBy == "Blockbasis") {
            if(this.addPlus === true) {
              memo = "transfer:+" + formValue.toUsername;
            } else {
              memo = "transfer:" + formValue.toUsername;
            }
        } else {
            memo = "";
        }
        console.log('memo', memo);
        let toAccount; //senthil-r wd-dev-test-13 r-senthil mark-dencker
        if (this.transferBy === 'Username') {
          toAccount = formValue.toUsername; //senthil-r wd-dev-test-13 r-senthil mark-dencker
        } else {
          toAccount = this.transferTo;
        }
        let sendAmount = {
          amount: formValue.amount,
          asset: (formValue.currency == 'TEST') ? formValue.currency : this.BASE_ASSET+'.'+formValue.currency
        }

        Promise.all([
          FetchChain("getAccount", fromAccount),
          FetchChain("getAccount", toAccount),
          FetchChain("getAccount", memoSender),
          FetchChain("getAsset", sendAmount.asset),
          FetchChain("getAsset", sendAmount.asset)
        ]).then((res)=> {

          let [fromAccount, toAccount, memoSender, sendAsset, feeAsset] = res;
          console.log('fromAccount', fromAccount);
          console.log('toAccount', toAccount);
          console.log('memoSender', memoSender);
          console.log('sendAsset', sendAsset);
          console.log('feeAsset', feeAsset);
          if(fromAccount) { 
            if (toAccount) {              
              var feeOptions = feeAsset.get("options");
              console.log('feeOptions', feeOptions)
              const marketFee = feeOptions._root.nodes;
              let memoFromKey = memoSender.getIn(["options","memo_key"]);
              let memoToKey = toAccount.getIn(["options","memo_key"]);
              let nonce = TransactionHelper.unique_nonce_uint64();
              let memo_object = {
                from: memoFromKey,
                to: memoToKey,
                nonce,
                message: Aes.encrypt_with_checksum(
                    pKeyMemo,
                    memoToKey,
                    nonce,
                    memo
                )
              }
              const user_transfer_info_dynamic = {
                fee: {
                  amount: 0, //2121093 2136718 (Math.pow(10, sendAsset.get('precision')) * sendAmount.amount) * (marketFee[1]['nodes'][0]['entry'][1]/100)
                  asset_id: feeAsset.get("id") //feeAsset.get("id") '1.3.0'
                },
                from: fromAccount.get("id"),
                  to: toAccount.get("id"),
                  amount: { amount: (Math.pow(10, sendAsset.get('precision')) * sendAmount.amount).toFixed(), asset_id: sendAsset.get("id") },
                  memo: memo_object
              };
              tr.add_type_operation( "transfer", user_transfer_info_dynamic);

              tr.set_required_fees().then(() => {
                  tr.add_signer(pKey, pKey.toPublicKey().toPublicKeyString());
                  console.log("serialized transaction:", tr.serialize());
                  //tr.broadcast();
                  //console.log("broadcast transaction:", tr.broadcast());
                  Promise.all([
                      tr.broadcast()
                  ]).then((trbrRes:any)=> {
                    console.log('trbrRes', trbrRes)
                    const tp = trbrRes[0][0];
                    if(tp.id) {
                      //this.transferFormReset();
                      this.transferStatus = true;
                      this.successMsg = tp.block_num;
                      this.transaction_id = tp.trx_num;
                      this.HOSTURL = this.bitshareService.hostUrl;
                      localStorage.setItem('successMsg', JSON.stringify(this.successMsg));
                      localStorage.setItem('transaction_id', JSON.stringify(this.transaction_id));
                      localStorage.setItem('HOSTURL', JSON.stringify(this.HOSTURL));
                      this.router.navigate(['/transfer-completed']);
                    }
                    this.ngxService.stop();
                    this.loading = false;
                      this.setIntervalWSSConn();
                    }).catch(error => { 
                    console.log('error', error);
                    this.errorMsg = 'Something went wrong, please try again.';
                    this.transferFormReset();
                    this.transferStatus = false;
                    this.ngxService.stop();
                    this.loading = false;
                    this.setIntervalWSSConn();
                  });
              });
            } else {
              
              this.errorMsg = 'Send Currency To username does not exist in BitsharesJS';
              this.transferFormReset();
              this.transferStatus = false;
              this.ngxService.stop();
              this.loading = false;
              this.setIntervalWSSConn();
            }
          } else {
            this.errorMsg = 'Username does not exist in BitsharesJS';
            this.transferFormReset();
            this.transferStatus = false;
            this.ngxService.stop();
            this.loading = false;
            this.setIntervalWSSConn();
          }
        });
        
    }
  }

  
  transferFormReset() {
    //this.transferForm.reset();
    //this.transferForm.controls['toUsername'].reset()
    this.networkFee = 0;
    this.selectedWallet = '';
    this.usersBSBalance = '';
    this.transferForm.get('toUsername').reset();
    this.transferForm.get('amount').reset();
    this.selectedCurrency = this.bitshareAssets[0];
    this.usernameBS = this.user.accountName;
   this.getBSUserBalance(this.user.accountName);
    setTimeout(() => {
        this.getBalofCurr(this.selectedCurrency);
        console.log('transfer all done');
    }, 3000);
    
    
  }
  
  getUsernameFromContact(userInfo) {
    this.errorMsg = '';
    let contactInfo = userInfo;
    this.transferBy = 'Username';
    this.transferTo = '';
    if (contactInfo) {
      if (!isNaN(contactInfo)) {
        
        if(contactInfo.slice(0,1) != '+') {
          contactInfo = '+' + contactInfo;
          this.addPlus = true;
        } else {
          this.addPlus = false;
        }
        if(contactInfo.slice(0,2) == '+1' || contactInfo.slice(0,2) == '+7') {
          this.transferForm.controls['toUsername'].setErrors({ phoneValidator: true });
        } 
        this.transferBy = 'Phone';
        
      } 
      if (contactInfo.indexOf('@') !== -1) {
        contactInfo = contactInfo;
        this.transferBy = 'Email';
        this.addPlus = false;
      }
      if(this.transferBy !== 'Username') {
        const APIurl = this.apiUrl + '/tasks/lookup/' +contactInfo;
        console.log('APIurl', APIurl);
        const headers = new HttpHeaders({Authorization: 'Bearer ' + this.accessToken });
        //responseType: 'text', 
        this.http.get(APIurl, {headers}).subscribe((createResponse:any) => {
          if(createResponse.accountName != null) {
            this.transferTo = createResponse.accountName;
            this.checkUserExistinBS();
      } else {
        this.transferTo = this.bbUsername;
            this.showTransferConf = true;
      }
        });
      } else {
        this.transferTo = contactInfo;
        this.checkUserExistinBS();
      }
    }
  }
  
  checkUserExistinBS() {
      //check the username exist in bitshare
      this.removeIntervalWSSConn();
      this.transferTo = this.transferTo.replace(/\s/g, "");
      console.log('this.transferTo', this.transferTo);
      if(this.transferTo) {
        this.bitshareService.getUsername(this.transferTo).then((toUserInfo) => {
          console.log('toUserInfo', toUserInfo);
          if(toUserInfo.response) { //user not exist in bitshare
            console.log('toUserInfo', toUserInfo);
            this.showTransferConf = true;
            this.transferTo = this.bbUsername;
          } else {
        this.showTransferConf = false;
      }    
          this.setIntervalWSSConn();
        });
      }
  }
  
  getBSUserBalance(usernameBS) {
    return new Promise<any>((resolve, reject) => {
      this.bitshareService.getBitshareUserbalance(usernameBS).then(userBalance => {
        console.log('userBalance', userBalance);
        this.usersBSBalance = userBalance;
        resolve(userBalance);
      }, err => {
          reject(err);
      });
    });
  }
  
  getBalofCurr(curr) {
    this.noBalance = false;
    this.transferStatus = false;
    this.showFeeandPay = false;
    
    if (curr && this.usersBSBalance) {
      if (this.usersBSBalance.length > 0) {
        this.selectedWallet = '';
        this.usersBSBalance.forEach((curInfo, ind) => {
          if(curInfo.symbol === curr || (curInfo.symbol === this.BASE_ASSET+"."+curr)) {
            // precision balance
            this.currBal = curInfo.balance;
            this.selectedWallet = curInfo;
            console.log('this.selectedWallet', this.selectedWallet);
            this.calcFee();
          }
        })
        if (this.selectedWallet == '') {
          this.errorMsg = 'No balance amount in the currency '+curr;
          this.noBalance = true;
        }
      } else {
        this.errorMsg = 'No balance amount in the currency '+curr;
        this.noBalance = true;
      }
    }
  }
  
  calcFee() {
    console.log('calcFee')
    this.removeIntervalWSSConn();
    this.transferAmount = parseFloat(this.transferAmount);
    if(typeof this.transferAmount === "number" && this.transferAmount && this.transferAmount > 0 && this.transferTo && this.bitshareuserService.token && this.selectedWallet) {
        console.log('this.transferTo', this.transferTo);
        console.log('this.user.accountName', this.user.accountName);
        console.log('this.bitshareuserService.token', this.bitshareuserService.token);
        this.loading = true;
        let tr = new TransactionBuilder();
        
        let pKey = PrivateKey.fromWif(this.bitshareuserService.token);
        let pKeyMemo = PrivateKey.fromWif(this.bitshareuserService.memo);
        
        let fromAccount = this.user.accountName;
        let memoSender = fromAccount;
        //const memo_symbol = this.selectedWallet.symbol.toLowerCase().split(".");  // Split block.xxx into "xxx"
        //let memo =  "Blockbasis Transfer"; //memo_symbol[1] + ":" + this.destinationAddress
        let memo;
        if (this.transferBy == "Blockbasis") {
            memo = "transfer:" + this.sendToUser;
        } else {
            memo = "";
        }
        console.log('this.transferTo', this.transferTo)
        let toAccount = this.transferTo;
        let sendAmount = {
          amount: this.transferAmount,
          asset: this.selectedWallet.symbol
        }

        Promise.all([
          FetchChain("getAccount", fromAccount),
          FetchChain("getAccount", toAccount),
          FetchChain("getAccount", memoSender),
          FetchChain("getAsset", sendAmount.asset),
          FetchChain("getAsset", sendAmount.asset)
        ]).then((res)=> {
            let [fromAccount, toAccount, memoSender, sendAsset, feeAsset] = res;
            console.log('______', sendAsset.get("precision"));
            this.totPrec = sendAsset.get("precision");
            if(toAccount) {
                let memoFromKey = memoSender.getIn(["options","memo_key"]);
                let memoToKey = toAccount.getIn(["options","memo_key"]);
                let nonce = TransactionHelper.unique_nonce_uint64();
                let memo_object = {
                    from: memoFromKey,
                    to: memoToKey,
                    nonce,
                    message: Aes.encrypt_with_checksum(
                        pKeyMemo,
                        memoToKey,
                        nonce,
                        memo
                    )
                }
                
                let serialized = ops.memo_data.fromObject(memo_object);
                const stringified = JSON.stringify(
                    ops.memo_data.toHex(serialized)
                );
                console.log('stringified', stringified)
                const byteLength = Buffer.byteLength(stringified, "hex");
                console.log('=======byteLength===========', byteLength)
                
                const user_transfer_info_dynamic = {
                    fee: {
                      amount: 0,//this.bitshareFees.transfer.fee 2136718
                      asset_id: feeAsset.get("id") // '1.3.0'
                    },
                    from: fromAccount.get("id"),
                    to: toAccount.get("id"),
                    amount: { amount: Math.pow(10, sendAsset.get('precision')) * sendAmount.amount, asset_id: sendAsset.get("id") },
                    memo: memo_object
                };
                console.log('user_transfer_info_dynamic', user_transfer_info_dynamic);
                tr.add_type_operation( "transfer", user_transfer_info_dynamic);

                tr.set_required_fees().then(() => {
                  tr.add_signer(pKey, pKey.toPublicKey().toPublicKeyString());
                  console.log("serialized transaction:", tr.serialize());
                  const calculatedFee = tr.serialize();
                  //console.log(calculatedFee['operations'][0][1]['fee'])
                  this.networkFee = this.formatAmountwithPrec(calculatedFee['operations'][0][1]['fee']['amount'],  sendAsset.get('precision'));
                  //this.networkFee = calculatedFee['operations'][0][1]['fee']['amount'];
                  this.loading = false;
                  this.showFeeandPay = true;
                  this.valBalance();
                  this.setIntervalWSSConn();
                });
            } else {
              this.errorMsg = 'Send Currency To username does not exist in BitsharesJS';
              this.transferForm.get('toUsername').reset();
              this.transferStatus = false;
              this.ngxService.stop();
              this.loading = false;
              this.setIntervalWSSConn();
            }
        });
    } else {
      this.noBalance = true;
    }
    console.log('endCALC')
    
  }
 
  formatAmountwithPrec(amount, precision){
    if (amount) {
        const amtInStr:string = amount.toString();
        let curBalnFor:any;
        if (amtInStr.length > precision) {
            const f:string = amtInStr.substring(0, (amtInStr.length - precision));
            const s:string = amtInStr.substring((amtInStr.length - precision), amtInStr.length);
            curBalnFor = f+'.'+s;
        } else {
            const diff = precision - amtInStr.length;
            let f = '0.';
            for (var i=1; i<=diff; i++) {
                f = f+'0';
            }
            curBalnFor = f+amtInStr;
        } 
        return curBalnFor;
    } else {
      return amount;
    }
  }
  sendMaximumCurrency(value) {
    /*
    this.noBalance = false;
    this.addedBlock = this.BASE_ASSET+"."+ value;
    if (this.usersBSBalance) {
      if (this.usersBSBalance.length > 0) {
        this.usersBSBalance.forEach((curInfo, ind) => {
            if (curInfo.symbol === this.addedBlock) {
              this.transferAmount = curInfo.balance;
              if (this.networkFee) {
                console.log('this.networkFee', this.networkFee);
                if(Number(this.networkFee) > Number(this.transferAmount)) {
                  this.noBalance = true;
                  this.transferAmount = 0;
                } else {
                  this.transferAmount = this.formatAmountwithPrec((this.transferAmount - this.networkFee), this.selectedWallet.precision);
                }
              }
            }
        })
      } else {
        this.transferAmount = 0;
      }
    }
    */
    this.noBalance = false;
    if(this.selectedWallet.balance) {
        this.transferAmount = this.selectedWallet.balance;
        if (this.networkFee) {
            if(Number(this.networkFee) > Number(this.transferAmount)) {
                this.noBalance = true;
                this.transferAmount = 0;
            } else {
                this.transferAmount = (this.transferAmount - this.networkFee);
            }
        }
    } else {
        this.transferAmount = 0;
    }
    
  }
  valBalance() {
    this.noBalance = false;
    this.errorMsg ='';
    if(this.transferAmount == 0) {
      this.errorMsg = "Low balance in your Account";
      this.noBalance = true;
    }
    if(this.transferAmount > 0 && this.transferAmount > (this.currBal - this.networkFee)) {
      this.noBalance = true;
      //this.sendMaximumCurrency(this.selectedCurrency);
      this.transferAmount = this.currBal - this.networkFee;
    }
    if(!this.currBal) {
      this.noBalance = true;
    } 
  }
}
