import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { FirebaseOptionsToken } from '@angular/fire';
import { environment } from './environments/environment';

import * as firebase from 'firebase';
import 'firebase/performance';
import { AppModule } from './app/app.module';

enableProdMode();  

/*
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
*/
function loadConfig() {
  return fetch('/__/firebase/init.json')
    .then(response => response.json());
}

(async () => {
  const config = await loadConfig();
  // console.log('config', config);
  // if (config.projectId === 'brilliantr-blockbasis-prod') {
  //   config.authDomain = environment.webHostName;
  // }
  if (config.projectId === 'brilliantr-blockbasis-api-dev') {
    config.authDomain = environment.webHostName;
  }
  platformBrowserDynamic([{ provide: FirebaseOptionsToken, useValue: config }])
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
})();
