import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appMatDialogClose]'
})
export class MatDialogCloseDirective {

  constructor(el: ElementRef) {
      el.nativeElement.style.fontSize = '14px';
      //el.nativeElement.style.cssFloat = 'right';
      el.nativeElement.style.textAlign = "right";
      el.nativeElement.style.width = "100%";
      el.nativeElement.style.fontWeight = '700';
      el.nativeElement.style.lineHeight = '20px';
      el.nativeElement.style.color = '#000';
      el.nativeElement.style.textShadow = "0 1px 0 #fff";
      el.nativeElement.style.opacity = "0.5";
      el.nativeElement.style.cursor = "pointer";
      let thisEle = el.nativeElement;
      thisEle.addEventListener("mouseover", function( event ) {
          event.target.style.opacity = "0.75";
      });
      thisEle.addEventListener("mouseout", function( event ) {
          event.target.style.opacity = "0.5";
      });
  }

}
