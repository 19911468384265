import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FormGroup, Validators, FormControl, FormGroupDirective, NgForm, FormBuilder, AbstractControl } from '@angular/forms';
import * as firebase from 'firebase/app';

import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AngularFireAuth } from '@angular/fire/auth';

import { AuthService } from '../service/auth.service';
import { UserService } from '../service/user.service';


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  public signUpForm: FormGroup;
  public isMailPoet = true;
  public loading = false;
  private EMAILPATTERN = /^(?:\d{8}|[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3})$/;
  public successMessage: string;
  public errorMessage: string;
  public popupErrorMessage;
  @ViewChild('linkMultipleAccount', {static: true}) linkMultipleAccount: ElementRef<HTMLElement>;
  private supportedPopupSignInMethods = [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    firebase.auth.GithubAuthProvider.PROVIDER_ID,
    // firebase.auth.OAuthProvider,
  ];
  public linkedProvider: any;
  public credentials: any;
  constructor(
    public afAuth: AngularFireAuth,
    private router: Router,
    private route: ActivatedRoute,
    private ngxService: NgxUiLoaderService,
    private authService: AuthService,
    private userService: UserService,
  ) {
    this.userService.detectMobileDevices();
    this.createSignUpForm();
    this.route.queryParams.subscribe(params => {
      console.log(params);
      this.signUpForm.controls['email'].setValue(params.email);
    });
  }

  validationMessages = {
    email: [
      { type: 'required', message: 'Email is required.' },
      { type: 'pattern', message: 'Enter valid email.' }
    ],
    // mobileNo: [
    //   { type: 'required', message: 'Mobile number is required.' }
    // ],
    password: [
      { type: 'required', message: 'Password is required.' },
      { type: 'minlength', message: ' Minimum 6 character.' },
      { type: 'maxlength', message: 'Maximum 12 character.' },
    ],
  };

  get f() { return this.signUpForm.controls; }

  createSignUpForm() {
    this.signUpForm = new FormGroup({
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern(this.EMAILPATTERN)
      ])),
      password: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(6),
      ])),
      subscribeToList: new FormControl(true, []),
    });
  }
  
  // onPasswordChange() {
  //   if (this.confirmPassword.value == this.password.value) {
  //       this.confirmPassword.setErrors(null);
  //   } else {
  //       this.confirmPassword.setErrors({ appEmailDomainValidation: true });
  //   }
  // }

  // getting the form control elements
  get password(): AbstractControl {
      return this.signUpForm.controls['password'];
  }

  // get confirmPassword(): AbstractControl {
  //     return this.signUpForm.controls['confirmPassword'];
  // }

  ngOnInit() {
  }

  login() {
    this.router.navigate(['/login']);
  }

  registerWithEmail(form) {
    if (this.signUpForm.invalid) {
      return;
    }
    this.loading = true;
    // this.ngxService.start();
    if(form.value.subscribeToList === true) { // checked
      this.userService.subscribeToMailPoet(form.value.email).subscribe(res => {
        console.log(res);
      }, err => {
        console.log(err.message);
      });
    }
    this.errorMessage = '';
    this.authService.doRegister(form.value).then(res => {
      const uid = res.user.uid;
      const additionalUserInfo = {email: form.value.email, emailVerified: false, firstName: '', lastName: '', photoURL: '', mobileNo: ''};
      this.checkUserAfterLogin('email', uid, additionalUserInfo);
    }, err => {
      this.errorMessage = err.message;
      console.log(err.message);
      // this.ngxService.stop();
      this.loading = false;
    });
  }

  signupWithFacebook() {
    this.authService.doFacebookLogin().then(res => {
      const uid = res.user.uid;
      const additionalUserInfo = res.additionalUserInfo.profile;
      additionalUserInfo.emailVerified = true;
      this.checkUserAfterLogin('facebook', uid, additionalUserInfo);
    }, err => {
      if (err.code === 'auth/account-exists-with-different-credential') {
        this.linkAccount(err.credential, err.email);
      }
    });
  }

  signupWithGoogle() {
    this.loading = true;
    this.authService.doGoogleLogin().then(res => {
      const uid = res.user.uid;
      const additionalUserInfo = res.additionalUserInfo.profile;
      additionalUserInfo.emailVerified = true;
      this.checkUserAfterLogin('google', uid, additionalUserInfo);
    }, err => {
      this.loading = false;
      console.log('err', err);
      this.errorMessage = 'Something went wrong, please try again';
    });
  }
  signupWithApple() {
    this.loading = true;
    this.authService.doAppleLogin().then(res => {
      const uid = res.user.uid;
      const additionalUserInfo = res.additionalUserInfo.profile;
      additionalUserInfo.emailVerified = true;
      this.checkUserAfterLogin('apple', uid, additionalUserInfo);
    }, err => {
      this.loading = false;
      console.log('err', err);
      this.errorMessage = 'Something went wrong, please try again';
    });
  }
  signupWithTwitter() {
    this.loading = true;
    this.authService.doTwitterLogin().then(res => {
      const uid = res.user.uid;
      const additionalUserInfo = res.additionalUserInfo.profile;
      additionalUserInfo.emailVerified = true;
      this.checkUserAfterLogin('twitter', uid, additionalUserInfo);
    }, err => {
      this.loading = false;
      console.log('err', err);
      this.errorMessage = 'Something went wrong, please try again';
    });
  }
  signInWithPopup(linkedProvider, credentials) {
    firebase.auth().signInWithPopup(linkedProvider).then(result => {
      result.user.linkWithCredential(credentials);
      this.userService.getCurrentUser().then(([user, udid]) => {
        console.log('user', user);
        this.router.navigate(['/verify-mobile']);
      }, error => {
        console.log('error', error);
      });
    }, error => {
      console.log('signInWithPopup', error);
      if(error.code === 'auth/popup-blocked') {
        this.popupErrorMessage = 'Please click continue button to proceed further';
      } else {
      this.errorMessage = 'Something went wrong, please try again';
    }
    });
  }
  checkUserAfterLogin(provider, uid, additionalUserInfo) {
    this.userService.getCurrentUser().then(user => {
      if (user) {
        console.log('user.mobileNo', user[0].mobileNo);
        console.log('user', user);
        // add email address to mailPoet
        this.userService.subscribeToMailPoet(user[0].email).subscribe(res => {
            console.log('res ', res, user[0].email);
        }, err => {
            console.log('Mailpoet email subscribe', err.message);
        });
        if (user[0].mobileNo === null && user[0].mobileNoVerified === false) {
          console.log('mobile not verified');
          this.router.navigate(['/verify-mobile']);
        } else if (user.emailVerified === false) {
          console.log('email not verified');
          this.router.navigate(['/verify-email']);
        } else {
          console.log('email and mobile verified');
          if (user.accountName === null || user.accountName === '') {
            console.log('bit share account not created, create it and add it to firebase DB');
            this.router.navigate(['/b/create']);
          } else {
            this.router.navigate(['/dashboard']);
          }
        }
      } else {
        if (provider === 'google') {
          this.addUserData(uid, additionalUserInfo.email, additionalUserInfo.emailVerified, additionalUserInfo.given_name, additionalUserInfo.family_name, additionalUserInfo.picture, null, provider);
        } else if (provider === 'facebook') {
          this.addUserData(uid, additionalUserInfo.email, additionalUserInfo.emailVerified, additionalUserInfo.first_name, additionalUserInfo.last_name, additionalUserInfo.picture.data.url, null, provider);
        } else if (provider === 'email') {
          this.addUserData(uid, additionalUserInfo.email, additionalUserInfo.emailVerified, additionalUserInfo.firstName, additionalUserInfo.lastName, additionalUserInfo.photoURL, additionalUserInfo.mobileNo, provider);
        } else if (provider === 'apple') {
          this.addUserData(uid, additionalUserInfo.email, additionalUserInfo.emailVerified, null, null, additionalUserInfo.photoURL, null, provider);
        } else if (provider === 'twitter') {
          this.addUserData(uid,  additionalUserInfo.email, additionalUserInfo.emailVerified, additionalUserInfo.name, null, additionalUserInfo.profile_image_url, null, provider);
        }
      }
    });
  }

  addUserData(uid, email, emailVerified, firstName, lastName, photoURL, mobileNo, provider) {
    // add email address to mailPoet
    this.userService.subscribeToMailPoet(email).subscribe(res => {
        console.log(res);
    }, err => {
        console.log('Mailpoet email subscribe', err.message);
    });
        
    const user = {accountName: '', email, emailVerified, firstName, lastName, mobileNo, mobileNoVerified: false, currency: 'USD', language: 'en', photoURL, roles: {admin: false}};
    this.userService.addUserDetails(uid, user).then(newUser => {
      this.userService.getCurrentUser();
      if (provider === 'email') {
        this.router.navigate(['/verify-email']);
      } else {
        this.router.navigate(['/verify-mobile']);
      }
    }, error => {
      console.log('Adding user to collection error', error);
    });
  }

  linkAccount(credentials, email) {
    this.credentials = credentials;
    firebase.auth().fetchSignInMethodsForEmail(email).then(providers => {
      const firstPopupProviderMethod = providers.find(p => this.supportedPopupSignInMethods.includes(p));
      // Test: Could this happen with email link then trying social provider?
      if (!firstPopupProviderMethod) {
        throw new Error(`Your account is linked to a provider that isn't supported.`);
      }
      const linkedProvider = this.getProvider(firstPopupProviderMethod);
      this.linkedProvider = linkedProvider;
      const el: HTMLElement = this.linkMultipleAccount.nativeElement;
      linkedProvider.setCustomParameters({ login_hint: email });
      setTimeout(() => {
        el.click();
      }, 100);
    });
  }

  getProvider(providerId) {
    switch (providerId) {
      case firebase.auth.GoogleAuthProvider.PROVIDER_ID:
        return new firebase.auth.GoogleAuthProvider();
      case firebase.auth.FacebookAuthProvider.PROVIDER_ID:
        return new firebase.auth.FacebookAuthProvider();
      case firebase.auth.GithubAuthProvider.PROVIDER_ID:
        return new firebase.auth.GithubAuthProvider();
      default:
        throw new Error(`No provider implemented for ${providerId}`);
    }
  }

  // sendVerificationEmail() {
  //   console.log('sending verification');
  //   this.authService.doSentVerificationEmail().then(responce => {
  //     this.signUpForm.reset();
  //     this.ngxService.stop();
  //     this.successMessage = 'Your Blockbasis account has been created and a verification email has been sent. Please verify your email.';
  //     // this.successMessage += ' Please verify your email to continue Blockbasis.';
  //   }, err => {
  //     this.ngxService.stop();
  //     console.log(err.message);
  //   });
  // }
}
