import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'amountFormat'
})
export class AmountFormatPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    //return null;
    if(value <= 0) return 0;
    var precision = args[0];
    const currBal:string = value.toString();
    let curBalnFor:any;
    if (currBal.length > precision) {
        const f:string = currBal.substring(0, (currBal.length - precision));
        const s:string = currBal.substring((currBal.length - precision), currBal.length);
        curBalnFor = f+'.'+s;
    } else {
        const diff = precision - currBal.length;
        let f = '0.';
        for (var i=1; i<=diff; i++) {
          f = f+'0';
        }
        curBalnFor = f+currBal;
    }
    console.log('Balamce ' + value + '-- ' + precision + 'ret '+ curBalnFor);
    return curBalnFor;
    
  }

}
