import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import * as firebase from 'firebase/app';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService , SPINNER} from 'ngx-ui-loader';

import { BitshareService } from '../service/bitshare.service';
import { UserService } from '../service/user.service';

import { Global } from '../global/global';
import { ReplaceOpenPipe } from '../pipe/replace-open.pipe';

import { Apis } from 'bitsharesjs-ws';
var { Login, ChainStore, FetchChain } = require("bitsharesjs");
import { BitshareuserService } from '../service/bitshareuser.service';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { XAxisTickFormatPipe } from '../pipe/x-axis-tick-format.pipe';
import {ReplaceBlockPipe} from '../pipe/replace-block.pipe';
export interface CelsiusAddress {
  coinName: string;
  coinAddress: string;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public walletBalance: any;
  spinnerType = SPINNER.circle;
  public transactions: any;
  public gatewaySymbol: any;
  public moonPayPrice: any;
  public user: any;
  public moonPayRes = [];
  public btcExchangePrice: number;
  public ethExchangePrice: number;
  public ltcExchangePrice: number;
  public xrpExchangePrice: number;
  public btsExchangePrice: number;
  public eosExchangePrice: number;
  public btcExchangePriceEUR: number;
  public ethExchangePriceEUR: number;
  public ltcExchangePriceEUR: number;
  public xrpExchangePriceEUR: number;
  public btsExchangePriceEUR: number;
  public eosExchangePriceEUR: number;
  public availableBalance = 0;
  public iconsOfAsset: any = [];
  public isGetAllAssetIcons: string;
  public marketPrices: any = [];
  public userDocId: string;
  public userInterest: any;
  public totalInterest: number;
  public celsiusWalletAddress: string;
  public currentWallet: any;
  public modalReference: any;
  public successMsg: string;
  public errorMsg: string;
  public kycBar: any;
  public respbal:any;
  totalBalance: any;
  balance:any;
  //public newAccountName: string = 'wd-dev-test-11';
  //public newAccountPassword: string = 'Wiredelta2020';
  public accountHistory: any = [];
  public BSTransHistory: any = [];
  public bitshareAssetInfo: Array<any> = [];
  public getAssetsInfoFlg: boolean = false;
  public allBSAssets: any = [];
  public BSBalance: any;
  public BSLatetsTrans: any = [];
  public placedOrdersInfo: Array<any> = [];
  public isGetPubOrd: boolean = false;
  public BASE_ASSET: string;
  public GATEWAY_WALLETS: string;
  public GET_BASE_CONFIG: boolean = false;
  public doContinue: boolean = false;
  public resetConn: any;
  public currencyLowercase;
  public email: any;

  //var for map
  public assetChartData: Array<any> = [];
  public chartCoinpair: Array<any> = [];
  public ngx_charts_exchange: boolean = false;
  view: any[] = [];

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = false;
  xAxisLabel = 'Date';
  showYAxisLabel = false;
  yAxisLabel = 'Price Value';
  yAxis: boolean = true;
  xAxis: boolean = true;
  legend: boolean = false;
  showLabels: boolean = true;
  animations: boolean = true;
  timeline = false;
  total = 0;
  public monthFromDate: string;
  public monthToDate: string;
  public weekFromDate: string;
  public weekToDate: string;
  public chartDataGetFor: string = 'Month';
  public xAxisCoord:Array<any> = [];
  public allXaxisTicks:Array<any> = [];
  public currXCoord:Array<any> = [];
  public dayFromDate: string;
  public dayToDate: string;
  public yearFromDate: string;
  public yearToDate: string;
  public allFromDate: string;
  public allToDate: string;
  
  //graphData:Array<any> = [];
  lineChartData:Array<any> = [];
  lineChartTwoData:Array<any> = [];
  colorScheme = {
    domain: ['#7f2090', '#E44D25', '#e799f1', '#36033c', '#a8385d', '#e3aaf5']
  };
  monthNames: Array<any> = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  @ViewChild('balanceTabGroup') balanceTabGroup: MatTabGroup;
  public chartTabIndex = 0;
  public chartLoader: boolean = false;
  chartLinks = ['1D', '1W', '1M', '1Y', 'All'];
  chartActiveLink = this.chartLinks[2];
  
  constructor(
    private bitshareService: BitshareService,
    private userService: UserService,
    private global: Global,
    private ngxService: NgxUiLoaderService,
    private router: Router,
    private modalService: NgbModal,
    public bitshareuserService: BitshareuserService,
  ) { 
    this.userService.detectMobileDevices();
  }
  // myWebSocket: WebSocketSubject = webSocket('ws://localhost:8000');
  // myWebSocket.asObservable().subscribe((dataFromServer:any) => 
  // console.log('data'); 
  // );
  allCelsiusAddress: CelsiusAddress[] = [
    { coinName: 'BTS', coinAddress: '3HdJo1GyYt9AAjLykAmHERovV9es5rxedF' },
    { coinName: 'BTC', coinAddress: '3HdJo1GyYt9AAjLykAmHERovV9es5rxedF' },
    { coinName: 'ETH', coinAddress: '0x33E5718252137292de12CB6e175ae36192598c24' },
    { coinName: 'XRP', coinAddress: 'rNJ4rRvx8AL7KBRCAxJjhqfUGZMtL5eDpV' },
    { coinName: 'LTC', coinAddress: 'MSabkkABm4kPHRvnwGeeoUWrCEsXai32nz' },
    { coinName: 'ZEC', coinAddress: 't3UrGiufU3p4Kg9ybBDPAUchKhoWmuaqs68' },
    { coinName: 'OMG', coinAddress: '0x33E5718252137292de12CB6e175ae36192598c24' },
    { coinName: 'DASH', coinAddress: '7aNnnuKNQ8qDh6HWSQxEJA3f5kHCRXKda1' },
    { coinName: 'USDT', coinAddress: '0x33E5718252137292de12CB6e175ae36192598c24' },
    { coinName: 'EOS', coinAddress: 'devcspo5z5mi' }
  ];

  ngOnInit() {
    this.ngxService.start();
    
    let today = new Date();
    let toDateOnly = ("0" + today.getDate()).slice(-2);
    let toMonthOnly = ("0" + (today.getMonth() < 1 ? 1 : today.getMonth())).slice(-2);
    let toYearOnly = today.getFullYear();
    let toDateTime = toYearOnly +'-'+ toMonthOnly + '-' + toDateOnly + 'T' + '00:00:00';
    this.monthFromDate = toDateTime;
    
    let fDate = new Date();
    let monthFromDateOnly = ("0" + fDate.getDate()).slice(-2);
    let monthFromMonthOnly = ("0" + (fDate.getMonth() - 1 + 2)).slice(-2);
    let monthFromYearOnly = fDate.getFullYear();
    let monthFromDateTime = monthFromYearOnly +'-'+ monthFromMonthOnly + '-' + monthFromDateOnly + 'T' + '00:00:00';
    this.monthToDate = monthFromDateTime;
    console.log('default :: ' + this.monthFromDate +'::'+this.monthToDate)
    this.weekToDate = this.monthToDate; 
    let weeknowdate = new Date();
    
    let weekFromDateOnly = ("0" + (weeknowdate.getDate() - 7)).slice(-2);
    let weekFromMonthOnly = ("0" + (weeknowdate.getMonth() - 1 + 2)).slice(-2);
    let weekFromYearOnly = weeknowdate.getFullYear();
    let weekFromDateTime = weekFromYearOnly +'-'+ weekFromMonthOnly + '-' + weekFromDateOnly + 'T' + '00:00:00';
    //this.weekFromDate = toYearOnly +'-'+ (weeknowdate.getMonth() + 1) + '-' + weekFromDateDay + 'T' + '00:00:00';
    this.weekFromDate = weekFromDateTime;
    console.log('week :: '+ this.weekFromDate + ' :: ' + this.weekToDate);
    
    let dayFromDateTime = new Date();
    this.dayToDate = dayFromDateTime.getFullYear() + '-' + (dayFromDateTime.getMonth() + 1) + '-' + dayFromDateTime.getDate() + 'T' + dayFromDateTime.getHours() + ":" + dayFromDateTime.getMinutes() + ":" + dayFromDateTime.getSeconds();
    
    let dayFromDateDay = dayFromDateTime.getDate() - 1;
    this.dayFromDate = dayFromDateTime.getFullYear() + '-' + (dayFromDateTime.getMonth() + 1) + '-' + dayFromDateDay + 'T' + dayFromDateTime.getHours() + ":" + dayFromDateTime.getMinutes() + ":" + dayFromDateTime.getSeconds();
    
    console.log('day :: ' + this.dayFromDate + '::' + this.dayToDate);
    
    let yearNowDate = new Date();
    this.yearFromDate = (yearNowDate.getFullYear() - 1) + '-' + (yearNowDate.getMonth() < 1 ? 1 : yearNowDate.getMonth()) + '-' + yearNowDate.getDate() + 'T00:00:00';
    this.yearToDate = this.monthToDate; 
    console.log('year :: '+ this.yearFromDate + ' :: ' + this.yearToDate);
    
    let allNowDate = new Date();
    this.allFromDate = (allNowDate.getFullYear() - 5) + '-' + allNowDate.getMonth() + '-' + allNowDate.getDate() + 'T00:00:00';
    this.allToDate = this.monthToDate;
    
    if (Apis.instance().chain_id) {
      this.initPage();
    } else {     
      this.bitshareService.bsConnect().then((connRes:any) => {
        if(connRes.WSSConnection === true) {
          console.log('WSS Connection failed');
        } else {
          this.initPage();
        }
      });
    }
    var val = JSON.parse(localStorage.getItem('clientConfig')).GATEWAY_WALLETS;
    this.GATEWAY_WALLETS = val.toLocaleString().toLowerCase().split(',');
  }
  
  initPage() {
    //const {object_type, impl_object_type} = ChainTypes;
    this.isGetAllAssetIcons = 'false';
    // this.ngxService.start();
    this.userService.getCurrentUser().then(([user, udid]) => {
      console.log('dashboard get curr user', user);
      this.email = user.email;
      if (!user) {
        this.router.navigate(['/login']);
      }
      console.log('user data', user);
      this.user = user;
      this.userDocId = udid;
      this.currencyLowercase = (user.currency).toLowerCase();
      if (user) {
        if (user.accountName === '') {
          this.router.navigate(['/b/create']);
        } 
        // else if (user.kyc) {
        //   if (user.kyc.status === 'READY_FOR_KYC' || user.kyc.status === 'REJECTED' || user.kyc.status === 'REJECTED_PROCESSED' || user.kyc.status === 'COLLECTING') {
        //     this.router.navigate(['/kyc']);
        //   } else {
        //     this.doContinue = true;
        //   }
        // } 
        else {
          this.doContinue = true;
        }
        console.log('this.doContinue', this.doContinue);
        if (this.doContinue === true) {
          if (localStorage.getItem('BASE_ASSET')) {
            this.BASE_ASSET = localStorage.getItem('BASE_ASSET');
            this.GET_BASE_CONFIG = true;
            setTimeout(() => {
              this.getPageBasics();
            }, 1500);
          } else {
            this.bitshareService.getBaseAsset().then(BASE_ASSET => {
              localStorage.setItem('BASE_ASSET', BASE_ASSET);
              this.BASE_ASSET = BASE_ASSET;
              this.GET_BASE_CONFIG = true;
              this.getPageBasics();
            })
          }
        }

      }
    });

  }
  setIntervalWSSConn() {
    this.resetConn = setInterval(() => {
      this.bitshareService.doCheckWSSConnection(); 
    }, 10000);
  }
  
  ngOnDestroy() {
    this.removeIntervalWSSConn();
  }
  
  removeIntervalWSSConn() {
    if (this.resetConn) {
      clearInterval(this.resetConn);
    }
  }
  getPageBasics() {
    //if (this.GET_BASE_CONFIG == true) {
  //this.gatewayIBTest();
  this.ngxService.start();
    if (this.user.accountName && this.user.accountName !== '' && this.user.accountName !== null) {
      this.getBSBalance(this.user.accountName).then(bitshareBalance => {
        if (!bitshareBalance.message) {
          this.BSBalance = bitshareBalance;
        }
          this.getAllBSAssets();
          this.getMarketPrice();
          this.getMoonPayPrice();
          //this.getBSREcentHistory();
          setTimeout(() => {
            this.loadPageContent();

            // this.getPriceChartData();
            
            // for (var key in this.respbal ) {
            //   if (this.respbal.hasOwnProperty(key)) {
            //     this.moonPayRes[this.BASE_ASSET +"."+key] = [];
            //     this.moonPayRes[this.BASE_ASSET +"."+key][this.user.currency] = this.respbal[key][this.user.currency]
            //     console.log("usercurr",this.respbal[key][this.user.currency]);
            //     let total = 0;
            //     for(let data of this.BSBalance){
            //       console.log('dataval',data);
            //       total += (data.balance * this.moonPayRes[this.BASE_ASSET +"."+key][this.user.currency] );
            //       console.log("bblns",this.moonPayRes[this.BASE_ASSET +"."+key][this.user.currency]);
            //       console.log("databalnz",data.balance);
            //     }  
            //     console.log("total",total);
            //   }
            // }
            // let total = 0;
           
                for(let data of this.BSBalance){
                  // console.log('dataval',data);
                  // total +=data.balance;
                 
                  const removeblock = new ReplaceBlockPipe();
                  const symbol = removeblock.transform(data.symbol);
                  console.log('symbol',symbol);
                  for (var key in this.respbal ) {
                    // console.log("keyval",key);
                    if(symbol==key){
                      if (this.respbal.hasOwnProperty(key)) {
                        this.moonPayRes[this.BASE_ASSET +"."+key] = [];
                        this.moonPayRes[this.BASE_ASSET +"."+key][this.user.currency] = this.respbal[key][this.user.currency]
                        this.total += (data.balance * this.moonPayRes[this.BASE_ASSET +"."+key][this.user.currency] );
                        // console.log("bblns",this.moonPayRes[this.BASE_ASSET +"."+key][this.user.currency]);
                        // console.log("databalnz",data.balance);
                      }
                    }                                          
                   }  

    // return this.formatPrice(total);
            // this.totalBalance = this.totalBalance + parseFloat(this.BSBalance[0].balance);
            // console.log('this.totalBa', this.BSBalance);
           }          
                 console.log("total",this.total);
          }, 2500);
        
      });
    }
  
    this.ngxService.stop();

    //}
  }

  getAllBSAssets() {
    this.bitshareService.getGatewayCoins().subscribe((bsAssets: any) => {
      bsAssets.forEach((bsitm, bsind) => {
        this.allBSAssets.push(this.BASE_ASSET + '.' + bsitm);
      });
      //console.log('this.allBSAssets', this.allBSAssets);
      Apis.instance().db_api().exec( "lookup_asset_symbols", [this.allBSAssets]).then((assetsInfoBS:any) => {
        this.bitshareService.ConversionRateFromMoonPay(this.GATEWAY_WALLETS, this.currencyLowercase).subscribe(res => {
          console.log('resd',res);
          this.respbal =res;
          for (var key in res) {
            if (res.hasOwnProperty(key)) {
              this.moonPayRes[this.BASE_ASSET +"."+key] = [];
              this.moonPayRes[this.BASE_ASSET +"."+key][this.user.currency] = res[key][this.user.currency]
              console.log("usercurr",this.moonPayRes);
            }
          }
          
          console.log("respbal",res);

        })  
        //check bsbalance length 0 and add it
        if(this.BSBalance && this.BSBalance.length) {
          assetsInfoBS.forEach((asst, assi) => {
            let hasBal = false;
            this.bitshareAssetInfo[asst.id] = asst;                  
            this.BSBalance.forEach((bsast, bsind) => {
              if(asst.symbol == bsast.symbol) {
                hasBal = true;
              }
            });
            if (hasBal == false) {
              const bslen = this.BSBalance.length;
              this.BSBalance[bslen] = assetsInfoBS[assi];
              this.BSBalance[bslen]['balance'] = 0
            }
          });
        } else {
          assetsInfoBS.forEach((asst, assi) => {
            this.bitshareAssetInfo[asst.id] = asst;
            assetsInfoBS[assi]['balance'] = 0;
          });
          this.BSBalance = assetsInfoBS;
        }
        this.getAssetsInfoFlg = true;
      });
    });
  }

  getBSREcentHistory() {
    this.bitshareService.getRecentHistory(this.user.accountName).then((transHistory: any) => {
	  setTimeout(()=>{
		  console.log('transHistory -', transHistory)
		  this.BSTransHistory = transHistory;
		  let litmCnt = 0;
		  setTimeout(()=>{
			  let stopLoop = 'NO';
			  this.BSTransHistory.forEach((bslattrs, bslatind) => {
				console.log('bslattrs', bslattrs);
				if ((bslattrs['Data'][1][0] == 'from' || bslattrs['Data'][1][0] == 'issuer') && litmCnt < 10 && stopLoop == 'NO') {
				  this.BSLatetsTrans.push(bslattrs);
				  litmCnt += 1;
				}
				if (litmCnt >= 10 && stopLoop == 'NO') { //(this.BSTransHistory.length) - 1 == bslatind
				  stopLoop = 'YES';
				  //this.setIntervalWSSConn();
				}
				
			  });
	 
		  }, 1000);
      }, 2000);
    })
  }

  doCalcOrderValue() {
    if (this.isGetPubOrd == true) {
      this.BSBalance.forEach((baitm, baind) => {
        var inOrdVal = 0;
        this.placedOrdersInfo.forEach(poitm => {
          var poTrans = poitm.orderInfo.transactions;
          if (poTrans[0]['operations'][0][1]['amount_to_sell']['asset_id'] == baitm.id) {
            inOrdVal = inOrdVal + poTrans[0]['operations'][0][1]['amount_to_sell']['amount'];
          }
        })
        if (inOrdVal != 0) {
          const currBal: string = inOrdVal.toString();
          const f: string = currBal.substring(0, (currBal.length - baitm.precision));
          const s: string = currBal.substring((currBal.length - baitm.precision), currBal.length);

          inOrdVal = parseFloat(f + '.' + s);
        }

        this.BSBalance[baind]['InOrderVal'] = inOrdVal;
      })
    }

  }
  // getAllAssetIcons() {
  //   console.log('getAllAssetIcons--------');
  //   const that = this;
  //   const storageRef = firebase.storage().ref().child('assets');
  //   storageRef.listAll().then(assetStorages => {
  //     const iconLoadedLen = assetStorages.items.length;

  //     assetStorages.items.forEach((assetIcon, ind) => {
  //       const iconName = assetIcon.name.replace('.jpg', '').replace('.png', '');

  //       assetIcon.getDownloadURL().then(response => {
  //         that.iconsOfAsset.push({ name: iconName, path: response });

  //         if (ind === (iconLoadedLen - 1)) {
  //           that.isGetAllAssetIcons = 'true';
  //           that.loadPageContent();
  //         }
  //       }, err => { console.log('not able to get asset icon path'); });

  //     });

  //   }).catch(error => {
  //     console.log('not able to get list of assets from storage');
  //     setTimeout(()=>{
  //          that.loadPageContent();
  //      }, 2500);
  //   });
  // }

  loadPageContent() {
    //console.log('_____________in loadPageContent_________________');
    // this.getHistory(this.global.authToken);
    // this.getExchangeValues(this.user.currency);
    // this.getUserTransactions(this.userDocId, this.user.currency);
    this.getBSREcentHistory();
    // this.moonpay();
	
  }

  getMarketPrice() {
    this.bitshareService.getPairMarketPrice().subscribe((MarketPrices) => {
      // console.log('marketPrices datas', MarketPrices);
      this.marketPrices = MarketPrices;
      this.getExchangeValues(this.user.currency);
    });
  }
  getWalletBalance(authToken, asset) {
    this.bitshareService.getBalance(this.userDocId, this.marketPrices, this.user.currency).subscribe((balances: any) => {
      // this.bitshareService.getAccountBalance(authToken, asset).subscribe((balances: any) => {
      //console.log('balances', balances);
      const walletBalance = [];
      for (const balance of balances) {
        if (balance.symbol) {
          // if (this.isSymbolSupported(balance.symbol)) {
          if (balance.market.marketPrice === -1) {
            this.availableBalance = this.availableBalance + balance.amount;
          } else {
            this.availableBalance = this.availableBalance + (balance.amount * balance.market.marketPrice);
          }

          // Filter interest for particular wallet balance
          const singleInterest = this.userInterest.filter(interest => {
            let interestAsset = Object.keys(interest.balance)[0];
            interestAsset = interestAsset.replace(/open./g, '').replace(/OPEN./g, '').toLowerCase();
            const balanceAsset = balance.symbol.replace(/open./g, '').replace(/OPEN./g, '').toLowerCase();
            if (interestAsset === balanceAsset) {
              return interest;
            }
          });

          if (singleInterest.length > 0) {
            // Sum the interest and append it to balance object
            const walletInterest: number = singleInterest.reduce((sa: number, b) => {
              const interestAmount: any = Object.values(b.balance)[0];
              return sa + interestAmount;
            }, 0);
            const interest = { amount: walletInterest, asPerUserCurrency: (walletInterest * balance.market.marketPrice) };
            balance.interest = interest; // Append intrest to balance object
          }
          walletBalance.push(balance);
          // }
        }
      }
      // Sum all wallet interest amount
      this.totalInterest = walletBalance.reduce((sa: number, b) => sa + b.interest.asPerUserCurrency, 0);
      this.walletBalance = walletBalance;

    }, error => {
      //this.ngxService.stop();
    });
  }

  trade() {
    this.router.navigate(['/trade']);
  }

  transfer() {
    this.router.navigate(['/transfer']);
  }

  buy() {
    // const url = 'https://buy.moonpay.io/';
    // window.open(url, '_blank');
    this.router.navigate(['/buy-sell']);
  }

  getHistory(authToken) {
    this.bitshareService.getHistory(authToken, 6, 'transfer').subscribe(transaction => {
      this.transactions = transaction;
      //console.log('this.transactions', this.transactions);
      //this.ngxService.stop();
    }, error => {
      //this.ngxService.stop();
    });
  }
  //   getGatewayAssetCoins() {
  //     this.bitshareService.getGatewayCoins().subscribe(gatewayCoins => {
  //       this.bitshareService.getMoonpayExchangeRates().subscribe( moonPayRes => {
  //         this.gatewaySymbol = gatewayCoins;
  //         this.gatewaySymbol.forEach(moonPayRes, function(key, value) {
  //         this.moonPayPrice = moonPayRes;
  //         this.moonPayPrice.forEach((moonPaykey, value) => {
  //         });
  //       });
  //     });
  //   });
  // }
  getMoonPayPrice() {
    this.bitshareService.getMoonpayExchangeRates().subscribe(moonPayRes => {
      this.moonPayPrice = moonPayRes;
      //console.log('moonPayRes', moonPayRes);
      this.btcExchangePrice = this.moonPayPrice.BTC.USD;
      this.ethExchangePrice = this.moonPayPrice.ETH.USD;
      this.ltcExchangePrice = this.moonPayPrice.LTC.USD;
      this.xrpExchangePrice = this.moonPayPrice.XRP.USD;
      this.eosExchangePrice = this.moonPayPrice.EOS.USD;
      this.btcExchangePriceEUR = this.moonPayPrice.BTC.EUR;
      this.ethExchangePriceEUR = this.moonPayPrice.ETH.EUR;
      this.ltcExchangePriceEUR = this.moonPayPrice.LTC.EUR;
      this.xrpExchangePriceEUR = this.moonPayPrice.XRP.EUR;
      this.eosExchangePriceEUR = this.moonPayPrice.EOS.EUR;
    });
  }
  getUserTransactions(uid, currency) {
    if (this.user) {
      this.bitshareService.getTransactions(uid, 6, 'transfer').subscribe(transaction => {
        // this.transactions = transaction;
        this.transactions = [];
        for (const item of transaction) {
          this.transactions.push(item.payload.doc.data());
        }
        //this.ngxService.stop();
      }, error => {
        console.log('getUserTransactions error', error);
        //this.ngxService.stop();
      });
    }
  }

  getExchangeValues(currency) {
    // if (this.marketPrices.length > 0) {
    //   this.marketPrices.forEach((marketpair, ind) => {
    //     if (marketpair.pair === 'OPEN.BTC' + ':' + currency) {
    //       this.btcExchangePrice = marketpair.price;
    //     }
    //     if (marketpair.pair === 'OPEN.ETH' + ':' + currency) {
    //       this.ethExchangePrice = marketpair.price;
    //     }
    //     if (marketpair.pair === 'OPEN.LTC' + ':' + currency) {
    //       this.ltcExchangePrice = marketpair.price;
    //     }
    //     if (marketpair.pair === 'OPEN.XRP' + ':' + currency) {
    //       this.xrpExchangePrice = marketpair.price;
    //     }
    //     if (marketpair.pair === 'OPEN.EOS' + ':' + currency) {
    //       this.eosExchangePrice = marketpair.price;
    //     }
    //     if (marketpair.pair === 'BTS' + ':' + currency) {
    //       this.btsExchangePrice = marketpair.price;
    //     }
    //   });
    // }
    /*
    this.bitshareService.ticker('OPEN.BTC' + ':' + currency).subscribe((btcExchange: any) => {
      this.btcExchangePrice = btcExchange.latest.price.toFixed(4);
    });
    this.bitshareService.ticker('OPEN.ETH' + ':' + currency).subscribe((ethExchange: any) => {
      this.ethExchangePrice = ethExchange.latest.price.toFixed(4);
    });
    this.bitshareService.ticker('OPEN.LTC' + ':' + currency).subscribe((ltcExchange: any) => {
      this.ltcExchangePrice = ltcExchange.latest.price.toFixed(4);
    });
    //OPEN.STEEM
    this.bitshareService.ticker('OPEN.XRP' + ':' + currency).subscribe((xrpExchange: any) => {
      this.xrpExchangePrice = xrpExchange.latest.price.toFixed(4);
    });
    this.bitshareService.ticker('OPEN.EOS' + ':' + currency).subscribe((eosExchange: any) => {
      this.eosExchangePrice = eosExchange.latest.price.toFixed(4);
    });
    this.bitshareService.ticker('BTS' + ':' + currency).subscribe((btsExchange: any) => {
      this.btsExchangePrice = btsExchange.latest.price.toFixed(4);
    });
    */
  }

  // isSymbolSupported(walletSymbol) {
  //   let validSymbol = false;
  //   walletSymbol = walletSymbol.replace(/open./g, '').replace(/OPEN./g, '');
  //   this.global.supportedAssets.forEach((item, ind) => {
  //     item = item.replace(/open./g, '').replace(/OPEN./g, '');
  //     if (item === walletSymbol && validSymbol === false) {
  //       validSymbol = true;
  //     }
  //   });
  //   return validSymbol;
  // }

  getAssetIcon(coinName) {
    if (coinName) {
      coinName = new ReplaceOpenPipe().transform(coinName);
      coinName = coinName.toLowerCase();
      let iconPath = '../../assets/images/currency_logo/' + coinName + 'coin.png';
      if (this.isGetAllAssetIcons === 'true') {
        const resFound = 'false';
        for (const assets of this.iconsOfAsset) {
          if (assets.name === coinName) {
            iconPath = assets.path;
          }
        }
        return iconPath;
      } else {
        return iconPath;
      }
    }
  }

  getCelsiusWalletAddress(symbol) {
    let caddress;
    this.allCelsiusAddress.forEach((coin) => {
      //console.log(coin.coinName + '===' + symbol);
      if (coin.coinName === symbol) {
        caddress = coin.coinAddress;
      }
    });
    return caddress;
  }
  closePopup() {
    this.modalReference.close();
  }
  formatBalancewithPrec(assetInfo) {
      if (assetInfo.balance > 0) {
          const amount:string = assetInfo.balance.toString();
          const precision = assetInfo.precision;
          let curBalnFor:any;
          if (amount.length > precision) {
            const f:string = amount.substring(0, (amount.length - precision));
            const s:string = amount.substring((amount.length - precision), amount.length);
            curBalnFor = f+'.'+s;
          } else {
            const diff = precision - amount.length;
            let f = '0.';
            for (var i=1; i<=diff; i++) {
              f = f+'0';
            }
            curBalnFor = f+amount;
          }
          return curBalnFor;
      } else {
          return 0;
      }
  }
  formatAmountwithPrec(amount, assetId) {
    if (assetId && amount) {
      //console.log('assetId', assetId)
      if (this.getAssetsInfoFlg === true) {
        var thisAssProp = this.bitshareAssetInfo[assetId];
        //console.log('thisAssProp', thisAssProp);
        const currBal: string = amount.toString();
        let curBalnFor: any;

        if (assetId != '1.3.0') {
          if (typeof (thisAssProp['precision']) !== undefined) {
            if (currBal.length > thisAssProp.precision) {
              const f: string = currBal.substring(0, (currBal.length - thisAssProp.precision));
              const s: string = currBal.substring((currBal.length - thisAssProp.precision), currBal.length);
              curBalnFor = f + '.' + s;
            } else {
              const diff = thisAssProp.precision - currBal.length;
              let f = '0.';
              for (var i = 1; i <= diff; i++) {
                f = f + '0';
              }
              curBalnFor = f + currBal;
            }
          }
        } else { //5
          const f: string = currBal.substring(0, (currBal.length - 5));
          const s: string = currBal.substring((currBal.length - 5), currBal.length);
          curBalnFor = f + '.' + s;
        }

        return curBalnFor;
      }
    } else {
      return amount;
    }
  }

  getBSBalance(usernameBS) {
    return new Promise<any>((resolve, reject) => {
      this.bitshareService.getBitshareUserbalance(usernameBS).then(userBalance => {
        resolve(userBalance);
      }, err => {
        reject(err);
      });
    });
  }
  
  formatDate(thisDateTime) {
    /*var dateandtime = thisDateTime.split("T");
    var datewithyear = dateandtime[0].split("-");
    var timewithsec = dateandtime[1].split(":");
    return datewithyear[1] + '-' + datewithyear[2] + ' ' + timewithsec[0] + ':' + timewithsec[1];
    */
    var thisDate = new Date(thisDateTime);
    var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    //console.log(months);
    //console.log(thisDate.getMonth())
    if(thisDate.getMonth() <= 12) {
      return months[thisDate.getMonth() - 1] + '-' + thisDate.getDate();
    } else {
      return thisDate.getMonth() + '-' + thisDate.getDate();
    }
  }
  
    onSelect(data): void {
    //console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    //console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    //console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }
  
  getPriceChartData() {
    //Temp
    //console.log('in getPriceChartData')
    var bsAssets:Array<any> = [];
    bsAssets.push("CNY");
    bsAssets.push("GDEX.EOS");
    bsAssets.push("BTS");
    
    this.allBSAssets.forEach((bsast, bsind) => {
       bsAssets.push(bsast.split(this.BASE_ASSET+'.')[1]);
    });
    
    let server = "wss://api.dex.trading/"; //wss://testnet.dex.trading/ wss://api.btsgo.net/ws wss://api.dex.trading/ 
    Apis.instance(server, true).init_promise.then((res) => { //comment
        //console.log("connected to:", res[0].network);
        //console.log('server conn res', res);
        ChainStore.init(false).then(() => {
        });
    });
    
    //Original this.allBSAssets
    setTimeout(() => {
        
        bsAssets.forEach((cpitm, cpind) => {
              
          this.chartCoinpair = [];
          this.chartCoinpair.push(cpitm);
          this.chartCoinpair.push(this.user.currency); //
          let graphDataAll:Array<any> = [];
          let graphDataWeek:Array<any> = [];
          let graphDataDay:Array<any> = [];
          let graphDataMonth:Array<any> = [];
          let graphDataYear:Array<any> = [];
          
          let yAxisMin:Array<number> = [];
          //console.log('this.chartCoinpair', this.chartCoinpair);
          Apis.instance().db_api().exec( "lookup_asset_symbols", [this.chartCoinpair]).then((assetsInfoBS:any) => {
            //console.log('assetsInfoBS', assetsInfoBS);
            const base_id = assetsInfoBS[0];
            const quote_id = assetsInfoBS[1];
            let toDo: string;
            let divByOne: boolean = false;
            let diff: number;
            let preDiv: boolean;
            let postDiv: boolean;
            let xAxisTicks:Array<any> = [];
            let xAxisAllCusLabel:Array<any> = [];
            if(base_id['precision'] >= quote_id['precision']) {
              diff = base_id['precision'] - quote_id['precision'];
              preDiv = true;
              postDiv = false;
            } else {
              diff = quote_id['precision'] - base_id['precision'];
              preDiv = false;
              postDiv = true;
            }
            
            if(base_id['id'].split(".")[2] > quote_id['id'].split(".")[2]) {
              if(quote_id['options']['core_exchange_rate']['quote']['asset_id'] == quote_id['id']) {
                toDo = 'Divide';
              } else {
                toDo = 'Multiply';
              }
            } else {
              toDo = 'Divide';
            }
            
            let bi:number = 1;
            for(let i=1; i <= diff; i++) {
              bi = bi * 10;
            }
            //console.log('bi', bi, toDo)

            if(base_id['options']['core_exchange_rate']['base']['asset_id'].split(".")[2] >= quote_id['options']['core_exchange_rate']['quote']['asset_id'].split(".")[2]) {
                //base_id['id'].split(".")[2] > quote_id['id'].split(".")[2]
                divByOne = true;    
            }
            //console.log(base_id['symbol'] +"::"+ quote_id['symbol'] +quote_id['precision'] + '>=' + base_id['precision'] + 'div ' + divByOne + '-'+preDiv+' - '+postDiv);
            
            Apis.history.get_market_history_buckets().then((marketHistoryBuckets:any) => {
                const mid = (marketHistoryBuckets.length / 2).toFixed();
                
                let lineChartDataTmp;
                Apis.history.get_market_history(base_id['symbol'], quote_id['symbol'], 60, this.dayFromDate, this.dayToDate).then((dayMarkHist:any) => {
                    if(dayMarkHist.length) {
                        dayMarkHist.forEach((dyData, dyInd) => {
                            let openVal, highVal, lowVal, closeVal;
                            const base_precision = base_id.precision;
                            const quote_precision = quote_id.precision;
                                
                            openVal = (dyData.open_base / dyData.open_quote);
                            highVal = (dyData.high_base / dyData.high_quote);
                            lowVal = (dyData.low_base / dyData.low_quote);
                            closeVal = (dyData.close_base / dyData.close_quote);
                            
                            if(toDo == 'Divide') {
                              if(preDiv == true) {
                                openVal = (bi / openVal);
                                highVal = (bi / highVal);
                                lowVal = (bi / lowVal);
                                closeVal = (bi / closeVal);
                              }
                              if(postDiv == true) {
                                openVal = (openVal / bi);
                                highVal = (highVal / bi);
                                lowVal = (lowVal / bi);
                                closeVal = (closeVal / bi);
                              }
                              
                            } else {
                              openVal = (openVal * bi);
                              highVal = (highVal * bi);
                              lowVal = (lowVal * bi);
                              closeVal = (closeVal * bi);
                            }
                            openVal = (Math.round(openVal * 100) / 100).toFixed(2);                     
                            graphDataDay.push({'name': new Date(dyData.key.open), 'value': openVal, 'base': dyData.open_base, 'quote': dyData.open_quote});

                        });
                    }
                });
                
                Apis.history.get_market_history(base_id['symbol'], quote_id['symbol'], 900, this.weekFromDate, this.weekToDate).then((weekMarkHist:any) => {
                    if(weekMarkHist.length) {
                        weekMarkHist.forEach((wData, wInd) => {
                            let openVal, highVal, lowVal, closeVal;
                            const base_precision = base_id.precision;
                            const quote_precision = quote_id.precision;
                                
                            openVal = (wData.open_base / wData.open_quote);
                            highVal = (wData.high_base / wData.high_quote);
                            lowVal = (wData.low_base / wData.low_quote);
                            closeVal = (wData.close_base / wData.close_quote);
                            
                            if(toDo == 'Divide') {
                              if(preDiv == true) {
                                openVal = (bi / openVal);
                                highVal = (bi / highVal);
                                lowVal = (bi / lowVal);
                                closeVal = (bi / closeVal);
                              }
                              if(postDiv == true) {
                                openVal = (openVal / bi);
                                highVal = (highVal / bi);
                                lowVal = (lowVal / bi);
                                closeVal = (closeVal / bi);
                              }
                              
                            } else {
                              openVal = (openVal * bi);
                              highVal = (highVal * bi);
                              lowVal = (lowVal * bi);
                              closeVal = (closeVal * bi);
                            }
                            openVal = (Math.round(openVal * 100) / 100).toFixed(2);                     
                            graphDataWeek.push({'name': new Date(wData.key.open), 'value': openVal, 'base': wData.open_base, 'quote': wData.open_quote});

                        });
                    }
                });
                Apis.history.get_market_history(base_id['symbol'], quote_id['symbol'], 3600, this.monthFromDate, this.monthToDate).then((montMarkHist:any) => {
                    if(montMarkHist.length) {
                        montMarkHist.forEach((mData, mInd) => {
                            let openVal, highVal, lowVal, closeVal;
                            const base_precision = base_id.precision;
                            const quote_precision = quote_id.precision;
                                
                            openVal = (mData.open_base / mData.open_quote);
                            highVal = (mData.high_base / mData.high_quote);
                            lowVal = (mData.low_base / mData.low_quote);
                            closeVal = (mData.close_base / mData.close_quote);
                            
                            if(toDo == 'Divide') {
                              if(preDiv == true) {
                                openVal = (bi / openVal);
                                highVal = (bi / highVal);
                                lowVal = (bi / lowVal);
                                closeVal = (bi / closeVal);
                              }
                              if(postDiv == true) {
                                openVal = (openVal / bi);
                                highVal = (highVal / bi);
                                lowVal = (lowVal / bi);
                                closeVal = (closeVal / bi);
                              }
                              
                            } else {
                              openVal = (openVal * bi);
                              highVal = (highVal * bi);
                              lowVal = (lowVal * bi);
                              closeVal = (closeVal * bi);
                            }
                            openVal = (Math.round(openVal * 100) / 100).toFixed(2);                     
                            graphDataMonth.push({'name': new Date(mData.key.open), 'value': openVal, 'base': mData.open_base, 'quote': mData.open_quote});

                        });
                    }
                });
                Apis.history.get_market_history(base_id['symbol'], quote_id['symbol'], 86400, this.yearFromDate, this.yearToDate).then((yearMarkHist:any) => {
                    if(yearMarkHist.length) {
                        yearMarkHist.forEach((yData, yInd) => {
                            let openVal, highVal, lowVal, closeVal;
                            const base_precision = base_id.precision;
                            const quote_precision = quote_id.precision;
                                
                            openVal = (yData.open_base / yData.open_quote);
                            highVal = (yData.high_base / yData.high_quote);
                            lowVal = (yData.low_base / yData.low_quote);
                            closeVal = (yData.close_base / yData.close_quote);
                            
                            if(toDo == 'Divide') {
                              if(preDiv == true) {
                                openVal = (bi / openVal);
                                highVal = (bi / highVal);
                                lowVal = (bi / lowVal);
                                closeVal = (bi / closeVal);
                              }
                              if(postDiv == true) {
                                openVal = (openVal / bi);
                                highVal = (highVal / bi);
                                lowVal = (lowVal / bi);
                                closeVal = (closeVal / bi);
                              }
                              
                            } else {
                              openVal = (openVal * bi);
                              highVal = (highVal * bi);
                              lowVal = (lowVal * bi);
                              closeVal = (closeVal * bi);
                            }
                            openVal = (Math.round(openVal * 100) / 100).toFixed(2);                     
                            graphDataYear.push({'name': new Date(yData.key.open), 'value': openVal, 'base': yData.open_base, 'quote': yData.open_quote});

                        });
                    }
                });
                
                
                
                
                Apis.history.get_market_history(base_id['symbol'], quote_id['symbol'], 14400, this.allFromDate, this.allToDate).then((allMarkHist:any) => { 
                 
                    if(allMarkHist.length) {
                      allMarkHist.forEach((aData, aInd) => {
                        let openVal, highVal, lowVal, closeVal;
                        const base_precision = base_id.precision;
                        const quote_precision = quote_id.precision;
                            
                        openVal = (aData.open_base / aData.open_quote);
                        highVal = (aData.high_base / aData.high_quote);
                        lowVal = (aData.low_base / aData.low_quote);
                        closeVal = (aData.close_base / aData.close_quote);
                        
                        if(toDo == 'Divide') {
                          if(preDiv == true) {
                            openVal = (bi / openVal);
                            highVal = (bi / highVal);
                            lowVal = (bi / lowVal);
                            closeVal = (bi / closeVal);
                          }
                          if(postDiv == true) {
                            openVal = (openVal / bi);
                            highVal = (highVal / bi);
                            lowVal = (lowVal / bi);
                            closeVal = (closeVal / bi);
                          }
                          
                        } else {
                          openVal = (openVal * bi);
                          highVal = (highVal * bi);
                          lowVal = (lowVal * bi);
                          closeVal = (closeVal * bi);
                        }
                        openVal = (Math.round(openVal * 100) / 100).toFixed(2);
                        yAxisMin.push(openVal);
                        
                        graphDataAll.push(...[{'name': new Date(aData.key.open), 'value': openVal, 'base': aData.open_base, 'quote': aData.open_quote}]);//x-axis label
                        if(graphDataAll.length == allMarkHist.length) {
                            setTimeout(()=>{
                                
                                //console.log('condition check ' +  cpind + '==' + bsAssets.length)
                                //let minYVal = yAxisMin.reduce((a, b)=>Math.min(a, b));
                                yAxisMin.sort((a,b) => (a > b) ? 1 : ((b > a) ? -1 : 0));
                                //console.log('yAxisMin', yAxisMin)
                                //let minYVal = yAxisMin[0] - (yAxisMin[1] - yAxisMin[0]);
                                let minYVal = yAxisMin[0];
                                if(minYVal < 0) { minYVal = 0; }
                                this.lineChartData = [...[{ "name": quote_id['symbol'], "series": [...graphDataAll], "min": minYVal, 'currentVal': openVal, "dayseries": graphDataDay, "weekseries": graphDataWeek, "monthseries": graphDataMonth, "yearseries": graphDataYear, "allseries": graphDataAll}]]; //hover
                                // + quote_id['symbol']
                                this.assetChartData.push({"base": base_id, "quote": quote_id, "graphData": this.lineChartData, "min": minYVal, 'currentVal': openVal});
                                //if(cpind == (bsAssets.length - 1)) {
                                    this.ngx_charts_exchange = true;
                                //}
                                console.log(this.assetChartData);
                            }, 1000);
                        }
                      });
                    }
                  
                }); 
            });
          });
          
        });
    }, 3000);
    
  }
  refreshChartData(dataFor) {
    var currentIndex = this.balanceTabGroup.selectedIndex;
    this.chartLoader = true;
    this.assetChartData.forEach((acd, aci) => {
      this.assetChartData[aci].graphData[0].series.length = 0
      if(dataFor == 0) {
          this.assetChartData[aci].graphData[0].series = [...this.assetChartData[aci].graphData[0].dayseries, ...this.assetChartData[aci].graphData[0].series];
      }
      if(dataFor == 1) {
          this.assetChartData[aci].graphData[0].series = [...this.assetChartData[aci].graphData[0].weekseries, ...this.assetChartData[aci].graphData[0].series];
      }
      if(dataFor == 2) {
          this.assetChartData[aci].graphData[0].series = [...this.assetChartData[aci].graphData[0].monthseries, ...this.assetChartData[aci].graphData[0].series];
      }
      if(dataFor == 3) {
          this.assetChartData[aci].graphData[0].series = [...this.assetChartData[aci].graphData[0].yearseries, ...this.assetChartData[aci].graphData[0].series];
      }
      if(dataFor == 4) {
          this.assetChartData[aci].graphData[0].series = [...this.assetChartData[aci].graphData[0].allseries, ...this.assetChartData[aci].graphData[0].series];
      }
    })
    if(this.balanceTabGroup.selectedIndex == 0) {
      this.chartTabIndex = this.balanceTabGroup._tabs.length - 1; 
    } else {
      this.chartTabIndex = 0;
    }
    //this.chartTabIndex = this.chartTabIndex + 1;
    setTimeout(()=>{
      //this.chartTabIndex = this.chartTabIndex - 1;
      this.chartTabIndex = currentIndex;
    }, 2000);
    setTimeout(()=>{
      this.chartLoader = false;
    }, 3000);
  }
  dateTickFormatting = (e) => {
      //if(this.balanceTabGroup) {
          //const arrayInd = this.balanceTabGroup.selectedIndex;
          var thisDate = new Date(e);
          var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
          let formattedDate;
          
          if(thisDate.getMonth() <= 12) {
            formattedDate =  months[thisDate.getMonth()] + '-' + thisDate.getDate();
          } else {
            formattedDate = thisDate.getMonth() + '-' + thisDate.getDate();
          }
		  formattedDate = thisDate.getMonth() + '-' + thisDate.getDate();
          /*
          this.currXCoord.push(formattedDate);
          //console.log('this.allXaxisTicks', this.allXaxisTicks);
          let showXlabel: string = "false";
          this.allXaxisTicks.forEach((xitmpair, xindpair) => {
            //if(xindpair == arrayInd) {
              xitmpair.forEach((xitm, xind) => {
                if(xitm == e) {
                  showXlabel = "true";
                }
              });
            //}
          });
          if (showXlabel == "true") {
              return formattedDate;
          } else {
              return '';
          }
          */
		  /*
		  let found: boolean = false;
		  this.xAxisCoord.forEach((xaxitm, xaxind) => {
			if(found == false) {
				var xAxisDate = new Date(xaxitm);
				const xaxdate = xAxisDate.getMonth() + '-' + xAxisDate.getDate();
				const thsdate = thisDate.getMonth() + '-' + thisDate.getDate();
				if (xaxdate == thsdate) {
				  //console.log(xaxdate, thsdate);
				  
				  found = true;
				} 
			}
		  })
		  if(found == false) {
		    formattedDate = '';
		  }
		  */
          return formattedDate;
		  
		  
      //}
	  //const formatPipe: XAxisTickFormatPipe = new XAxisTickFormatPipe();
      //return formatPipe.transform(thisDate);
  }
  balanceTabChanged(tabChangeEvent: MatTabChangeEvent): void {
    //console.log('tabChangeEvent => ', tabChangeEvent);
    //console.log('index => ', tabChangeEvent.index);
    //console.log('allXaxisTicks', this.allXaxisTicks);
    this.currXCoord.length = 0;
  }
  
  getDates(startDate, endDate, interval) {
    const duration = endDate - startDate;
    const steps = duration / interval;
    var datearr:any = Array.from({length: steps+1}, (v,i) => new Date(startDate.valueOf() + (interval * i)));
  }
    
  yCurrencyFormatting = (e) => {
      if(this.user.currency == 'USD') {
        return "$" + e;
      } else {
        return "�" + e;
      }
  }

  getDaysArray(st, en) {

    var arr=[];
    var start:any = new Date(st);
    var end:any = new Date(en);
    const diffTwoDates = Math.round((end-start)/(1000*60*60*24));
    var newend = end.setDate(end.getDate());
    end = new Date(newend);
    const xAxisGridLen = 6;

    
    while(start < end && arr.length < (xAxisGridLen - 1)){
       //console.log(new Date(start).getTime() / 1000); // unix timestamp format
       //console.log(start); // ISO Date format
       arr.push(start);    
       var newDate = start.setDate(start.getDate() + xAxisGridLen);
       start = new Date(newDate);
       
        
       
    }
    //console.log('arr', arr)
    return arr;
  };
  
  gatewayIBTest() {
    const formData = new FormData();
    formData.append('amount', "0.011");
	formData.append('symbol', "BLOCK.BTC");
	formData.append('to', "fuck-fuck");
	
	this.bitshareService.gatewayIssueTest(this.global.authToken, formData).subscribe((gwResponse) => {
	    console.log('gwResponse', gwResponse);
	});
  }
}
