import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormControl, ValidatorFn, AbstractControl } from '@angular/forms';
import * as firebase from 'firebase/app';

import { NgxUiLoaderService } from 'ngx-ui-loader';

import { AuthService } from '../service/auth.service';
import { UserService } from '../service/user.service';

import { Global } from '../global/global';

@Component({
  selector: 'app-google-action',
  templateUrl: './google-action.component.html',
  styleUrls: ['./google-action.component.scss']
})
export class GoogleActionComponent implements OnInit {
  public oobCode: string;
  public mode: string;
  public errorMessage: string;
  public successMessage: string;
  public passwordResetForm: FormGroup;
  public user: any;
  public userDocId: string;
  
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private ngxService: NgxUiLoaderService,
    private authService: AuthService,
    private userService: UserService,
    private global: Global,
  ) {
    this.createPasswordResetForm();
  }

  validationMessages = {
    newPassword: [
      { type: 'required', message: 'Password is required.' },
      { type: 'minlength', message: ' Minimum 6 character.' },
      { type: 'maxlength', message: 'Maximum 12 character.' },
    ],
    confirmPassword: [
      { type: 'required', message: 'Password is required.' },
      { type: 'minlength', message: ' Minimum 6 character.' },
      { type: 'maxlength', message: 'Maximum 12 character.' },
      { type: 'equalTo', message: 'Confirm password not match' },
    ],
  };

  get f() { return this.passwordResetForm.controls; }

  createPasswordResetForm() {
    this.passwordResetForm = new FormGroup({
      newPassword: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(6),
      ])),
      confirmPassword: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(6),
        this.equalTo('newPassword')
      ])),
    });
  }

  // confirm password validator
  equalTo(fieldName): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const input = control.value;
      const isValid = control.root.value[fieldName] === input;
      if (!isValid) { return { equalTo: { isValid } }; } else { return null; }
    };
  }

  ngOnInit() {
    // this.ngxService.start();
    this.authService.doInvisibleReChaptcha();
    this.route.data.subscribe(routeData => {
      this.route.queryParams.subscribe(params => {
        this.oobCode = params.oobCode;
        this.mode = params.mode;
        if (this.global.loggedInUser) {
          if (this.mode === 'verifyEmail') {
            this.userService.getCurrentUser().then(([res, udid]: any) => {
              this.user = res;
              this.global.loggedInUser = res;
              this.userDocId = udid;
              setTimeout(() => {
                this.tryVerifyEmail(this.oobCode);
              }, 2000);
            }, error => {
              // this.ngxService.stop();
            });
          } else if (this.mode === 'resetPassword') {
            // this.ngxService.stop();
          }
        } else {
          // Need to show popup or need to write email verify after hook in firebase function to update emailVerify status
          console.log('Please login before verifying email');
        }
      });
    });
  }

  login() {
    this.router.navigate(['/login']);
  }

  tryVerifyEmail(code) {
    this.errorMessage = '';
    this.authService.doVerifyEmail(code).then(res => {
      // this.ngxService.stop();
      this.user.emailVerified = true;
      this.userService.updateUserDetails(this.user, this.userDocId);
      // this.successMessage = 'Your email has been verified';
      this.userService.getCurrentUser();
      if (this.user.mobileNoVerified === false) {
        // this.sendVerificationSMS(this.user.mobileNo);
        this.router.navigate(['/verify-mobile']);
      } else {
        this.router.navigate(['/b/create']);
      }
    }, err => {
      if (err.code === 'auth/invalid-action-code' && !this.user.emailVerified) {
        this.errorMessage = 'The verification is invalid. This can happen if the link is expired, or has already been used. ';
        this.errorMessage += 'Try to login and resend verification email.';
        // this.ngxService.stop();
      } else {
        this.errorMessage = err.message;
        // this.ngxService.stop();
      }
      this.successMessage = '';
    });
  }

  // sendVerificationSMS(mobileNo) {
  //   console.log('sendVerificationSMS On google action page ');
  //   const appVerifier = this.authService.windowRef.recaptchaVerifier;

  //   firebase.auth().currentUser.linkWithPhoneNumber(mobileNo, appVerifier).then(confirmationResult => {
  //     this.authService.windowRef.confirmationResult = confirmationResult;
  //     this.router.navigate(['/verify-mobile']);
  //   }, err => {
  //     this.errorMessage = 'Something went wrong while sending PIN to mobile, please try again';
  //     console.log('err', err);
  //   });
  // }

  changePassword(form) {
    // this.ngxService.start();
    this.authService.doVerifyCodeAndPasswordReset(this.oobCode, form.value).then(res => {
      this.global.passwordResetMsg = 'Your password has been updated, login using your new credential to continue.';
      // this.ngxService.stop();
      this.router.navigate(['/login']);
    }, err => {
      console.log(err);
      if (err.code === 'auth/invalid-action-code') {
        this.errorMessage = 'This link was expaired or has already been used.';
      } else {
        this.errorMessage = 'Something went wrong, please try again.';
      }
      // this.ngxService.stop();
    });
  }

}
