import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-transfer-completed',
  templateUrl: './transfer-completed.component.html',
  styleUrls: ['./transfer-completed.component.scss']
})
export class TransferCompletedComponent implements OnInit {
  successMsg;
  transaction_id;
  HOSTURL;
  constructor(
    private router: Router,
  ) {
   }
  ngOnInit(): void {
    this.successMsg = localStorage.getItem('successMsg');
    this.transaction_id = localStorage.getItem('transaction_id');
    this.HOSTURL = JSON.parse(localStorage.getItem('HOSTURL'));
  }
  transfer() {
    this.router.navigate(['/transfer']);
  }
  account() {
    this.router.navigate(['/accounts']);
  }
}
