import { Directive, ElementRef, Renderer2  } from '@angular/core';

@Directive({
  selector: '[appButton]'
})
export class ButtonDirective {

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {
  renderer.setStyle(el.nativeElement, 'border-radius', '25px');
  renderer.setStyle(el.nativeElement, 'color', '#ffffff');
  renderer.setStyle(el.nativeElement, 'padding', '10px 35px');
  renderer.setStyle(el.nativeElement, 'background-color', '#673ab7');
  renderer.setStyle(el.nativeElement, 'font-family', 'JosefinSans-Regular');
  renderer.setStyle(el.nativeElement, 'font-size', '16px');
  }
}
