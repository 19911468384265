import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { BitshareService } from '../service/bitshare.service';
import { AuthService } from '../service/auth.service';
import { UserService } from '../service/user.service';
import { Global } from '../global/global';
 require('firebase/database');
@Component({
  selector: 'app-verify-mobile',
  templateUrl: './verify-mobile.component.html',
  styleUrls: ['./verify-mobile.component.scss']
})
export class VerifyMobileComponent implements OnInit {
  public showOTPForm = false;
  public showMobileNumberForm = false;
  public showOTPVerifyMsg = false;
  public mobileOTPForm: FormGroup;
  public mobileVerifyForm: FormGroup;
  public errorMessage: string;
  public successMessage: string;
  public user: any;
  public userDocId: string;
  public loading = false;
  public apiUrl: any;
  public accessToken: string;
  public phoneNumberExists;
  public contactInfo;
  public emailExists;
  public phoneNumberAlreadyExists;
  constructor(
    private global: Global,
    private fb: FormBuilder,
    private bitshareService: BitshareService,
    private authService: AuthService,
    private userService: UserService,
    public afAuth: AngularFireAuth,
    private router: Router,
    private http: HttpClient,
  ) {
    this.userService.detectMobileDevices();
    this.mobileOTPForm = this.fb.group({
      otp: ['', Validators.required]
    });
    this.mobileVerifyForm = this.fb.group({
      mobileNo: ['', Validators.required]
    });
  }

  validationMessages = {
    otp: [
      { type: 'required', message: 'PIN is required.' },
      { type: 'number', message: 'Enter valid PIN.' }
    ],
    mobileNo: [
      { type: 'required', message: 'Mobile number is required.' }
    ],
  };
  get otpF() { return this.mobileOTPForm.controls; }
  get mobileF() { return this.mobileVerifyForm.controls; }

  ngOnInit() {
    this.userService.getCurrentUser();
    this.authService.doInvisibleReChaptcha();
    this.userService.getCurrentUser().then(([user, udid]) => {
      if (!user) {
        this.router.navigate(['/login']);
      }
      this.global.loggedInUser = user;
      this.user = user;
      this.userDocId = udid;
      this.accessToken = this.global.authToken;
      if (user.mobileNoVerified === false) {
        console.log('if')
        // this.ngxService.stop();
        // Show mobile number form
        this.showMobileNumberForm = true;
      } 
      // else if (user.mobileNoVerified === false) {
      //   console.log('else');
      //   // this.ngxService.stop();
      //   // Send OTP and show OTP form
      //   this.showOTPForm = true;
      //   this.resendOTP();
      // } 
      else {
        console.log('else')
        this.router.navigate(['/b/create']);
      }
    });
    this.bitshareService.getClientConfig().subscribe(configData => {
      console.log('configData', configData);
      this.apiUrl = 'https://' + configData['API_HOSTNAME'];
      console.log('this.apiUrl', this.apiUrl);
    }, err => {
        console.log('err', err);
    });
  }

  resendOTP() {
    console.log('resend OTP');
    firebase.auth().currentUser.unlink(firebase.auth.PhoneAuthProvider.PROVIDER_ID);
    setTimeout(() => {
    this.authService.doSendVerificationCode(this.user.mobileNo).then(responce => {
      // this.successMessage = 'PIN send to your registered mobile number';
      console.log('response', responce);
    }, err => {
      console.log('resenderr', err);
      this.errorMessage = 'Something went wrong while sending OTP to mobile, please try again';
    });
    }, 3000);
  }

  verifyOTP(form) {
    this.authService.doVerifyOTP(form.value.otp).then(res => {
      this.user.mobileNoVerified = true;
      this.userService.updateUserDetails(this.user, this.userDocId);
      this.userService.getCurrentUser().then(([user, udid]) => {
        this.mobileOTPForm.reset();
        this.global.mobileVerifiedMsg = 'Your mobile number has been verified';
        setTimeout(() => {
          this.router.navigate(['/b/create']);
        }, 3000);
      });
    }, err => {
      if (err.code === 'auth/credential-already-in-use') {
        this.errorMessage = 'This mobile number is already associated with a different user account.';
      } else {
        this.errorMessage = 'Pin you have entered was invalid, please try again';
      }
      console.log('verifyOTP', err);
    });
  }
  sendVerificationSMS(form)  {
    this.loading = true;
    this.errorMessage = '';
    this.contactInfo = form.value.mobileNo;
    const APIurl = this.apiUrl + '/tasks/lookup/' + this.contactInfo;
    console.log('Al', APIurl);
    const headers = new HttpHeaders({Authorization: 'Bearer ' + this.accessToken });
    this.http.get(APIurl, {headers}).subscribe((createResponse:any) => {
      console.log('createResponse', createResponse);
      this.phoneNumberExists = createResponse.accountName;
      firebase.database().ref('Users').once('value', dataSnapshot => { 
        console.log('dataSnapshot', dataSnapshot)
      });
      // firebase.firestore().collection('Users').doc(this.phoneNumberExists).onSnapshot( (snapshot) =>{
      //   console.log('snapshot', snapshot);
      // });
    //  var resp = firebase.database().ref('Users').orderByChild(this.phoneNumberExists);
    //  console.log('resp', resp)
      // this.emailExists = createResponse.email;
      // console.log('createResponse', this.emailExists);
      if (this.phoneNumberExists === null) {
        console.log('if');
        const appVerifier = this.authService.windowRef.recaptchaVerifier;
        firebase.auth().currentUser.linkWithPhoneNumber(form.value.mobileNo, appVerifier).then(confirmationResult => {
          this.authService.windowRef.confirmationResult = confirmationResult;
          this.user.mobileNo = form.value.mobileNo;
          this.userService.updateUserDetails(this.user, this.userDocId);
          this.showOTPForm = true;
          this.showMobileNumberForm = false;
        }, err => {
          this.loading = false;
          if (err.code === 'auth/invalid-phone-number') {
            this.errorMessage = 'Phone number is InValid, please try again';
          }  else {
            this.errorMessage = 'Something went wrong while sending PIN to mobile, please try again';
            console.log('err', err);
          }
        });
      } else {
        this.loading = false;
        this.mobileVerifyForm.reset();
        this.phoneNumberAlreadyExists = 'This phone number(' + this.contactInfo + ')  is already associated with an existing Blockbasis account';
      }
    });
  }
}