import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceBlock'
})
export class ReplaceBlockPipe implements PipeTransform {

  transform(value: string): string {
    if (value) {
      return value.replace(/blockbasis./g, '').replace(/BLOCKBASIS./g, '').replace(/block./g, '').replace(/BLOCK./g, '');
    } else {
      return value;
    }
  }

}
