import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appMatDialogHeading]'
})
export class MatDialogHeadingDirective {

  constructor(el: ElementRef) {
    //el.nativeElement.style.width = "94%";
    //el.nativeElement.style.cssFloat = "left";
    el.nativeElement.style.color = "#6b50a1";
    el.nativeElement.style.fontWeight = "600";
    el.nativeElement.style.fontSize = "18px";
    el.nativeElement.style.textTransform = "uppercase";
    el.nativeElement.style.fontFamily = "JosefinSans-Regular";
  }

}
