import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';

import { WindowService } from '../service/window.service';
import { Global } from '../global/global';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public windowRef: any;
  // authState: FirebaseAuthState = null;
  constructor(
    public afAuth: AngularFireAuth,
    private router: Router,
    private win: WindowService,
    private global: Global,
  ) { }

  // Returns true if user is logged in
  // get authenticated(): boolean {
  //   return this.authState !== null;
  // }

  doFacebookLogin() {
    return new Promise<any>((resolve, reject) => {
      const provider = new firebase.auth.FacebookAuthProvider();
      this.afAuth.auth.signInWithPopup(provider).then(res => {
        resolve(res);
      }, err => {
        // console.log(err);
        reject(err);
      });
    });
  }

  doGoogleLogin() {
    return new Promise<any>((resolve, reject) => {
      const provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');
      this.afAuth.auth.signInWithPopup(provider).then(res => {
        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      });
    });
  }
  doAppleLogin() {
    return new Promise<any>((resolve, reject) => {
      const provider = new firebase.auth.OAuthProvider('apple.com');
      provider.addScope('email');
      provider.addScope('name');
      this.afAuth.auth.signInWithPopup(provider).then(res => {
        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      });
    });
  }
  doTwitterLogin() {
    return new Promise<any>((resolve, reject) => {
      const provider = new firebase.auth.TwitterAuthProvider();
      this.afAuth.auth.signInWithPopup(provider).then(res => {
        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      });
    });
  }
  doLogin(value) {
    return new Promise<any>((resolve, reject) => {
      firebase.auth().signInWithEmailAndPassword(value.email, value.password).then(res => {
        resolve(res);
      }, err => reject(err));
    });
  }

  doRegister(value) {
    return new Promise<any>((resolve, reject) => {
      firebase.auth().createUserWithEmailAndPassword(value.email, value.password).then(res => {
        resolve(res);
      }, err => reject(err));
    });
  }

  doEmailLogin(value) {
    return new Promise<any>((resolve, reject) => {
      firebase.auth().signInWithEmailAndPassword(value.email, value.password).then(res => {
        resolve(res);
      }, err => reject(err));
    });
  }

  doSentForgotlink(value) {
    return new Promise<any>((resolve, reject) => {
      firebase.auth().sendPasswordResetEmail(value.email).then(res => {
        resolve(res);
      }, err => reject(err));
    });
  }

  doVerifyCodeAndPasswordReset(code, value) {
    return new Promise<any>((resolve, reject) => {
      firebase.auth().verifyPasswordResetCode(code).then(verified => {
        firebase.auth().confirmPasswordReset(code, value.confirmPassword)
        .then(res => {
          resolve(res);
        }, err => reject(err));
      }, err => reject(err));
    });
  }

  doSentVerificationEmail() {
    return new Promise<any>((resolve, reject) => {
      firebase.auth().currentUser.sendEmailVerification().then(res => {
        resolve(res);
      }, err => reject(err));
    });
  }

  doVerifyEmail(code) {
    return new Promise<any>((resolve, reject) => {
      firebase.auth().applyActionCode(code).then(res => {
        resolve(res);
      }, err => {
        console.log('doVerifyEmail', err);
        reject(err);
      });
    });
  }

  doInvisibleReChaptcha() {
    return new Promise<any>((resolve, reject) => {
      this.windowRef = this.win.windowRef;
      this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('invisible-recaptcha-container', {
        size: 'invisible',
      });

      this.windowRef.recaptchaVerifier.render();
    });
  }

  doSendVerificationCode(phoneNumber) {
    console.log('doSendVerificationCode');
    return new Promise<any>((resolve, reject) => {
      const appVerifier = this.windowRef.recaptchaVerifier;
      // firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier).then(res => {
      //   this.windowRef.confirmationResult = res;
      //   resolve(res);
      // }, err => reject(err));
      firebase.auth().currentUser.linkWithPhoneNumber(phoneNumber, appVerifier).then(confirmationResult => {
        this.windowRef.confirmationResult = confirmationResult;
        console.log('confirmationResult');
        resolve(confirmationResult);
      }, err => reject(err));
    });
  }

  doVerifyOTP(otp) {
    return new Promise<any>((resolve, reject) => {
      this.windowRef.confirmationResult.confirm(otp).then( res => {
        console.log('doVerifyOTP', res);
        resolve(res);
      }, err => reject(err));
    });
  }

  signOut() {
    this.afAuth.auth.signOut().then(() => {
      this.global.loggedIn = false;
      this.global.loggedInUser = {};
      setTimeout(() => {
        this.router.navigate(['/login']);
      }, 3000);
    });
  }
  
  updatePassword(password) {
    return new Promise<any>((resolve, reject) => {
      firebase.auth().currentUser.updatePassword(password).then(function(res) {
        resolve({message: 'Password updated successfully'});
      }, err => reject(err));
    });
  }
  

}
