import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transDate'
})
export class TransDatePipe implements PipeTransform {

  transform(value: string): object {
    if (value) {
      const userDate = value.split(' ');
      const mydate = new Date(userDate[0] + 'T' + userDate[1]);
      return mydate;
    } else {
      return new Date();
    }
  }

}
