import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { MatStepperModule } from '@angular/material/stepper'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 
import { NgxChartsModule } from '@swimlane/ngx-charts';

// For poeditor
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { QRCodeModule } from 'angularx-qrcode';
// For international mobile number
import { InternationalPhoneNumberModule } from 'ngx-international-phone-number';
import { UiSwitchModule } from 'ngx-toggle-switch';

// 3rd party lib
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { NgxUiLoaderModule, NgxUiLoaderConfig, SPINNER, POSITION, PB_DIRECTION  } from 'ngx-ui-loader';
import { ImageCropperModule } from 'ngx-image-cropper';
import { Ng2CompleterModule } from 'ng2-completer';
import { MatIconModule } from '@angular/material/icon';
import { ToastrModule } from 'ngx-toastr';
// Page components
import { AppMaterialModule } from './app-material.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SignupComponent } from './signup/signup.component';
import { ProfileComponent } from './profile/profile.component';
import { AccountsComponent } from './accounts/accounts.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { GoogleActionComponent } from './google-action/google-action.component';
import { VerifyMobileComponent } from './verify-mobile/verify-mobile.component';
import { BitsharesCreateAccountComponent } from './bitshares-create-account/bitshares-create-account.component';
import { BitsharesTransferComponent } from './bitshares-transfer/bitshares-transfer.component';
import { BitsharesLoginComponent } from './bitshares-login/bitshares-login.component';
import { BitsharesTradeComponent } from './bitshares-trade/bitshares-trade.component';
import { MobileAppComponent } from './mobile-app/mobile-app.component';
import { TransferCompletedComponent } from './transfer-completed/transfer-completed.component';
import { ExchangeComponent } from './exchange/exchange.component';
import { PaymentComponent } from './payment/payment.component';
import { BuySellComponent } from './buy-sell/buy-sell.component';

// Class
import { Global } from './global/global';
import { AuthService } from './service/auth.service';

// Custom pipe
import { ReplaceOpenPipe } from './pipe/replace-open.pipe';
import { ReplaceBlockPipe } from './pipe/replace-block.pipe'
import { TransDatePipe } from './pipe/trans-date.pipe';
import { MoonpayComponent } from './moonpay/moonpay.component';
import { BuySuccessComponent } from './buy-success/buy-success.component';
import { KycComponent } from './kyc/kyc.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { DateonlyPipe } from './pipe/dateonly.pipe';
import { MatProgressButtonsModule } from 'mat-progress-buttons';
import { UnlockPopup } from './unlock-popup/unlock-popup';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ButtonDirective } from './directives/button.directive';
import { AmountFormatPipe } from './pipe/amount-format.pipe';
import { ActionPopup } from './action-popup/action-popup';
import { MatDialogCloseDirective } from './directives/mat-dialog-close.directive';
import { InputFieldDirective } from './directives/input-field.directive';
import { EightDigitDecimalPlaceDirective } from './directives/eight-digit-decimal-place.directive';
import { MatDialogHeadingDirective } from './directives/mat-dialog-heading.directive';
import { AngularPageVisibilityModule } from 'angular-page-visibility';
import { FileValidator } from './validator/file-validator';
import { FileValueAccessor } from './validator/file-value-accessor';
import { ChartSampleComponent } from './chart-sample/chart-sample.component';
import { XAxisTickFormatPipe } from './pipe/x-axis-tick-format.pipe';

// For poeditor
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  gap: 1,
  fgsType: SPINNER.threeBounce,
  fgsColor: '#ffffff',
  fgsSize: 100,
  fgsPosition: POSITION.centerCenter,
  pbDirection: PB_DIRECTION.leftToRight,
  pbThickness: 5,
  pbColor: '#673ab7',
  logoSize: 150,
  logoUrl: './assets/images/logo-white.png',
};

@NgModule({
  declarations: [
    EightDigitDecimalPlaceDirective,
    InputFieldDirective,
    ButtonDirective,
    ChangePasswordComponent,
    UnlockPopup,
    AppComponent,
    LoginComponent,
    DashboardComponent,
    SignupComponent,
    ProfileComponent,
    AccountsComponent,
    ForgotPasswordComponent,
    GoogleActionComponent,
    VerifyMobileComponent,
    ReplaceOpenPipe,
    TransDatePipe,
    MoonpayComponent,
    ExchangeComponent,
    PaymentComponent,
    BuySellComponent,
    BuySuccessComponent,
    TransferCompletedComponent,
    BitsharesCreateAccountComponent,
    BitsharesTransferComponent,
    BitsharesLoginComponent,
    BitsharesTradeComponent,
    KycComponent,
    VerifyEmailComponent,
    DateonlyPipe,
    ReplaceBlockPipe,
    AmountFormatPipe,
    MobileAppComponent,
    ActionPopup,
    MatDialogCloseDirective,
    MatDialogHeadingDirective,
    FileValidator,
    FileValueAccessor,
    ChartSampleComponent,
    XAxisTickFormatPipe
  ],
  imports: [
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-center',
    }),
    AppMaterialModule,
    BrowserAnimationsModule,
    MatStepperModule,
    MatProgressButtonsModule,
    MatProgressSpinnerModule,
    MatIconModule,
    FormsModule,
    QRCodeModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    ReactiveFormsModule,
    HttpClientModule,
    ImageCropperModule,
    Ng2CompleterModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AngularFireModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    InternationalPhoneNumberModule,
    UiSwitchModule,
    AngularPageVisibilityModule,
    NgxChartsModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  entryComponents: [UnlockPopup, ChangePasswordComponent, ActionPopup],
  providers: [Global, AuthService],
  bootstrap: [AppComponent]
})
export class AppModule { }
